/* ===============================================
    TABLE  OF CONTENT:
    ------------------
- 1/ CSS Basic classes
- 2/ base
- 3/ elements
- 4/ Layouts
- 5/ Pages
=============================================== */
/* ===================
- 1/ CSS Basic classes
=======================*/
.color .color_box {
  margin-bottom: 20px;
}

.color .color_box .color_palette {
  border-radius: 10px;
  height: 100px;
}

.color .color_box .color_palette.Brand {
  background-color: #566ffe;
}

.color .color_box .color_palette.Gradient {
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
}

.color .color_box .color_palette.Black {
  background-color: #183b56;
}

.color .color_box .color_palette.White {
  background-color: #ffffff;
}

.color .color_box .color_palette.Light {
  background-color: #f9fbfc;
}

.color .color_box .color_palette.Hard_light {
  background-color: #e6edf0;
}

.color .color_box .color_palette.Brand_light {
  background-color: rgba(86, 111, 254, 0.15);
}

.color .color_box .color_palette.Red {
  background-color: #eb5757;
}

.color .color_box .color_palette.Green {
  background: #36b37e;
}

.color .color_box .color_palette.Info {
  background-color: #1384e0;
}

.color .color_box .color_palette.Stroke {
  background-color: #e2eef1;
}

.color .color_box .color_palette.Text {
  background-color: #6f7d95;
}

/*-----------------------------
  media query
-----------------------------*/
/*-----------------------------
    mixin prefix
  -----------------------------*/
/*-----------------------------
    mixin animation
  -----------------------------*/
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -o-font-smoothing: antialiased !important;
  -ms-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-family: "SoraStd", sans-serif;
  font-size: 16px;
  color: #183b56;
  -webkit-transition: 0.3s ease-in-out !important;
  -moz-transition: 0.3s ease-in-out !important;
  -o-transition: 0.3s ease-in-out !important;
  -ms-transition: 0.3s ease-in-out !important;
  transition: 0.3s ease-in-out !important;
}

body.modal-open {
  padding-right: 0 !important;
  overflow: auto;
}

#wrapper,
#content {
  width: 100%;
  height: 100%;
}

/*-----------------------------
  button, input , a , btn
-----------------------------*/
button,
input {
  -webkit-outline: 0 !important;
  -moz-outline: 0 !important;
  -o-outline: 0 !important;
  -ms-outline: 0 !important;
  outline: 0 !important;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.btn,
.btn.focus,
.btn:active,
.btn.active,
.btn:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
  border: 0;
}

img {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/*-----------------------------
  Selection
-----------------------------*/
::selection {
  background-color: #566ffe;
  color: #ffffff;
}

.color_stroke {
  color: #e2eef1;
}

.color_brand {
  color: #566ffe;
}

.color_brand_light {
  color: rgba(86, 111, 254, 0.15);
}

.color_text {
  color: #6f7d95;
}

.color_yellow {
  color: #d7e932;
}

.color_light {
  color: #f9fbfc;
}

.color_hard_light {
  color: #e6edf0;
}

.color_white {
  color: #ffffff;
}

.color_black {
  color: #183b56;
}

.color_green {
  color: #36b37e;
}

.color_info {
  color: #1384e0;
}

.color_red {
  color: #eb5757;
}

.bg_brand {
  background-color: #566ffe;
}

.bg_brand_light {
  background-color: rgba(86, 111, 254, 0.15);
}

.bg_light {
  background-color: #f9fbfc;
}

.bg_body {
  background-color: #ecf1f9;
}

.bg_hard_light {
  background-color: #e6edf0;
}

.bg_white {
  background-color: #ffffff !important;
}

.bg_black {
  background-color: #183b56;
}

.bg_green {
  background-color: #36b37e;
}

.bg_info {
  background-color: #1384e0;
}

.bg_red {
  background-color: #eb5757;
}

.light_bg {
  background: #edf3f5;
  color: #183b56;
  box-shadow: 0px 2px 4px #7e8eb11f !important;
  padding: 4px 16px;
  border-radius: 28px;
  font-weight: 500;
  white-space: nowrap;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.hr {
  height: 1px;
  width: 100%;
  background: #e2eef1;
}

.hr2 {
  height: 1px;
  width: 100%;
  background: #cad1d8;
}

.dashed_box {
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%237B61FFFF' stroke-width='3' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 2.5rem;
}

@media screen and (max-width: 991px) {
  .dashed_box {
    padding: 15px;
  }
}

.dashed_border_h {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%237B61FFFF' stroke-width='3' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 100%;
  height: 1px;
}

.dashed_border_v {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23566FFEFF' stroke-width='3' stroke-dasharray='7%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  width: 1px;
  height: 100%;
}

.txt_hero {
  font-size: 72px;
}

.txt_lg {
  font-size: 23px;
}

.txt_lg._bold {
  font-family: "circular_bold";
}

.txt {
  font-size: 17px;
}

.txt._bold {
  font-family: "circular_bold";
}

.txt_sm {
  font-size: 16px;
}

.txt_sm._bold {
  font-family: "circular_bold";
}

.txt_xs {
  font-size: 13.5px;
}

.txt_xs._bold {
  font-family: "circular_bold";
}

.border-b {
  border-bottom: 1px solid #e2eef1;
}

.m-0 {
  margin: 0;
}

.m-helf {
  margin: 0.5px;
}

.m-100 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.m-40 {
  margin: 40px;
}

.m-50 {
  margin: 50px;
}

.m-60 {
  margin: 60px;
}

.m-70 {
  margin: 70px;
}

.m-80 {
  margin: 80px;
}

.m-90 {
  margin: 90px;
}

.m-100 {
  margin: 10rem;
}

/* ------------------------------------ margin top*/
.mt-0 {
  margin-top: 0 !important;
}

.mt-helf {
  margin-top: 0.5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 10rem;
}

/* ------------------------------------ margin bottom*/
.mb-20_reset {
  margin-bottom: -20px !important;
}

.mb-30_reset {
  margin-bottom: -30px !important;
}

.mb-70_reset {
  margin-bottom: -70px !important;
}

.mb-helf {
  margin-bottom: 0.5px;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-20_reset {
  margin-bottom: -20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 10px;
}

/* ------------------------------------ margin right*/
.mr-helf {
  margin-right: 0.5px;
}

.mr-100 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 10rem;
}

/* ------------------------------------ margin left*/
.mr-helf {
  margin-right: 0.5px;
}

.ml-100 {
  margin-right: 10px;
}

.ml-20 {
  margin-right: 20px;
}

.ml-30 {
  margin-right: 30px;
}

.ml-40 {
  margin-right: 40px;
}

.ml-50 {
  margin-right: 50px;
}

.ml-60 {
  margin-right: 60px;
}

.ml-70 {
  margin-right: 70px;
}

.ml-80 {
  margin-right: 80px;
}

.ml-90 {
  margin-right: 90px;
}

.ml-100 {
  margin-right: 10rem;
}

@media screen and (max-width: 991px) {
  .mt-100 {
    margin-top: 70px !important;
  }
}

.countdown_item {
  font-family: "circular_bold";
  font-size: 25px;
}

.w-fit {
  width: fit-content;
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(0px * var(--cb-space-y-reverse));
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(0px * var(--cb-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(1px * var(--cb-space-y-reverse));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(1px * var(--cb-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(2px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(2px * var(--cb-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(2px * var(--cb-space-x-reverse));
  margin-left: calc(2px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(3px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(3px * var(--cb-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(3px * var(--cb-space-x-reverse));
  margin-left: calc(3px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(4px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(4px * var(--cb-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(4px * var(--cb-space-x-reverse));
  margin-left: calc(4px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(5px * var(--cb-space-y-reverse));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(5px * var(--cb-space-x-reverse));
  margin-left: calc(5px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(10px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(10px * var(--cb-space-y-reverse));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(10px * var(--cb-space-x-reverse));
  margin-left: calc(10px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-15 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(15px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(15px * var(--cb-space-y-reverse));
}

.space-x-15 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(15px * var(--cb-space-x-reverse));
  margin-left: calc(15px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(20px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(20px * var(--cb-space-y-reverse));
}

.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(20px * var(--cb-space-x-reverse));
  margin-left: calc(20px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-25 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(25px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(25px * var(--cb-space-y-reverse));
}

.space-x-25 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(25px * var(--cb-space-x-reverse));
  margin-left: calc(25px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-30 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(30px * var(--cb-space-y-reverse));
}

.space-x-30 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(30px * var(--cb-space-x-reverse));
  margin-left: calc(30px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-35 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(35px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(35px * var(--cb-space-y-reverse));
}

.space-x-35 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(35px * var(--cb-space-x-reverse));
  margin-left: calc(35px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(40px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(40px * var(--cb-space-y-reverse));
}

.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(40px * var(--cb-space-x-reverse));
  margin-left: calc(40px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-45 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(45px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(45px * var(--cb-space-y-reverse));
}

.space-x-45 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(45px * var(--cb-space-x-reverse));
  margin-left: calc(45px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-50 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(50px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(50px * var(--cb-space-y-reverse));
}

.space-x-50 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(50px * var(--cb-space-x-reverse));
  margin-left: calc(50px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-55 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(55px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(55px * var(--cb-space-y-reverse));
}

.space-x-55 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(55px * var(--cb-space-x-reverse));
  margin-left: calc(55px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(60px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(60px * var(--cb-space-y-reverse));
}

.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(60px * var(--cb-space-x-reverse));
  margin-left: calc(60px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-65 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(65px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(65px * var(--cb-space-y-reverse));
}

.space-x-65 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(65px * var(--cb-space-x-reverse));
  margin-left: calc(65px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-70 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(70px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(70px * var(--cb-space-y-reverse));
}

.space-x-70 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(70px * var(--cb-space-x-reverse));
  margin-left: calc(70px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-75 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(75px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(75px * var(--cb-space-y-reverse));
}

.space-x-75 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(75px * var(--cb-space-x-reverse));
  margin-left: calc(75px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(80px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(80px * var(--cb-space-y-reverse));
}

.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(80px * var(--cb-space-x-reverse));
  margin-left: calc(80px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-85 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(85px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(85px * var(--cb-space-y-reverse));
}

.space-x-85 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(85px * var(--cb-space-x-reverse));
  margin-left: calc(85px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-90 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(90px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(90px * var(--cb-space-y-reverse));
}

.space-x-90 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(90px * var(--cb-space-x-reverse));
  margin-left: calc(90px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-95 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(95px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(95px * var(--cb-space-y-reverse));
}

.space-x-95 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(95px * var(--cb-space-x-reverse));
  margin-left: calc(95px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-100 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(100px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(100px * var(--cb-space-y-reverse));
}

.space-x-100 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(100px * var(--cb-space-x-reverse));
  margin-left: calc(100px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-105 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(105px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(105px * var(--cb-space-y-reverse));
}

.space-x-105 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(105px * var(--cb-space-x-reverse));
  margin-left: calc(105px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-110 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(110px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(110px * var(--cb-space-y-reverse));
}

.space-x-110 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(110px * var(--cb-space-x-reverse));
  margin-left: calc(110px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-115 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(115px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(115px * var(--cb-space-y-reverse));
}

.space-x-115 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(115px * var(--cb-space-x-reverse));
  margin-left: calc(115px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-120 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(120px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(120px * var(--cb-space-y-reverse));
}

.space-x-120 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(120px * var(--cb-space-x-reverse));
  margin-left: calc(120px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(1px * var(--cb-space-y-reverse));
}

.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(1px * var(--cb-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(0.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(0.5px * var(--cb-space-y-reverse));
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(0.5px * var(--cb-space-x-reverse));
  margin-left: calc(0.5px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(1.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(1.5px * var(--cb-space-y-reverse));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(1.5px * var(--cb-space-x-reverse));
  margin-left: calc(1.5px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(2.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(2.5px * var(--cb-space-y-reverse));
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(2.5px * var(--cb-space-x-reverse));
  margin-left: calc(2.5px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(3.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(3.5px * var(--cb-space-y-reverse));
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(3.5px * var(--cb-space-x-reverse));
  margin-left: calc(3.5px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(0px * var(--cb-space-y-reverse));
}

.-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(0px * var(--cb-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-1px * var(--cb-space-y-reverse));
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-1px * var(--cb-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-2px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-2px * var(--cb-space-y-reverse));
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-2px * var(--cb-space-x-reverse));
  margin-left: calc(-2px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-3px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-3px * var(--cb-space-y-reverse));
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-3px * var(--cb-space-x-reverse));
  margin-left: calc(-3px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-4px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-4px * var(--cb-space-y-reverse));
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-4px * var(--cb-space-x-reverse));
  margin-left: calc(-4px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-5px * var(--cb-space-y-reverse));
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-5px * var(--cb-space-x-reverse));
  margin-left: calc(-5px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-10px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-10px * var(--cb-space-y-reverse));
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-10px * var(--cb-space-x-reverse));
  margin-left: calc(-10px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-15 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-15px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-15px * var(--cb-space-y-reverse));
}

.-space-x-15 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-15px * var(--cb-space-x-reverse));
  margin-left: calc(-15px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-20px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-20px * var(--cb-space-y-reverse));
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-20px * var(--cb-space-x-reverse));
  margin-left: calc(-20px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-25 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-25px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-25px * var(--cb-space-y-reverse));
}

.-space-x-25 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-25px * var(--cb-space-x-reverse));
  margin-left: calc(-25px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-30 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-30px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-30px * var(--cb-space-y-reverse));
}

.-space-x-30 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-30px * var(--cb-space-x-reverse));
  margin-left: calc(-30px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-35 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-35px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-35px * var(--cb-space-y-reverse));
}

.-space-x-35 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-35px * var(--cb-space-x-reverse));
  margin-left: calc(-35px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-40px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-40px * var(--cb-space-y-reverse));
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-40px * var(--cb-space-x-reverse));
  margin-left: calc(-40px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-45 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-45px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-45px * var(--cb-space-y-reverse));
}

.-space-x-45 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-45px * var(--cb-space-x-reverse));
  margin-left: calc(-45px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-50 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-50px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-50px * var(--cb-space-y-reverse));
}

.-space-x-50 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-50px * var(--cb-space-x-reverse));
  margin-left: calc(-50px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-55 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-55px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-55px * var(--cb-space-y-reverse));
}

.-space-x-55 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-55px * var(--cb-space-x-reverse));
  margin-left: calc(-55px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-60px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-60px * var(--cb-space-y-reverse));
}

.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-60px * var(--cb-space-x-reverse));
  margin-left: calc(-60px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-65 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-65px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-65px * var(--cb-space-y-reverse));
}

.-space-x-65 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-65px * var(--cb-space-x-reverse));
  margin-left: calc(-65px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-70 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-70px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-70px * var(--cb-space-y-reverse));
}

.-space-x-70 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-70px * var(--cb-space-x-reverse));
  margin-left: calc(-70px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-75 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-75px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-75px * var(--cb-space-y-reverse));
}

.-space-x-75 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-75px * var(--cb-space-x-reverse));
  margin-left: calc(-75px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-80px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-80px * var(--cb-space-y-reverse));
}

.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-80px * var(--cb-space-x-reverse));
  margin-left: calc(-80px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-85 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-85px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-85px * var(--cb-space-y-reverse));
}

.-space-x-85 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-85px * var(--cb-space-x-reverse));
  margin-left: calc(-85px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-90 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-90px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-90px * var(--cb-space-y-reverse));
}

.-space-x-90 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-90px * var(--cb-space-x-reverse));
  margin-left: calc(-90px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-95 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-95px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-95px * var(--cb-space-y-reverse));
}

.-space-x-95 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-95px * var(--cb-space-x-reverse));
  margin-left: calc(-95px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-100 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-100px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-100px * var(--cb-space-y-reverse));
}

.-space-x-100 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-100px * var(--cb-space-x-reverse));
  margin-left: calc(-100px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-105 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-105px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-105px * var(--cb-space-y-reverse));
}

.-space-x-105 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-105px * var(--cb-space-x-reverse));
  margin-left: calc(-105px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-110 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-110px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-110px * var(--cb-space-y-reverse));
}

.-space-x-110 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-110px * var(--cb-space-x-reverse));
  margin-left: calc(-110px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-115 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-115px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-115px * var(--cb-space-y-reverse));
}

.-space-x-115 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-115px * var(--cb-space-x-reverse));
  margin-left: calc(-115px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-120 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-120px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-120px * var(--cb-space-y-reverse));
}

.-space-x-120 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-120px * var(--cb-space-x-reverse));
  margin-left: calc(-120px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-1px * var(--cb-space-y-reverse));
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-1px * var(--cb-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-0.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-0.5px * var(--cb-space-y-reverse));
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-0.5px * var(--cb-space-x-reverse));
  margin-left: calc(-0.5px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-1.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-1.5px * var(--cb-space-y-reverse));
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-1.5px * var(--cb-space-x-reverse));
  margin-left: calc(-1.5px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-2.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-2.5px * var(--cb-space-y-reverse));
}

.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-2.5px * var(--cb-space-x-reverse));
  margin-left: calc(-2.5px * calc(1 - var(--cb-space-x-reverse)));
}

.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 0;
  margin-top: calc(-3.5px * calc(1 - var(--cb-space-y-reverse)));
  margin-bottom: calc(-3.5px * var(--cb-space-y-reverse));
}

.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 0;
  margin-right: calc(-3.5px * var(--cb-space-x-reverse));
  margin-left: calc(-3.5px * calc(1 - var(--cb-space-x-reverse)));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --cb-space-y-reverse: 1
;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --cb-space-x-reverse: 1
;
}

@media screen and (max-width: 768px) {
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0px * var(--cb-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1px * var(--cb-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(2px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(2px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(2px * var(--cb-space-x-reverse));
    margin-left: calc(2px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(3px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(3px * var(--cb-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(4px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(4px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(4px * var(--cb-space-x-reverse));
    margin-left: calc(4px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(5px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(5px * var(--cb-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(10px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(10px * var(--cb-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(15px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(15px * var(--cb-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(20px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(20px * var(--cb-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(25px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(25px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(25px * var(--cb-space-x-reverse));
    margin-left: calc(25px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(30px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(30px * var(--cb-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(35px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(35px * var(--cb-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(40px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(40px * var(--cb-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(45px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(45px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(45px * var(--cb-space-x-reverse));
    margin-left: calc(45px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(50px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(50px * var(--cb-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(55px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(55px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(55px * var(--cb-space-x-reverse));
    margin-left: calc(55px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(60px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(60px * var(--cb-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(65px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(65px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(65px * var(--cb-space-x-reverse));
    margin-left: calc(65px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(70px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(70px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(70px * var(--cb-space-x-reverse));
    margin-left: calc(70px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(75px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(75px * var(--cb-space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(80px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(80px * var(--cb-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(85px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(85px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(85px * var(--cb-space-x-reverse));
    margin-left: calc(85px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(90px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(90px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(90px * var(--cb-space-x-reverse));
    margin-left: calc(90px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(95px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(95px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(95px * var(--cb-space-x-reverse));
    margin-left: calc(95px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(100px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(100px * var(--cb-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(105px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(105px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(105px * var(--cb-space-x-reverse));
    margin-left: calc(105px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(110px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(110px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(110px * var(--cb-space-x-reverse));
    margin-left: calc(110px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(115px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(115px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(115px * var(--cb-space-x-reverse));
    margin-left: calc(115px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(120px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(120px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(120px * var(--cb-space-x-reverse));
    margin-left: calc(120px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1px * var(--cb-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0.5px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0.5px * var(--cb-space-x-reverse));
    margin-left: calc(0.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1.5px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1.5px * var(--cb-space-x-reverse));
    margin-left: calc(1.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(2.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(2.5px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(2.5px * var(--cb-space-x-reverse));
    margin-left: calc(2.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(3.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(3.5px * var(--cb-space-y-reverse));
  }
  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(3.5px * var(--cb-space-x-reverse));
    margin-left: calc(3.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0px * var(--cb-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1px * var(--cb-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-2px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-2px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-2px * var(--cb-space-x-reverse));
    margin-left: calc(-2px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-3px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-3px * var(--cb-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-4px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-4px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-4px * var(--cb-space-x-reverse));
    margin-left: calc(-4px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-5px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-5px * var(--cb-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-10px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-10px * var(--cb-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-15px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-15px * var(--cb-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-20px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-20px * var(--cb-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-25px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-25px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-25px * var(--cb-space-x-reverse));
    margin-left: calc(-25px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-30px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-30px * var(--cb-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-35px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-35px * var(--cb-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-40px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-40px * var(--cb-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-45px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-45px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-45px * var(--cb-space-x-reverse));
    margin-left: calc(-45px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-50px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-50px * var(--cb-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-55px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-55px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-55px * var(--cb-space-x-reverse));
    margin-left: calc(-55px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-60px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-60px * var(--cb-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-65px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-65px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-65px * var(--cb-space-x-reverse));
    margin-left: calc(-65px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-70px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-70px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-70px * var(--cb-space-x-reverse));
    margin-left: calc(-70px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-75px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-75px * var(--cb-space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-80px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-80px * var(--cb-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-85px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-85px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-85px * var(--cb-space-x-reverse));
    margin-left: calc(-85px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-90px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-90px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-90px * var(--cb-space-x-reverse));
    margin-left: calc(-90px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-95px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-95px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-95px * var(--cb-space-x-reverse));
    margin-left: calc(-95px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-100px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-100px * var(--cb-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-105px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-105px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-105px * var(--cb-space-x-reverse));
    margin-left: calc(-105px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-110px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-110px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-110px * var(--cb-space-x-reverse));
    margin-left: calc(-110px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-115px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-115px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-115px * var(--cb-space-x-reverse));
    margin-left: calc(-115px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-120px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-120px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-120px * var(--cb-space-x-reverse));
    margin-left: calc(-120px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1px * var(--cb-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-0.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-0.5px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-0.5px * var(--cb-space-x-reverse));
    margin-left: calc(-0.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1.5px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1.5px * var(--cb-space-x-reverse));
    margin-left: calc(-1.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-2.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-2.5px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-2.5px * var(--cb-space-x-reverse));
    margin-left: calc(-2.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-3.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-3.5px * var(--cb-space-y-reverse));
  }
  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-3.5px * var(--cb-space-x-reverse));
    margin-left: calc(-3.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 1;
  }
  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 1;
  }
}

@media screen and (max-width: 992px) {
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0px * var(--cb-space-y-reverse));
  }
  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0px * var(--cb-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1px * var(--cb-space-y-reverse));
  }
  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1px * var(--cb-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(2px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(2px * var(--cb-space-y-reverse));
  }
  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(2px * var(--cb-space-x-reverse));
    margin-left: calc(2px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(3px * var(--cb-space-y-reverse));
  }
  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(3px * var(--cb-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(4px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(4px * var(--cb-space-y-reverse));
  }
  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(4px * var(--cb-space-x-reverse));
    margin-left: calc(4px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(5px * var(--cb-space-y-reverse));
  }
  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(5px * var(--cb-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(10px * var(--cb-space-y-reverse));
  }
  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(10px * var(--cb-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(15px * var(--cb-space-y-reverse));
  }
  .md\:space-x-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(15px * var(--cb-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(20px * var(--cb-space-y-reverse));
  }
  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(20px * var(--cb-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(25px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(25px * var(--cb-space-y-reverse));
  }
  .md\:space-x-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(25px * var(--cb-space-x-reverse));
    margin-left: calc(25px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(30px * var(--cb-space-y-reverse));
  }
  .md\:space-x-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(30px * var(--cb-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(35px * var(--cb-space-y-reverse));
  }
  .md\:space-x-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(35px * var(--cb-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(40px * var(--cb-space-y-reverse));
  }
  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(40px * var(--cb-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(45px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(45px * var(--cb-space-y-reverse));
  }
  .md\:space-x-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(45px * var(--cb-space-x-reverse));
    margin-left: calc(45px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(50px * var(--cb-space-y-reverse));
  }
  .md\:space-x-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(50px * var(--cb-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(55px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(55px * var(--cb-space-y-reverse));
  }
  .md\:space-x-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(55px * var(--cb-space-x-reverse));
    margin-left: calc(55px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(60px * var(--cb-space-y-reverse));
  }
  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(60px * var(--cb-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(65px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(65px * var(--cb-space-y-reverse));
  }
  .md\:space-x-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(65px * var(--cb-space-x-reverse));
    margin-left: calc(65px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(70px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(70px * var(--cb-space-y-reverse));
  }
  .md\:space-x-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(70px * var(--cb-space-x-reverse));
    margin-left: calc(70px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(75px * var(--cb-space-y-reverse));
  }
  .md\:space-x-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(75px * var(--cb-space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(80px * var(--cb-space-y-reverse));
  }
  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(80px * var(--cb-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(85px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(85px * var(--cb-space-y-reverse));
  }
  .md\:space-x-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(85px * var(--cb-space-x-reverse));
    margin-left: calc(85px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(90px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(90px * var(--cb-space-y-reverse));
  }
  .md\:space-x-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(90px * var(--cb-space-x-reverse));
    margin-left: calc(90px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(95px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(95px * var(--cb-space-y-reverse));
  }
  .md\:space-x-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(95px * var(--cb-space-x-reverse));
    margin-left: calc(95px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(100px * var(--cb-space-y-reverse));
  }
  .md\:space-x-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(100px * var(--cb-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(105px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(105px * var(--cb-space-y-reverse));
  }
  .md\:space-x-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(105px * var(--cb-space-x-reverse));
    margin-left: calc(105px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(110px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(110px * var(--cb-space-y-reverse));
  }
  .md\:space-x-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(110px * var(--cb-space-x-reverse));
    margin-left: calc(110px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(115px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(115px * var(--cb-space-y-reverse));
  }
  .md\:space-x-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(115px * var(--cb-space-x-reverse));
    margin-left: calc(115px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(120px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(120px * var(--cb-space-y-reverse));
  }
  .md\:space-x-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(120px * var(--cb-space-x-reverse));
    margin-left: calc(120px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1px * var(--cb-space-y-reverse));
  }
  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1px * var(--cb-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0.5px * var(--cb-space-y-reverse));
  }
  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0.5px * var(--cb-space-x-reverse));
    margin-left: calc(0.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1.5px * var(--cb-space-y-reverse));
  }
  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1.5px * var(--cb-space-x-reverse));
    margin-left: calc(1.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(2.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(2.5px * var(--cb-space-y-reverse));
  }
  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(2.5px * var(--cb-space-x-reverse));
    margin-left: calc(2.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(3.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(3.5px * var(--cb-space-y-reverse));
  }
  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(3.5px * var(--cb-space-x-reverse));
    margin-left: calc(3.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0px * var(--cb-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1px * var(--cb-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-2px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-2px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-2px * var(--cb-space-x-reverse));
    margin-left: calc(-2px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-3px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-3px * var(--cb-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-4px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-4px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-4px * var(--cb-space-x-reverse));
    margin-left: calc(-4px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-5px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-5px * var(--cb-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-10px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-10px * var(--cb-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-15px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-15px * var(--cb-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-20px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-20px * var(--cb-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-25px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-25px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-25px * var(--cb-space-x-reverse));
    margin-left: calc(-25px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-30px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-30px * var(--cb-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-35px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-35px * var(--cb-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-40px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-40px * var(--cb-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-45px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-45px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-45px * var(--cb-space-x-reverse));
    margin-left: calc(-45px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-50px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-50px * var(--cb-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-55px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-55px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-55px * var(--cb-space-x-reverse));
    margin-left: calc(-55px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-60px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-60px * var(--cb-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-65px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-65px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-65px * var(--cb-space-x-reverse));
    margin-left: calc(-65px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-70px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-70px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-70px * var(--cb-space-x-reverse));
    margin-left: calc(-70px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-75px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-75px * var(--cb-space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-80px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-80px * var(--cb-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-85px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-85px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-85px * var(--cb-space-x-reverse));
    margin-left: calc(-85px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-90px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-90px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-90px * var(--cb-space-x-reverse));
    margin-left: calc(-90px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-95px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-95px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-95px * var(--cb-space-x-reverse));
    margin-left: calc(-95px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-100px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-100px * var(--cb-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-105px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-105px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-105px * var(--cb-space-x-reverse));
    margin-left: calc(-105px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-110px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-110px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-110px * var(--cb-space-x-reverse));
    margin-left: calc(-110px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-115px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-115px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-115px * var(--cb-space-x-reverse));
    margin-left: calc(-115px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-120px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-120px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-120px * var(--cb-space-x-reverse));
    margin-left: calc(-120px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1px * var(--cb-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-0.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-0.5px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-0.5px * var(--cb-space-x-reverse));
    margin-left: calc(-0.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1.5px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1.5px * var(--cb-space-x-reverse));
    margin-left: calc(-1.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-2.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-2.5px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-2.5px * var(--cb-space-x-reverse));
    margin-left: calc(-2.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-3.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-3.5px * var(--cb-space-y-reverse));
  }
  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-3.5px * var(--cb-space-x-reverse));
    margin-left: calc(-3.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 1;
  }
  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 1;
  }
}

.m-0 {
  margin: 0px;
}

.m-1 {
  margin: 1px;
}

.m-2 {
  margin: 2px;
}

.m-3 {
  margin: 3px;
}

.m-4 {
  margin: 4px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.m-55 {
  margin: 55px;
}

.m-60 {
  margin: 60px;
}

.m-65 {
  margin: 65px;
}

.m-70 {
  margin: 70px;
}

.m-75 {
  margin: 75px;
}

.m-80 {
  margin: 80px;
}

.m-85 {
  margin: 85px;
}

.m-90 {
  margin: 90px;
}

.m-95 {
  margin: 95px;
}

.m-100 {
  margin: 100px;
}

.m-105 {
  margin: 105px;
}

.m-110 {
  margin: 110px;
}

.m-115 {
  margin: 115px;
}

.m-120 {
  margin: 120px;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.m-0\.5 {
  margin: 0.5px;
}

.m-1\.5 {
  margin: 1.5px;
}

.m-2\.5 {
  margin: 2.5px;
}

.m-3\.5 {
  margin: 3.5px;
}

.-m-0 {
  margin: 0px;
}

.-m-1 {
  margin: -1px;
}

.-m-2 {
  margin: -2px;
}

.-m-3 {
  margin: -3px;
}

.-m-4 {
  margin: -4px;
}

.-m-5 {
  margin: -5px;
}

.-m-10 {
  margin: -10px;
}

.-m-15 {
  margin: -15px;
}

.-m-20 {
  margin: -20px;
}

.-m-25 {
  margin: -25px;
}

.-m-30 {
  margin: -30px;
}

.-m-35 {
  margin: -35px;
}

.-m-40 {
  margin: -40px;
}

.-m-45 {
  margin: -45px;
}

.-m-50 {
  margin: -50px;
}

.-m-55 {
  margin: -55px;
}

.-m-60 {
  margin: -60px;
}

.-m-65 {
  margin: -65px;
}

.-m-70 {
  margin: -70px;
}

.-m-75 {
  margin: -75px;
}

.-m-80 {
  margin: -80px;
}

.-m-85 {
  margin: -85px;
}

.-m-90 {
  margin: -90px;
}

.-m-95 {
  margin: -95px;
}

.-m-100 {
  margin: -100px;
}

.-m-105 {
  margin: -105px;
}

.-m-110 {
  margin: -110px;
}

.-m-115 {
  margin: -115px;
}

.-m-120 {
  margin: -120px;
}

.-m-px {
  margin: -1px;
}

.-m-0\.5 {
  margin: -0.5px;
}

.-m-1\.5 {
  margin: -1.5px;
}

.-m-2\.5 {
  margin: -2.5px;
}

.-m-3\.5 {
  margin: -3.5px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.my-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mx-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.my-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mx-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mx-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.my-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mx-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.my-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mx-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.my-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mx-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.my-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mx-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.my-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mx-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.my-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mx-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.my-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mx-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.my-105 {
  margin-top: 105px;
  margin-bottom: 105px;
}

.mx-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.my-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.mx-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.my-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.mx-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.my-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mx-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0\.5 {
  margin-top: 0.5px;
  margin-bottom: 0.5px;
}

.mx-0\.5 {
  margin-left: 0.5px;
  margin-right: 0.5px;
}

.my-1\.5 {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
}

.mx-1\.5 {
  margin-left: 1.5px;
  margin-right: 1.5px;
}

.my-2\.5 {
  margin-top: 2.5px;
  margin-bottom: 2.5px;
}

.mx-2\.5 {
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.my-3\.5 {
  margin-top: 3.5px;
  margin-bottom: 3.5px;
}

.mx-3\.5 {
  margin-left: 3.5px;
  margin-right: 3.5px;
}

.-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.-my-1 {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-1 {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-2 {
  margin-top: -2px;
  margin-bottom: -2px;
}

.-mx-2 {
  margin-left: -2px;
  margin-right: -2px;
}

.-my-3 {
  margin-top: -3px;
  margin-bottom: -3px;
}

.-mx-3 {
  margin-left: -3px;
  margin-right: -3px;
}

.-my-4 {
  margin-top: -4px;
  margin-bottom: -4px;
}

.-mx-4 {
  margin-left: -4px;
  margin-right: -4px;
}

.-my-5 {
  margin-top: -5px;
  margin-bottom: -5px;
}

.-mx-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.-my-10 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-mx-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.-my-15 {
  margin-top: -15px;
  margin-bottom: -15px;
}

.-mx-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.-my-20 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.-mx-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.-my-25 {
  margin-top: -25px;
  margin-bottom: -25px;
}

.-mx-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.-my-30 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.-mx-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.-my-35 {
  margin-top: -35px;
  margin-bottom: -35px;
}

.-mx-35 {
  margin-left: -35px;
  margin-right: -35px;
}

.-my-40 {
  margin-top: -40px;
  margin-bottom: -40px;
}

.-mx-40 {
  margin-left: -40px;
  margin-right: -40px;
}

.-my-45 {
  margin-top: -45px;
  margin-bottom: -45px;
}

.-mx-45 {
  margin-left: -45px;
  margin-right: -45px;
}

.-my-50 {
  margin-top: -50px;
  margin-bottom: -50px;
}

.-mx-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.-my-55 {
  margin-top: -55px;
  margin-bottom: -55px;
}

.-mx-55 {
  margin-left: -55px;
  margin-right: -55px;
}

.-my-60 {
  margin-top: -60px;
  margin-bottom: -60px;
}

.-mx-60 {
  margin-left: -60px;
  margin-right: -60px;
}

.-my-65 {
  margin-top: -65px;
  margin-bottom: -65px;
}

.-mx-65 {
  margin-left: -65px;
  margin-right: -65px;
}

.-my-70 {
  margin-top: -70px;
  margin-bottom: -70px;
}

.-mx-70 {
  margin-left: -70px;
  margin-right: -70px;
}

.-my-75 {
  margin-top: -75px;
  margin-bottom: -75px;
}

.-mx-75 {
  margin-left: -75px;
  margin-right: -75px;
}

.-my-80 {
  margin-top: -80px;
  margin-bottom: -80px;
}

.-mx-80 {
  margin-left: -80px;
  margin-right: -80px;
}

.-my-85 {
  margin-top: -85px;
  margin-bottom: -85px;
}

.-mx-85 {
  margin-left: -85px;
  margin-right: -85px;
}

.-my-90 {
  margin-top: -90px;
  margin-bottom: -90px;
}

.-mx-90 {
  margin-left: -90px;
  margin-right: -90px;
}

.-my-95 {
  margin-top: -95px;
  margin-bottom: -95px;
}

.-mx-95 {
  margin-left: -95px;
  margin-right: -95px;
}

.-my-100 {
  margin-top: -100px;
  margin-bottom: -100px;
}

.-mx-100 {
  margin-left: -100px;
  margin-right: -100px;
}

.-my-105 {
  margin-top: -105px;
  margin-bottom: -105px;
}

.-mx-105 {
  margin-left: -105px;
  margin-right: -105px;
}

.-my-110 {
  margin-top: -110px;
  margin-bottom: -110px;
}

.-mx-110 {
  margin-left: -110px;
  margin-right: -110px;
}

.-my-115 {
  margin-top: -115px;
  margin-bottom: -115px;
}

.-mx-115 {
  margin-left: -115px;
  margin-right: -115px;
}

.-my-120 {
  margin-top: -120px;
  margin-bottom: -120px;
}

.-mx-120 {
  margin-left: -120px;
  margin-right: -120px;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0\.5 {
  margin-top: -0.5px;
  margin-bottom: -0.5px;
}

.-mx-0\.5 {
  margin-left: -0.5px;
  margin-right: -0.5px;
}

.-my-1\.5 {
  margin-top: -1.5px;
  margin-bottom: -1.5px;
}

.-mx-1\.5 {
  margin-left: -1.5px;
  margin-right: -1.5px;
}

.-my-2\.5 {
  margin-top: -2.5px;
  margin-bottom: -2.5px;
}

.-mx-2\.5 {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.-my-3\.5 {
  margin-top: -3.5px;
  margin-bottom: -3.5px;
}

.-mx-3\.5 {
  margin-left: -3.5px;
  margin-right: -3.5px;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px;
}

.mt-1 {
  margin-top: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mr-2 {
  margin-right: 2px;
}

.mb-2 {
  margin-bottom: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mr-3 {
  margin-right: 3px;
}

.mb-3 {
  margin-bottom: 3px;
}

.ml-3 {
  margin-left: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mr-105 {
  margin-right: 105px;
}

.mb-105 {
  margin-bottom: 105px;
}

.ml-105 {
  margin-left: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mr-110 {
  margin-right: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.ml-110 {
  margin-left: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mr-115 {
  margin-right: 115px;
}

.mb-115 {
  margin-bottom: 115px;
}

.ml-115 {
  margin-left: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.ml-120 {
  margin-left: 120px;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.mt-0\.5 {
  margin-top: 0.5px;
}

.mr-0\.5 {
  margin-right: 0.5px;
}

.mb-0\.5 {
  margin-bottom: 0.5px;
}

.ml-0\.5 {
  margin-left: 0.5px;
}

.mt-1\.5 {
  margin-top: 1.5px;
}

.mr-1\.5 {
  margin-right: 1.5px;
}

.mb-1\.5 {
  margin-bottom: 1.5px;
}

.ml-1\.5 {
  margin-left: 1.5px;
}

.mt-2\.5 {
  margin-top: 2.5px;
}

.mr-2\.5 {
  margin-right: 2.5px;
}

.mb-2\.5 {
  margin-bottom: 2.5px;
}

.ml-2\.5 {
  margin-left: 2.5px;
}

.mt-3\.5 {
  margin-top: 3.5px;
}

.mr-3\.5 {
  margin-right: 3.5px;
}

.mb-3\.5 {
  margin-bottom: 3.5px;
}

.ml-3\.5 {
  margin-left: 3.5px;
}

.-mt-0 {
  margin-top: 0px;
}

.-mr-0 {
  margin-right: 0px;
}

.-mb-0 {
  margin-bottom: 0px;
}

.-ml-0 {
  margin-left: 0px;
}

.-mt-1 {
  margin-top: -1px;
}

.-mr-1 {
  margin-right: -1px;
}

.-mb-1 {
  margin-bottom: -1px;
}

.-ml-1 {
  margin-left: -1px;
}

.-mt-2 {
  margin-top: -2px;
}

.-mr-2 {
  margin-right: -2px;
}

.-mb-2 {
  margin-bottom: -2px;
}

.-ml-2 {
  margin-left: -2px;
}

.-mt-3 {
  margin-top: -3px;
}

.-mr-3 {
  margin-right: -3px;
}

.-mb-3 {
  margin-bottom: -3px;
}

.-ml-3 {
  margin-left: -3px;
}

.-mt-4 {
  margin-top: -4px;
}

.-mr-4 {
  margin-right: -4px;
}

.-mb-4 {
  margin-bottom: -4px;
}

.-ml-4 {
  margin-left: -4px;
}

.-mt-5 {
  margin-top: -5px;
}

.-mr-5 {
  margin-right: -5px;
}

.-mb-5 {
  margin-bottom: -5px;
}

.-ml-5 {
  margin-left: -5px;
}

.-mt-10 {
  margin-top: -10px;
}

.-mr-10 {
  margin-right: -10px;
}

.-mb-10 {
  margin-bottom: -10px;
}

.-ml-10 {
  margin-left: -10px;
}

.-mt-15 {
  margin-top: -15px;
}

.-mr-15 {
  margin-right: -15px;
}

.-mb-15 {
  margin-bottom: -15px;
}

.-ml-15 {
  margin-left: -15px;
}

.-mt-20 {
  margin-top: -20px;
}

.-mr-20 {
  margin-right: -20px;
}

.-mb-20 {
  margin-bottom: -20px;
}

.-ml-20 {
  margin-left: -20px;
}

.-mt-25 {
  margin-top: -25px;
}

.-mr-25 {
  margin-right: -25px;
}

.-mb-25 {
  margin-bottom: -25px;
}

.-ml-25 {
  margin-left: -25px;
}

.-mt-30 {
  margin-top: -30px;
}

.-mr-30 {
  margin-right: -30px;
}

.-mb-30 {
  margin-bottom: -30px;
}

.-ml-30 {
  margin-left: -30px;
}

.-mt-35 {
  margin-top: -35px;
}

.-mr-35 {
  margin-right: -35px;
}

.-mb-35 {
  margin-bottom: -35px;
}

.-ml-35 {
  margin-left: -35px;
}

.-mt-40 {
  margin-top: -40px;
}

.-mr-40 {
  margin-right: -40px;
}

.-mb-40 {
  margin-bottom: -40px;
}

.-ml-40 {
  margin-left: -40px;
}

.-mt-45 {
  margin-top: -45px;
}

.-mr-45 {
  margin-right: -45px;
}

.-mb-45 {
  margin-bottom: -45px;
}

.-ml-45 {
  margin-left: -45px;
}

.-mt-50 {
  margin-top: -50px;
}

.-mr-50 {
  margin-right: -50px;
}

.-mb-50 {
  margin-bottom: -50px;
}

.-ml-50 {
  margin-left: -50px;
}

.-mt-55 {
  margin-top: -55px;
}

.-mr-55 {
  margin-right: -55px;
}

.-mb-55 {
  margin-bottom: -55px;
}

.-ml-55 {
  margin-left: -55px;
}

.-mt-60 {
  margin-top: -60px;
}

.-mr-60 {
  margin-right: -60px;
}

.-mb-60 {
  margin-bottom: -60px;
}

.-ml-60 {
  margin-left: -60px;
}

.-mt-65 {
  margin-top: -65px;
}

.-mr-65 {
  margin-right: -65px;
}

.-mb-65 {
  margin-bottom: -65px;
}

.-ml-65 {
  margin-left: -65px;
}

.-mt-70 {
  margin-top: -70px;
}

.-mr-70 {
  margin-right: -70px;
}

.-mb-70 {
  margin-bottom: -70px;
}

.-ml-70 {
  margin-left: -70px;
}

.-mt-75 {
  margin-top: -75px;
}

.-mr-75 {
  margin-right: -75px;
}

.-mb-75 {
  margin-bottom: -75px;
}

.-ml-75 {
  margin-left: -75px;
}

.-mt-80 {
  margin-top: -80px;
}

.-mr-80 {
  margin-right: -80px;
}

.-mb-80 {
  margin-bottom: -80px;
}

.-ml-80 {
  margin-left: -80px;
}

.-mt-85 {
  margin-top: -85px;
}

.-mr-85 {
  margin-right: -85px;
}

.-mb-85 {
  margin-bottom: -85px;
}

.-ml-85 {
  margin-left: -85px;
}

.-mt-90 {
  margin-top: -90px;
}

.-mr-90 {
  margin-right: -90px;
}

.-mb-90 {
  margin-bottom: -90px;
}

.-ml-90 {
  margin-left: -90px;
}

.-mt-95 {
  margin-top: -95px;
}

.-mr-95 {
  margin-right: -95px;
}

.-mb-95 {
  margin-bottom: -95px;
}

.-ml-95 {
  margin-left: -95px;
}

.-mt-100 {
  margin-top: -100px;
}

.-mr-100 {
  margin-right: -100px;
}

.-mb-100 {
  margin-bottom: -100px;
}

.-ml-100 {
  margin-left: -100px;
}

.-mt-105 {
  margin-top: -105px;
}

.-mr-105 {
  margin-right: -105px;
}

.-mb-105 {
  margin-bottom: -105px;
}

.-ml-105 {
  margin-left: -105px;
}

.-mt-110 {
  margin-top: -110px;
}

.-mr-110 {
  margin-right: -110px;
}

.-mb-110 {
  margin-bottom: -110px;
}

.-ml-110 {
  margin-left: -110px;
}

.-mt-115 {
  margin-top: -115px;
}

.-mr-115 {
  margin-right: -115px;
}

.-mb-115 {
  margin-bottom: -115px;
}

.-ml-115 {
  margin-left: -115px;
}

.-mt-120 {
  margin-top: -120px;
}

.-mr-120 {
  margin-right: -120px;
}

.-mb-120 {
  margin-bottom: -120px;
}

.-ml-120 {
  margin-left: -120px;
}

.-mt-px {
  margin-top: -1px;
}

.-mr-px {
  margin-right: -1px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.-mt-0\.5 {
  margin-top: -0.5px;
}

.-mr-0\.5 {
  margin-right: -0.5px;
}

.-mb-0\.5 {
  margin-bottom: -0.5px;
}

.-ml-0\.5 {
  margin-left: -0.5px;
}

.-mt-1\.5 {
  margin-top: -1.5px;
}

.-mr-1\.5 {
  margin-right: -1.5px;
}

.-mb-1\.5 {
  margin-bottom: -1.5px;
}

.-ml-1\.5 {
  margin-left: -1.5px;
}

.-mt-2\.5 {
  margin-top: -2.5px;
}

.-mr-2\.5 {
  margin-right: -2.5px;
}

.-mb-2\.5 {
  margin-bottom: -2.5px;
}

.-ml-2\.5 {
  margin-left: -2.5px;
}

.-mt-3\.5 {
  margin-top: -3.5px;
}

.-mr-3\.5 {
  margin-right: -3.5px;
}

.-mb-3\.5 {
  margin-bottom: -3.5px;
}

.-ml-3\.5 {
  margin-left: -3.5px;
}

@media screen and (max-width: 768px) {
  .sm\:m-0 {
    margin: 0px;
  }
  .sm\:m-1 {
    margin: 1px;
  }
  .sm\:m-2 {
    margin: 2px;
  }
  .sm\:m-3 {
    margin: 3px;
  }
  .sm\:m-4 {
    margin: 4px;
  }
  .sm\:m-5 {
    margin: 5px;
  }
  .sm\:m-10 {
    margin: 10px;
  }
  .sm\:m-15 {
    margin: 15px;
  }
  .sm\:m-20 {
    margin: 20px;
  }
  .sm\:m-25 {
    margin: 25px;
  }
  .sm\:m-30 {
    margin: 30px;
  }
  .sm\:m-35 {
    margin: 35px;
  }
  .sm\:m-40 {
    margin: 40px;
  }
  .sm\:m-45 {
    margin: 45px;
  }
  .sm\:m-50 {
    margin: 50px;
  }
  .sm\:m-55 {
    margin: 55px;
  }
  .sm\:m-60 {
    margin: 60px;
  }
  .sm\:m-65 {
    margin: 65px;
  }
  .sm\:m-70 {
    margin: 70px;
  }
  .sm\:m-75 {
    margin: 75px;
  }
  .sm\:m-80 {
    margin: 80px;
  }
  .sm\:m-85 {
    margin: 85px;
  }
  .sm\:m-90 {
    margin: 90px;
  }
  .sm\:m-95 {
    margin: 95px;
  }
  .sm\:m-100 {
    margin: 100px;
  }
  .sm\:m-105 {
    margin: 105px;
  }
  .sm\:m-110 {
    margin: 110px;
  }
  .sm\:m-115 {
    margin: 115px;
  }
  .sm\:m-120 {
    margin: 120px;
  }
  .sm\:m-auto {
    margin: auto;
  }
  .sm\:m-px {
    margin: 1px;
  }
  .sm\:m-0\.5 {
    margin: 0.5px;
  }
  .sm\:m-1\.5 {
    margin: 1.5px;
  }
  .sm\:m-2\.5 {
    margin: 2.5px;
  }
  .sm\:m-3\.5 {
    margin: 3.5px;
  }
  .sm\:-m-0 {
    margin: 0px;
  }
  .sm\:-m-1 {
    margin: -1px;
  }
  .sm\:-m-2 {
    margin: -2px;
  }
  .sm\:-m-3 {
    margin: -3px;
  }
  .sm\:-m-4 {
    margin: -4px;
  }
  .sm\:-m-5 {
    margin: -5px;
  }
  .sm\:-m-10 {
    margin: -10px;
  }
  .sm\:-m-15 {
    margin: -15px;
  }
  .sm\:-m-20 {
    margin: -20px;
  }
  .sm\:-m-25 {
    margin: -25px;
  }
  .sm\:-m-30 {
    margin: -30px;
  }
  .sm\:-m-35 {
    margin: -35px;
  }
  .sm\:-m-40 {
    margin: -40px;
  }
  .sm\:-m-45 {
    margin: -45px;
  }
  .sm\:-m-50 {
    margin: -50px;
  }
  .sm\:-m-55 {
    margin: -55px;
  }
  .sm\:-m-60 {
    margin: -60px;
  }
  .sm\:-m-65 {
    margin: -65px;
  }
  .sm\:-m-70 {
    margin: -70px;
  }
  .sm\:-m-75 {
    margin: -75px;
  }
  .sm\:-m-80 {
    margin: -80px;
  }
  .sm\:-m-85 {
    margin: -85px;
  }
  .sm\:-m-90 {
    margin: -90px;
  }
  .sm\:-m-95 {
    margin: -95px;
  }
  .sm\:-m-100 {
    margin: -100px;
  }
  .sm\:-m-105 {
    margin: -105px;
  }
  .sm\:-m-110 {
    margin: -110px;
  }
  .sm\:-m-115 {
    margin: -115px;
  }
  .sm\:-m-120 {
    margin: -120px;
  }
  .sm\:-m-px {
    margin: -1px;
  }
  .sm\:-m-0\.5 {
    margin: -0.5px;
  }
  .sm\:-m-1\.5 {
    margin: -1.5px;
  }
  .sm\:-m-2\.5 {
    margin: -2.5px;
  }
  .sm\:-m-3\.5 {
    margin: -3.5px;
  }
  .sm\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sm\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sm\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .sm\:my-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .sm\:mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .sm\:my-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sm\:mx-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .sm\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .sm\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .sm\:my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .sm\:mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .sm\:my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sm\:mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sm\:my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sm\:mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .sm\:my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sm\:mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .sm\:my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .sm\:mx-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .sm\:my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .sm\:mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .sm\:my-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .sm\:mx-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .sm\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .sm\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .sm\:my-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .sm\:mx-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .sm\:my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .sm\:mx-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .sm\:my-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .sm\:mx-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .sm\:my-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .sm\:mx-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .sm\:my-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .sm\:mx-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .sm\:my-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .sm\:mx-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .sm\:my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .sm\:mx-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .sm\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .sm\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .sm\:my-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .sm\:mx-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .sm\:my-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .sm\:mx-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .sm\:my-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .sm\:mx-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .sm\:my-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .sm\:mx-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .sm\:my-105 {
    margin-top: 105px;
    margin-bottom: 105px;
  }
  .sm\:mx-105 {
    margin-left: 105px;
    margin-right: 105px;
  }
  .sm\:my-110 {
    margin-top: 110px;
    margin-bottom: 110px;
  }
  .sm\:mx-110 {
    margin-left: 110px;
    margin-right: 110px;
  }
  .sm\:my-115 {
    margin-top: 115px;
    margin-bottom: 115px;
  }
  .sm\:mx-115 {
    margin-left: 115px;
    margin-right: 115px;
  }
  .sm\:my-120 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .sm\:mx-120 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .sm\:my-0\.5 {
    margin-top: 0.5px;
    margin-bottom: 0.5px;
  }
  .sm\:mx-0\.5 {
    margin-left: 0.5px;
    margin-right: 0.5px;
  }
  .sm\:my-1\.5 {
    margin-top: 1.5px;
    margin-bottom: 1.5px;
  }
  .sm\:mx-1\.5 {
    margin-left: 1.5px;
    margin-right: 1.5px;
  }
  .sm\:my-2\.5 {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
  .sm\:mx-2\.5 {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
  .sm\:my-3\.5 {
    margin-top: 3.5px;
    margin-bottom: 3.5px;
  }
  .sm\:mx-3\.5 {
    margin-left: 3.5px;
    margin-right: 3.5px;
  }
  .sm\:-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .sm\:-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sm\:-my-1 {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .sm\:-mx-1 {
    margin-left: -1px;
    margin-right: -1px;
  }
  .sm\:-my-2 {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .sm\:-mx-2 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .sm\:-my-3 {
    margin-top: -3px;
    margin-bottom: -3px;
  }
  .sm\:-mx-3 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .sm\:-my-4 {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .sm\:-mx-4 {
    margin-left: -4px;
    margin-right: -4px;
  }
  .sm\:-my-5 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .sm\:-mx-5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .sm\:-my-10 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .sm\:-mx-10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .sm\:-my-15 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .sm\:-mx-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm\:-my-20 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .sm\:-mx-20 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .sm\:-my-25 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .sm\:-mx-25 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .sm\:-my-30 {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .sm\:-mx-30 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm\:-my-35 {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .sm\:-mx-35 {
    margin-left: -35px;
    margin-right: -35px;
  }
  .sm\:-my-40 {
    margin-top: -40px;
    margin-bottom: -40px;
  }
  .sm\:-mx-40 {
    margin-left: -40px;
    margin-right: -40px;
  }
  .sm\:-my-45 {
    margin-top: -45px;
    margin-bottom: -45px;
  }
  .sm\:-mx-45 {
    margin-left: -45px;
    margin-right: -45px;
  }
  .sm\:-my-50 {
    margin-top: -50px;
    margin-bottom: -50px;
  }
  .sm\:-mx-50 {
    margin-left: -50px;
    margin-right: -50px;
  }
  .sm\:-my-55 {
    margin-top: -55px;
    margin-bottom: -55px;
  }
  .sm\:-mx-55 {
    margin-left: -55px;
    margin-right: -55px;
  }
  .sm\:-my-60 {
    margin-top: -60px;
    margin-bottom: -60px;
  }
  .sm\:-mx-60 {
    margin-left: -60px;
    margin-right: -60px;
  }
  .sm\:-my-65 {
    margin-top: -65px;
    margin-bottom: -65px;
  }
  .sm\:-mx-65 {
    margin-left: -65px;
    margin-right: -65px;
  }
  .sm\:-my-70 {
    margin-top: -70px;
    margin-bottom: -70px;
  }
  .sm\:-mx-70 {
    margin-left: -70px;
    margin-right: -70px;
  }
  .sm\:-my-75 {
    margin-top: -75px;
    margin-bottom: -75px;
  }
  .sm\:-mx-75 {
    margin-left: -75px;
    margin-right: -75px;
  }
  .sm\:-my-80 {
    margin-top: -80px;
    margin-bottom: -80px;
  }
  .sm\:-mx-80 {
    margin-left: -80px;
    margin-right: -80px;
  }
  .sm\:-my-85 {
    margin-top: -85px;
    margin-bottom: -85px;
  }
  .sm\:-mx-85 {
    margin-left: -85px;
    margin-right: -85px;
  }
  .sm\:-my-90 {
    margin-top: -90px;
    margin-bottom: -90px;
  }
  .sm\:-mx-90 {
    margin-left: -90px;
    margin-right: -90px;
  }
  .sm\:-my-95 {
    margin-top: -95px;
    margin-bottom: -95px;
  }
  .sm\:-mx-95 {
    margin-left: -95px;
    margin-right: -95px;
  }
  .sm\:-my-100 {
    margin-top: -100px;
    margin-bottom: -100px;
  }
  .sm\:-mx-100 {
    margin-left: -100px;
    margin-right: -100px;
  }
  .sm\:-my-105 {
    margin-top: -105px;
    margin-bottom: -105px;
  }
  .sm\:-mx-105 {
    margin-left: -105px;
    margin-right: -105px;
  }
  .sm\:-my-110 {
    margin-top: -110px;
    margin-bottom: -110px;
  }
  .sm\:-mx-110 {
    margin-left: -110px;
    margin-right: -110px;
  }
  .sm\:-my-115 {
    margin-top: -115px;
    margin-bottom: -115px;
  }
  .sm\:-mx-115 {
    margin-left: -115px;
    margin-right: -115px;
  }
  .sm\:-my-120 {
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .sm\:-mx-120 {
    margin-left: -120px;
    margin-right: -120px;
  }
  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .sm\:-my-0\.5 {
    margin-top: -0.5px;
    margin-bottom: -0.5px;
  }
  .sm\:-mx-0\.5 {
    margin-left: -0.5px;
    margin-right: -0.5px;
  }
  .sm\:-my-1\.5 {
    margin-top: -1.5px;
    margin-bottom: -1.5px;
  }
  .sm\:-mx-1\.5 {
    margin-left: -1.5px;
    margin-right: -1.5px;
  }
  .sm\:-my-2\.5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .sm\:-mx-2\.5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .sm\:-my-3\.5 {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .sm\:-mx-3\.5 {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:mr-0 {
    margin-right: 0px;
  }
  .sm\:mb-0 {
    margin-bottom: 0px;
  }
  .sm\:ml-0 {
    margin-left: 0px;
  }
  .sm\:mt-1 {
    margin-top: 1px;
  }
  .sm\:mr-1 {
    margin-right: 1px;
  }
  .sm\:mb-1 {
    margin-bottom: 1px;
  }
  .sm\:ml-1 {
    margin-left: 1px;
  }
  .sm\:mt-2 {
    margin-top: 2px;
  }
  .sm\:mr-2 {
    margin-right: 2px;
  }
  .sm\:mb-2 {
    margin-bottom: 2px;
  }
  .sm\:ml-2 {
    margin-left: 2px;
  }
  .sm\:mt-3 {
    margin-top: 3px;
  }
  .sm\:mr-3 {
    margin-right: 3px;
  }
  .sm\:mb-3 {
    margin-bottom: 3px;
  }
  .sm\:ml-3 {
    margin-left: 3px;
  }
  .sm\:mt-4 {
    margin-top: 4px;
  }
  .sm\:mr-4 {
    margin-right: 4px;
  }
  .sm\:mb-4 {
    margin-bottom: 4px;
  }
  .sm\:ml-4 {
    margin-left: 4px;
  }
  .sm\:mt-5 {
    margin-top: 5px;
  }
  .sm\:mr-5 {
    margin-right: 5px;
  }
  .sm\:mb-5 {
    margin-bottom: 5px;
  }
  .sm\:ml-5 {
    margin-left: 5px;
  }
  .sm\:mt-10 {
    margin-top: 10px;
  }
  .sm\:mr-10 {
    margin-right: 10px;
  }
  .sm\:mb-10 {
    margin-bottom: 10px;
  }
  .sm\:ml-10 {
    margin-left: 10px;
  }
  .sm\:mt-15 {
    margin-top: 15px;
  }
  .sm\:mr-15 {
    margin-right: 15px;
  }
  .sm\:mb-15 {
    margin-bottom: 15px;
  }
  .sm\:ml-15 {
    margin-left: 15px;
  }
  .sm\:mt-20 {
    margin-top: 20px;
  }
  .sm\:mr-20 {
    margin-right: 20px;
  }
  .sm\:mb-20 {
    margin-bottom: 20px;
  }
  .sm\:ml-20 {
    margin-left: 20px;
  }
  .sm\:mt-25 {
    margin-top: 25px;
  }
  .sm\:mr-25 {
    margin-right: 25px;
  }
  .sm\:mb-25 {
    margin-bottom: 25px;
  }
  .sm\:ml-25 {
    margin-left: 25px;
  }
  .sm\:mt-30 {
    margin-top: 30px;
  }
  .sm\:mr-30 {
    margin-right: 30px;
  }
  .sm\:mb-30 {
    margin-bottom: 30px;
  }
  .sm\:ml-30 {
    margin-left: 30px;
  }
  .sm\:mt-35 {
    margin-top: 35px;
  }
  .sm\:mr-35 {
    margin-right: 35px;
  }
  .sm\:mb-35 {
    margin-bottom: 35px;
  }
  .sm\:ml-35 {
    margin-left: 35px;
  }
  .sm\:mt-40 {
    margin-top: 40px;
  }
  .sm\:mr-40 {
    margin-right: 40px;
  }
  .sm\:mb-40 {
    margin-bottom: 40px;
  }
  .sm\:ml-40 {
    margin-left: 40px;
  }
  .sm\:mt-45 {
    margin-top: 45px;
  }
  .sm\:mr-45 {
    margin-right: 45px;
  }
  .sm\:mb-45 {
    margin-bottom: 45px;
  }
  .sm\:ml-45 {
    margin-left: 45px;
  }
  .sm\:mt-50 {
    margin-top: 50px;
  }
  .sm\:mr-50 {
    margin-right: 50px;
  }
  .sm\:mb-50 {
    margin-bottom: 50px;
  }
  .sm\:ml-50 {
    margin-left: 50px;
  }
  .sm\:mt-55 {
    margin-top: 55px;
  }
  .sm\:mr-55 {
    margin-right: 55px;
  }
  .sm\:mb-55 {
    margin-bottom: 55px;
  }
  .sm\:ml-55 {
    margin-left: 55px;
  }
  .sm\:mt-60 {
    margin-top: 60px;
  }
  .sm\:mr-60 {
    margin-right: 60px;
  }
  .sm\:mb-60 {
    margin-bottom: 60px;
  }
  .sm\:ml-60 {
    margin-left: 60px;
  }
  .sm\:mt-65 {
    margin-top: 65px;
  }
  .sm\:mr-65 {
    margin-right: 65px;
  }
  .sm\:mb-65 {
    margin-bottom: 65px;
  }
  .sm\:ml-65 {
    margin-left: 65px;
  }
  .sm\:mt-70 {
    margin-top: 70px;
  }
  .sm\:mr-70 {
    margin-right: 70px;
  }
  .sm\:mb-70 {
    margin-bottom: 70px;
  }
  .sm\:ml-70 {
    margin-left: 70px;
  }
  .sm\:mt-75 {
    margin-top: 75px;
  }
  .sm\:mr-75 {
    margin-right: 75px;
  }
  .sm\:mb-75 {
    margin-bottom: 75px;
  }
  .sm\:ml-75 {
    margin-left: 75px;
  }
  .sm\:mt-80 {
    margin-top: 80px;
  }
  .sm\:mr-80 {
    margin-right: 80px;
  }
  .sm\:mb-80 {
    margin-bottom: 80px;
  }
  .sm\:ml-80 {
    margin-left: 80px;
  }
  .sm\:mt-85 {
    margin-top: 85px;
  }
  .sm\:mr-85 {
    margin-right: 85px;
  }
  .sm\:mb-85 {
    margin-bottom: 85px;
  }
  .sm\:ml-85 {
    margin-left: 85px;
  }
  .sm\:mt-90 {
    margin-top: 90px;
  }
  .sm\:mr-90 {
    margin-right: 90px;
  }
  .sm\:mb-90 {
    margin-bottom: 90px;
  }
  .sm\:ml-90 {
    margin-left: 90px;
  }
  .sm\:mt-95 {
    margin-top: 95px;
  }
  .sm\:mr-95 {
    margin-right: 95px;
  }
  .sm\:mb-95 {
    margin-bottom: 95px;
  }
  .sm\:ml-95 {
    margin-left: 95px;
  }
  .sm\:mt-100 {
    margin-top: 100px;
  }
  .sm\:mr-100 {
    margin-right: 100px;
  }
  .sm\:mb-100 {
    margin-bottom: 100px;
  }
  .sm\:ml-100 {
    margin-left: 100px;
  }
  .sm\:mt-105 {
    margin-top: 105px;
  }
  .sm\:mr-105 {
    margin-right: 105px;
  }
  .sm\:mb-105 {
    margin-bottom: 105px;
  }
  .sm\:ml-105 {
    margin-left: 105px;
  }
  .sm\:mt-110 {
    margin-top: 110px;
  }
  .sm\:mr-110 {
    margin-right: 110px;
  }
  .sm\:mb-110 {
    margin-bottom: 110px;
  }
  .sm\:ml-110 {
    margin-left: 110px;
  }
  .sm\:mt-115 {
    margin-top: 115px;
  }
  .sm\:mr-115 {
    margin-right: 115px;
  }
  .sm\:mb-115 {
    margin-bottom: 115px;
  }
  .sm\:ml-115 {
    margin-left: 115px;
  }
  .sm\:mt-120 {
    margin-top: 120px;
  }
  .sm\:mr-120 {
    margin-right: 120px;
  }
  .sm\:mb-120 {
    margin-bottom: 120px;
  }
  .sm\:ml-120 {
    margin-left: 120px;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mb-auto {
    margin-bottom: auto;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:mt-px {
    margin-top: 1px;
  }
  .sm\:mr-px {
    margin-right: 1px;
  }
  .sm\:mb-px {
    margin-bottom: 1px;
  }
  .sm\:ml-px {
    margin-left: 1px;
  }
  .sm\:mt-0\.5 {
    margin-top: 0.5px;
  }
  .sm\:mr-0\.5 {
    margin-right: 0.5px;
  }
  .sm\:mb-0\.5 {
    margin-bottom: 0.5px;
  }
  .sm\:ml-0\.5 {
    margin-left: 0.5px;
  }
  .sm\:mt-1\.5 {
    margin-top: 1.5px;
  }
  .sm\:mr-1\.5 {
    margin-right: 1.5px;
  }
  .sm\:mb-1\.5 {
    margin-bottom: 1.5px;
  }
  .sm\:ml-1\.5 {
    margin-left: 1.5px;
  }
  .sm\:mt-2\.5 {
    margin-top: 2.5px;
  }
  .sm\:mr-2\.5 {
    margin-right: 2.5px;
  }
  .sm\:mb-2\.5 {
    margin-bottom: 2.5px;
  }
  .sm\:ml-2\.5 {
    margin-left: 2.5px;
  }
  .sm\:mt-3\.5 {
    margin-top: 3.5px;
  }
  .sm\:mr-3\.5 {
    margin-right: 3.5px;
  }
  .sm\:mb-3\.5 {
    margin-bottom: 3.5px;
  }
  .sm\:ml-3\.5 {
    margin-left: 3.5px;
  }
  .sm\:-mt-0 {
    margin-top: 0px;
  }
  .sm\:-mr-0 {
    margin-right: 0px;
  }
  .sm\:-mb-0 {
    margin-bottom: 0px;
  }
  .sm\:-ml-0 {
    margin-left: 0px;
  }
  .sm\:-mt-1 {
    margin-top: -1px;
  }
  .sm\:-mr-1 {
    margin-right: -1px;
  }
  .sm\:-mb-1 {
    margin-bottom: -1px;
  }
  .sm\:-ml-1 {
    margin-left: -1px;
  }
  .sm\:-mt-2 {
    margin-top: -2px;
  }
  .sm\:-mr-2 {
    margin-right: -2px;
  }
  .sm\:-mb-2 {
    margin-bottom: -2px;
  }
  .sm\:-ml-2 {
    margin-left: -2px;
  }
  .sm\:-mt-3 {
    margin-top: -3px;
  }
  .sm\:-mr-3 {
    margin-right: -3px;
  }
  .sm\:-mb-3 {
    margin-bottom: -3px;
  }
  .sm\:-ml-3 {
    margin-left: -3px;
  }
  .sm\:-mt-4 {
    margin-top: -4px;
  }
  .sm\:-mr-4 {
    margin-right: -4px;
  }
  .sm\:-mb-4 {
    margin-bottom: -4px;
  }
  .sm\:-ml-4 {
    margin-left: -4px;
  }
  .sm\:-mt-5 {
    margin-top: -5px;
  }
  .sm\:-mr-5 {
    margin-right: -5px;
  }
  .sm\:-mb-5 {
    margin-bottom: -5px;
  }
  .sm\:-ml-5 {
    margin-left: -5px;
  }
  .sm\:-mt-10 {
    margin-top: -10px;
  }
  .sm\:-mr-10 {
    margin-right: -10px;
  }
  .sm\:-mb-10 {
    margin-bottom: -10px;
  }
  .sm\:-ml-10 {
    margin-left: -10px;
  }
  .sm\:-mt-15 {
    margin-top: -15px;
  }
  .sm\:-mr-15 {
    margin-right: -15px;
  }
  .sm\:-mb-15 {
    margin-bottom: -15px;
  }
  .sm\:-ml-15 {
    margin-left: -15px;
  }
  .sm\:-mt-20 {
    margin-top: -20px;
  }
  .sm\:-mr-20 {
    margin-right: -20px;
  }
  .sm\:-mb-20 {
    margin-bottom: -20px;
  }
  .sm\:-ml-20 {
    margin-left: -20px;
  }
  .sm\:-mt-25 {
    margin-top: -25px;
  }
  .sm\:-mr-25 {
    margin-right: -25px;
  }
  .sm\:-mb-25 {
    margin-bottom: -25px;
  }
  .sm\:-ml-25 {
    margin-left: -25px;
  }
  .sm\:-mt-30 {
    margin-top: -30px;
  }
  .sm\:-mr-30 {
    margin-right: -30px;
  }
  .sm\:-mb-30 {
    margin-bottom: -30px;
  }
  .sm\:-ml-30 {
    margin-left: -30px;
  }
  .sm\:-mt-35 {
    margin-top: -35px;
  }
  .sm\:-mr-35 {
    margin-right: -35px;
  }
  .sm\:-mb-35 {
    margin-bottom: -35px;
  }
  .sm\:-ml-35 {
    margin-left: -35px;
  }
  .sm\:-mt-40 {
    margin-top: -40px;
  }
  .sm\:-mr-40 {
    margin-right: -40px;
  }
  .sm\:-mb-40 {
    margin-bottom: -40px;
  }
  .sm\:-ml-40 {
    margin-left: -40px;
  }
  .sm\:-mt-45 {
    margin-top: -45px;
  }
  .sm\:-mr-45 {
    margin-right: -45px;
  }
  .sm\:-mb-45 {
    margin-bottom: -45px;
  }
  .sm\:-ml-45 {
    margin-left: -45px;
  }
  .sm\:-mt-50 {
    margin-top: -50px;
  }
  .sm\:-mr-50 {
    margin-right: -50px;
  }
  .sm\:-mb-50 {
    margin-bottom: -50px;
  }
  .sm\:-ml-50 {
    margin-left: -50px;
  }
  .sm\:-mt-55 {
    margin-top: -55px;
  }
  .sm\:-mr-55 {
    margin-right: -55px;
  }
  .sm\:-mb-55 {
    margin-bottom: -55px;
  }
  .sm\:-ml-55 {
    margin-left: -55px;
  }
  .sm\:-mt-60 {
    margin-top: -60px;
  }
  .sm\:-mr-60 {
    margin-right: -60px;
  }
  .sm\:-mb-60 {
    margin-bottom: -60px;
  }
  .sm\:-ml-60 {
    margin-left: -60px;
  }
  .sm\:-mt-65 {
    margin-top: -65px;
  }
  .sm\:-mr-65 {
    margin-right: -65px;
  }
  .sm\:-mb-65 {
    margin-bottom: -65px;
  }
  .sm\:-ml-65 {
    margin-left: -65px;
  }
  .sm\:-mt-70 {
    margin-top: -70px;
  }
  .sm\:-mr-70 {
    margin-right: -70px;
  }
  .sm\:-mb-70 {
    margin-bottom: -70px;
  }
  .sm\:-ml-70 {
    margin-left: -70px;
  }
  .sm\:-mt-75 {
    margin-top: -75px;
  }
  .sm\:-mr-75 {
    margin-right: -75px;
  }
  .sm\:-mb-75 {
    margin-bottom: -75px;
  }
  .sm\:-ml-75 {
    margin-left: -75px;
  }
  .sm\:-mt-80 {
    margin-top: -80px;
  }
  .sm\:-mr-80 {
    margin-right: -80px;
  }
  .sm\:-mb-80 {
    margin-bottom: -80px;
  }
  .sm\:-ml-80 {
    margin-left: -80px;
  }
  .sm\:-mt-85 {
    margin-top: -85px;
  }
  .sm\:-mr-85 {
    margin-right: -85px;
  }
  .sm\:-mb-85 {
    margin-bottom: -85px;
  }
  .sm\:-ml-85 {
    margin-left: -85px;
  }
  .sm\:-mt-90 {
    margin-top: -90px;
  }
  .sm\:-mr-90 {
    margin-right: -90px;
  }
  .sm\:-mb-90 {
    margin-bottom: -90px;
  }
  .sm\:-ml-90 {
    margin-left: -90px;
  }
  .sm\:-mt-95 {
    margin-top: -95px;
  }
  .sm\:-mr-95 {
    margin-right: -95px;
  }
  .sm\:-mb-95 {
    margin-bottom: -95px;
  }
  .sm\:-ml-95 {
    margin-left: -95px;
  }
  .sm\:-mt-100 {
    margin-top: -100px;
  }
  .sm\:-mr-100 {
    margin-right: -100px;
  }
  .sm\:-mb-100 {
    margin-bottom: -100px;
  }
  .sm\:-ml-100 {
    margin-left: -100px;
  }
  .sm\:-mt-105 {
    margin-top: -105px;
  }
  .sm\:-mr-105 {
    margin-right: -105px;
  }
  .sm\:-mb-105 {
    margin-bottom: -105px;
  }
  .sm\:-ml-105 {
    margin-left: -105px;
  }
  .sm\:-mt-110 {
    margin-top: -110px;
  }
  .sm\:-mr-110 {
    margin-right: -110px;
  }
  .sm\:-mb-110 {
    margin-bottom: -110px;
  }
  .sm\:-ml-110 {
    margin-left: -110px;
  }
  .sm\:-mt-115 {
    margin-top: -115px;
  }
  .sm\:-mr-115 {
    margin-right: -115px;
  }
  .sm\:-mb-115 {
    margin-bottom: -115px;
  }
  .sm\:-ml-115 {
    margin-left: -115px;
  }
  .sm\:-mt-120 {
    margin-top: -120px;
  }
  .sm\:-mr-120 {
    margin-right: -120px;
  }
  .sm\:-mb-120 {
    margin-bottom: -120px;
  }
  .sm\:-ml-120 {
    margin-left: -120px;
  }
  .sm\:-mt-px {
    margin-top: -1px;
  }
  .sm\:-mr-px {
    margin-right: -1px;
  }
  .sm\:-mb-px {
    margin-bottom: -1px;
  }
  .sm\:-ml-px {
    margin-left: -1px;
  }
  .sm\:-mt-0\.5 {
    margin-top: -0.5px;
  }
  .sm\:-mr-0\.5 {
    margin-right: -0.5px;
  }
  .sm\:-mb-0\.5 {
    margin-bottom: -0.5px;
  }
  .sm\:-ml-0\.5 {
    margin-left: -0.5px;
  }
  .sm\:-mt-1\.5 {
    margin-top: -1.5px;
  }
  .sm\:-mr-1\.5 {
    margin-right: -1.5px;
  }
  .sm\:-mb-1\.5 {
    margin-bottom: -1.5px;
  }
  .sm\:-ml-1\.5 {
    margin-left: -1.5px;
  }
  .sm\:-mt-2\.5 {
    margin-top: -2.5px;
  }
  .sm\:-mr-2\.5 {
    margin-right: -2.5px;
  }
  .sm\:-mb-2\.5 {
    margin-bottom: -2.5px;
  }
  .sm\:-ml-2\.5 {
    margin-left: -2.5px;
  }
  .sm\:-mt-3\.5 {
    margin-top: -3.5px;
  }
  .sm\:-mr-3\.5 {
    margin-right: -3.5px;
  }
  .sm\:-mb-3\.5 {
    margin-bottom: -3.5px;
  }
  .sm\:-ml-3\.5 {
    margin-left: -3.5px;
  }
}

@media screen and (max-width: 992px) {
  .md\:m-0 {
    margin: 0px;
  }
  .md\:m-1 {
    margin: 1px;
  }
  .md\:m-2 {
    margin: 2px;
  }
  .md\:m-3 {
    margin: 3px;
  }
  .md\:m-4 {
    margin: 4px;
  }
  .md\:m-5 {
    margin: 5px;
  }
  .md\:m-10 {
    margin: 10px;
  }
  .md\:m-15 {
    margin: 15px;
  }
  .md\:m-20 {
    margin: 20px;
  }
  .md\:m-25 {
    margin: 25px;
  }
  .md\:m-30 {
    margin: 30px;
  }
  .md\:m-35 {
    margin: 35px;
  }
  .md\:m-40 {
    margin: 40px;
  }
  .md\:m-45 {
    margin: 45px;
  }
  .md\:m-50 {
    margin: 50px;
  }
  .md\:m-55 {
    margin: 55px;
  }
  .md\:m-60 {
    margin: 60px;
  }
  .md\:m-65 {
    margin: 65px;
  }
  .md\:m-70 {
    margin: 70px;
  }
  .md\:m-75 {
    margin: 75px;
  }
  .md\:m-80 {
    margin: 80px;
  }
  .md\:m-85 {
    margin: 85px;
  }
  .md\:m-90 {
    margin: 90px;
  }
  .md\:m-95 {
    margin: 95px;
  }
  .md\:m-100 {
    margin: 100px;
  }
  .md\:m-105 {
    margin: 105px;
  }
  .md\:m-110 {
    margin: 110px;
  }
  .md\:m-115 {
    margin: 115px;
  }
  .md\:m-120 {
    margin: 120px;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:m-px {
    margin: 1px;
  }
  .md\:m-0\.5 {
    margin: 0.5px;
  }
  .md\:m-1\.5 {
    margin: 1.5px;
  }
  .md\:m-2\.5 {
    margin: 2.5px;
  }
  .md\:m-3\.5 {
    margin: 3.5px;
  }
  .md\:-m-0 {
    margin: 0px;
  }
  .md\:-m-1 {
    margin: -1px;
  }
  .md\:-m-2 {
    margin: -2px;
  }
  .md\:-m-3 {
    margin: -3px;
  }
  .md\:-m-4 {
    margin: -4px;
  }
  .md\:-m-5 {
    margin: -5px;
  }
  .md\:-m-10 {
    margin: -10px;
  }
  .md\:-m-15 {
    margin: -15px;
  }
  .md\:-m-20 {
    margin: -20px;
  }
  .md\:-m-25 {
    margin: -25px;
  }
  .md\:-m-30 {
    margin: -30px;
  }
  .md\:-m-35 {
    margin: -35px;
  }
  .md\:-m-40 {
    margin: -40px;
  }
  .md\:-m-45 {
    margin: -45px;
  }
  .md\:-m-50 {
    margin: -50px;
  }
  .md\:-m-55 {
    margin: -55px;
  }
  .md\:-m-60 {
    margin: -60px;
  }
  .md\:-m-65 {
    margin: -65px;
  }
  .md\:-m-70 {
    margin: -70px;
  }
  .md\:-m-75 {
    margin: -75px;
  }
  .md\:-m-80 {
    margin: -80px;
  }
  .md\:-m-85 {
    margin: -85px;
  }
  .md\:-m-90 {
    margin: -90px;
  }
  .md\:-m-95 {
    margin: -95px;
  }
  .md\:-m-100 {
    margin: -100px;
  }
  .md\:-m-105 {
    margin: -105px;
  }
  .md\:-m-110 {
    margin: -110px;
  }
  .md\:-m-115 {
    margin: -115px;
  }
  .md\:-m-120 {
    margin: -120px;
  }
  .md\:-m-px {
    margin: -1px;
  }
  .md\:-m-0\.5 {
    margin: -0.5px;
  }
  .md\:-m-1\.5 {
    margin: -1.5px;
  }
  .md\:-m-2\.5 {
    margin: -2.5px;
  }
  .md\:-m-3\.5 {
    margin: -3.5px;
  }
  .md\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .md\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .md\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .md\:my-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .md\:mx-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .md\:my-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .md\:mx-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .md\:my-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .md\:mx-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .md\:my-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .md\:mx-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .md\:my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .md\:mx-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .md\:my-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .md\:mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .md\:my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .md\:mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .md\:my-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .md\:mx-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .md\:my-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .md\:mx-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .md\:my-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .md\:mx-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .md\:my-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .md\:mx-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .md\:my-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .md\:mx-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .md\:my-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .md\:mx-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .md\:my-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .md\:mx-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .md\:my-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .md\:mx-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .md\:my-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .md\:mx-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .md\:my-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .md\:mx-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .md\:my-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .md\:mx-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .md\:my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .md\:mx-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .md\:my-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .md\:mx-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .md\:my-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .md\:mx-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .md\:my-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .md\:mx-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .md\:my-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .md\:mx-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .md\:my-105 {
    margin-top: 105px;
    margin-bottom: 105px;
  }
  .md\:mx-105 {
    margin-left: 105px;
    margin-right: 105px;
  }
  .md\:my-110 {
    margin-top: 110px;
    margin-bottom: 110px;
  }
  .md\:mx-110 {
    margin-left: 110px;
    margin-right: 110px;
  }
  .md\:my-115 {
    margin-top: 115px;
    margin-bottom: 115px;
  }
  .md\:mx-115 {
    margin-left: 115px;
    margin-right: 115px;
  }
  .md\:my-120 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .md\:mx-120 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .md\:my-0\.5 {
    margin-top: 0.5px;
    margin-bottom: 0.5px;
  }
  .md\:mx-0\.5 {
    margin-left: 0.5px;
    margin-right: 0.5px;
  }
  .md\:my-1\.5 {
    margin-top: 1.5px;
    margin-bottom: 1.5px;
  }
  .md\:mx-1\.5 {
    margin-left: 1.5px;
    margin-right: 1.5px;
  }
  .md\:my-2\.5 {
    margin-top: 2.5px;
    margin-bottom: 2.5px;
  }
  .md\:mx-2\.5 {
    margin-left: 2.5px;
    margin-right: 2.5px;
  }
  .md\:my-3\.5 {
    margin-top: 3.5px;
    margin-bottom: 3.5px;
  }
  .md\:mx-3\.5 {
    margin-left: 3.5px;
    margin-right: 3.5px;
  }
  .md\:-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .md\:-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .md\:-my-1 {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .md\:-mx-1 {
    margin-left: -1px;
    margin-right: -1px;
  }
  .md\:-my-2 {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .md\:-mx-2 {
    margin-left: -2px;
    margin-right: -2px;
  }
  .md\:-my-3 {
    margin-top: -3px;
    margin-bottom: -3px;
  }
  .md\:-mx-3 {
    margin-left: -3px;
    margin-right: -3px;
  }
  .md\:-my-4 {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .md\:-mx-4 {
    margin-left: -4px;
    margin-right: -4px;
  }
  .md\:-my-5 {
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .md\:-mx-5 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .md\:-my-10 {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .md\:-mx-10 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .md\:-my-15 {
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .md\:-mx-15 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md\:-my-20 {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .md\:-mx-20 {
    margin-left: -20px;
    margin-right: -20px;
  }
  .md\:-my-25 {
    margin-top: -25px;
    margin-bottom: -25px;
  }
  .md\:-mx-25 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .md\:-my-30 {
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .md\:-mx-30 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md\:-my-35 {
    margin-top: -35px;
    margin-bottom: -35px;
  }
  .md\:-mx-35 {
    margin-left: -35px;
    margin-right: -35px;
  }
  .md\:-my-40 {
    margin-top: -40px;
    margin-bottom: -40px;
  }
  .md\:-mx-40 {
    margin-left: -40px;
    margin-right: -40px;
  }
  .md\:-my-45 {
    margin-top: -45px;
    margin-bottom: -45px;
  }
  .md\:-mx-45 {
    margin-left: -45px;
    margin-right: -45px;
  }
  .md\:-my-50 {
    margin-top: -50px;
    margin-bottom: -50px;
  }
  .md\:-mx-50 {
    margin-left: -50px;
    margin-right: -50px;
  }
  .md\:-my-55 {
    margin-top: -55px;
    margin-bottom: -55px;
  }
  .md\:-mx-55 {
    margin-left: -55px;
    margin-right: -55px;
  }
  .md\:-my-60 {
    margin-top: -60px;
    margin-bottom: -60px;
  }
  .md\:-mx-60 {
    margin-left: -60px;
    margin-right: -60px;
  }
  .md\:-my-65 {
    margin-top: -65px;
    margin-bottom: -65px;
  }
  .md\:-mx-65 {
    margin-left: -65px;
    margin-right: -65px;
  }
  .md\:-my-70 {
    margin-top: -70px;
    margin-bottom: -70px;
  }
  .md\:-mx-70 {
    margin-left: -70px;
    margin-right: -70px;
  }
  .md\:-my-75 {
    margin-top: -75px;
    margin-bottom: -75px;
  }
  .md\:-mx-75 {
    margin-left: -75px;
    margin-right: -75px;
  }
  .md\:-my-80 {
    margin-top: -80px;
    margin-bottom: -80px;
  }
  .md\:-mx-80 {
    margin-left: -80px;
    margin-right: -80px;
  }
  .md\:-my-85 {
    margin-top: -85px;
    margin-bottom: -85px;
  }
  .md\:-mx-85 {
    margin-left: -85px;
    margin-right: -85px;
  }
  .md\:-my-90 {
    margin-top: -90px;
    margin-bottom: -90px;
  }
  .md\:-mx-90 {
    margin-left: -90px;
    margin-right: -90px;
  }
  .md\:-my-95 {
    margin-top: -95px;
    margin-bottom: -95px;
  }
  .md\:-mx-95 {
    margin-left: -95px;
    margin-right: -95px;
  }
  .md\:-my-100 {
    margin-top: -100px;
    margin-bottom: -100px;
  }
  .md\:-mx-100 {
    margin-left: -100px;
    margin-right: -100px;
  }
  .md\:-my-105 {
    margin-top: -105px;
    margin-bottom: -105px;
  }
  .md\:-mx-105 {
    margin-left: -105px;
    margin-right: -105px;
  }
  .md\:-my-110 {
    margin-top: -110px;
    margin-bottom: -110px;
  }
  .md\:-mx-110 {
    margin-left: -110px;
    margin-right: -110px;
  }
  .md\:-my-115 {
    margin-top: -115px;
    margin-bottom: -115px;
  }
  .md\:-mx-115 {
    margin-left: -115px;
    margin-right: -115px;
  }
  .md\:-my-120 {
    margin-top: -120px;
    margin-bottom: -120px;
  }
  .md\:-mx-120 {
    margin-left: -120px;
    margin-right: -120px;
  }
  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .md\:-my-0\.5 {
    margin-top: -0.5px;
    margin-bottom: -0.5px;
  }
  .md\:-mx-0\.5 {
    margin-left: -0.5px;
    margin-right: -0.5px;
  }
  .md\:-my-1\.5 {
    margin-top: -1.5px;
    margin-bottom: -1.5px;
  }
  .md\:-mx-1\.5 {
    margin-left: -1.5px;
    margin-right: -1.5px;
  }
  .md\:-my-2\.5 {
    margin-top: -2.5px;
    margin-bottom: -2.5px;
  }
  .md\:-mx-2\.5 {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  .md\:-my-3\.5 {
    margin-top: -3.5px;
    margin-bottom: -3.5px;
  }
  .md\:-mx-3\.5 {
    margin-left: -3.5px;
    margin-right: -3.5px;
  }
  .md\:mt-0 {
    margin-top: 0px;
  }
  .md\:mr-0 {
    margin-right: 0px;
  }
  .md\:mb-0 {
    margin-bottom: 0px;
  }
  .md\:ml-0 {
    margin-left: 0px;
  }
  .md\:mt-1 {
    margin-top: 1px;
  }
  .md\:mr-1 {
    margin-right: 1px;
  }
  .md\:mb-1 {
    margin-bottom: 1px;
  }
  .md\:ml-1 {
    margin-left: 1px;
  }
  .md\:mt-2 {
    margin-top: 2px;
  }
  .md\:mr-2 {
    margin-right: 2px;
  }
  .md\:mb-2 {
    margin-bottom: 2px;
  }
  .md\:ml-2 {
    margin-left: 2px;
  }
  .md\:mt-3 {
    margin-top: 3px;
  }
  .md\:mr-3 {
    margin-right: 3px;
  }
  .md\:mb-3 {
    margin-bottom: 3px;
  }
  .md\:ml-3 {
    margin-left: 3px;
  }
  .md\:mt-4 {
    margin-top: 4px;
  }
  .md\:mr-4 {
    margin-right: 4px;
  }
  .md\:mb-4 {
    margin-bottom: 4px;
  }
  .md\:ml-4 {
    margin-left: 4px;
  }
  .md\:mt-5 {
    margin-top: 5px;
  }
  .md\:mr-5 {
    margin-right: 5px;
  }
  .md\:mb-5 {
    margin-bottom: 5px;
  }
  .md\:ml-5 {
    margin-left: 5px;
  }
  .md\:mt-10 {
    margin-top: 10px;
  }
  .md\:mr-10 {
    margin-right: 10px;
  }
  .md\:mb-10 {
    margin-bottom: 10px;
  }
  .md\:ml-10 {
    margin-left: 10px;
  }
  .md\:mt-15 {
    margin-top: 15px;
  }
  .md\:mr-15 {
    margin-right: 15px;
  }
  .md\:mb-15 {
    margin-bottom: 15px;
  }
  .md\:ml-15 {
    margin-left: 15px;
  }
  .md\:mt-20 {
    margin-top: 20px;
  }
  .md\:mr-20 {
    margin-right: 20px;
  }
  .md\:mb-20 {
    margin-bottom: 20px;
  }
  .md\:ml-20 {
    margin-left: 20px;
  }
  .md\:mt-25 {
    margin-top: 25px;
  }
  .md\:mr-25 {
    margin-right: 25px;
  }
  .md\:mb-25 {
    margin-bottom: 25px;
  }
  .md\:ml-25 {
    margin-left: 25px;
  }
  .md\:mt-30 {
    margin-top: 30px;
  }
  .md\:mr-30 {
    margin-right: 30px;
  }
  .md\:mb-30 {
    margin-bottom: 30px;
  }
  .md\:ml-30 {
    margin-left: 30px;
  }
  .md\:mt-35 {
    margin-top: 35px;
  }
  .md\:mr-35 {
    margin-right: 35px;
  }
  .md\:mb-35 {
    margin-bottom: 35px;
  }
  .md\:ml-35 {
    margin-left: 35px;
  }
  .md\:mt-40 {
    margin-top: 40px;
  }
  .md\:mr-40 {
    margin-right: 40px;
  }
  .md\:mb-40 {
    margin-bottom: 40px;
  }
  .md\:ml-40 {
    margin-left: 40px;
  }
  .md\:mt-45 {
    margin-top: 45px;
  }
  .md\:mr-45 {
    margin-right: 45px;
  }
  .md\:mb-45 {
    margin-bottom: 45px;
  }
  .md\:ml-45 {
    margin-left: 45px;
  }
  .md\:mt-50 {
    margin-top: 50px;
  }
  .md\:mr-50 {
    margin-right: 50px;
  }
  .md\:mb-50 {
    margin-bottom: 50px;
  }
  .md\:ml-50 {
    margin-left: 50px;
  }
  .md\:mt-55 {
    margin-top: 55px;
  }
  .md\:mr-55 {
    margin-right: 55px;
  }
  .md\:mb-55 {
    margin-bottom: 55px;
  }
  .md\:ml-55 {
    margin-left: 55px;
  }
  .md\:mt-60 {
    margin-top: 60px;
  }
  .md\:mr-60 {
    margin-right: 60px;
  }
  .md\:mb-60 {
    margin-bottom: 60px;
  }
  .md\:ml-60 {
    margin-left: 60px;
  }
  .md\:mt-65 {
    margin-top: 65px;
  }
  .md\:mr-65 {
    margin-right: 65px;
  }
  .md\:mb-65 {
    margin-bottom: 65px;
  }
  .md\:ml-65 {
    margin-left: 65px;
  }
  .md\:mt-70 {
    margin-top: 70px;
  }
  .md\:mr-70 {
    margin-right: 70px;
  }
  .md\:mb-70 {
    margin-bottom: 70px;
  }
  .md\:ml-70 {
    margin-left: 70px;
  }
  .md\:mt-75 {
    margin-top: 75px;
  }
  .md\:mr-75 {
    margin-right: 75px;
  }
  .md\:mb-75 {
    margin-bottom: 75px;
  }
  .md\:ml-75 {
    margin-left: 75px;
  }
  .md\:mt-80 {
    margin-top: 80px;
  }
  .md\:mr-80 {
    margin-right: 80px;
  }
  .md\:mb-80 {
    margin-bottom: 80px;
  }
  .md\:ml-80 {
    margin-left: 80px;
  }
  .md\:mt-85 {
    margin-top: 85px;
  }
  .md\:mr-85 {
    margin-right: 85px;
  }
  .md\:mb-85 {
    margin-bottom: 85px;
  }
  .md\:ml-85 {
    margin-left: 85px;
  }
  .md\:mt-90 {
    margin-top: 90px;
  }
  .md\:mr-90 {
    margin-right: 90px;
  }
  .md\:mb-90 {
    margin-bottom: 90px;
  }
  .md\:ml-90 {
    margin-left: 90px;
  }
  .md\:mt-95 {
    margin-top: 95px;
  }
  .md\:mr-95 {
    margin-right: 95px;
  }
  .md\:mb-95 {
    margin-bottom: 95px;
  }
  .md\:ml-95 {
    margin-left: 95px;
  }
  .md\:mt-100 {
    margin-top: 100px;
  }
  .md\:mr-100 {
    margin-right: 100px;
  }
  .md\:mb-100 {
    margin-bottom: 100px;
  }
  .md\:ml-100 {
    margin-left: 100px;
  }
  .md\:mt-105 {
    margin-top: 105px;
  }
  .md\:mr-105 {
    margin-right: 105px;
  }
  .md\:mb-105 {
    margin-bottom: 105px;
  }
  .md\:ml-105 {
    margin-left: 105px;
  }
  .md\:mt-110 {
    margin-top: 110px;
  }
  .md\:mr-110 {
    margin-right: 110px;
  }
  .md\:mb-110 {
    margin-bottom: 110px;
  }
  .md\:ml-110 {
    margin-left: 110px;
  }
  .md\:mt-115 {
    margin-top: 115px;
  }
  .md\:mr-115 {
    margin-right: 115px;
  }
  .md\:mb-115 {
    margin-bottom: 115px;
  }
  .md\:ml-115 {
    margin-left: 115px;
  }
  .md\:mt-120 {
    margin-top: 120px;
  }
  .md\:mr-120 {
    margin-right: 120px;
  }
  .md\:mb-120 {
    margin-bottom: 120px;
  }
  .md\:ml-120 {
    margin-left: 120px;
  }
  .md\:mt-auto {
    margin-top: auto;
  }
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:mb-auto {
    margin-bottom: auto;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:mt-px {
    margin-top: 1px;
  }
  .md\:mr-px {
    margin-right: 1px;
  }
  .md\:mb-px {
    margin-bottom: 1px;
  }
  .md\:ml-px {
    margin-left: 1px;
  }
  .md\:mt-0\.5 {
    margin-top: 0.5px;
  }
  .md\:mr-0\.5 {
    margin-right: 0.5px;
  }
  .md\:mb-0\.5 {
    margin-bottom: 0.5px;
  }
  .md\:ml-0\.5 {
    margin-left: 0.5px;
  }
  .md\:mt-1\.5 {
    margin-top: 1.5px;
  }
  .md\:mr-1\.5 {
    margin-right: 1.5px;
  }
  .md\:mb-1\.5 {
    margin-bottom: 1.5px;
  }
  .md\:ml-1\.5 {
    margin-left: 1.5px;
  }
  .md\:mt-2\.5 {
    margin-top: 2.5px;
  }
  .md\:mr-2\.5 {
    margin-right: 2.5px;
  }
  .md\:mb-2\.5 {
    margin-bottom: 2.5px;
  }
  .md\:ml-2\.5 {
    margin-left: 2.5px;
  }
  .md\:mt-3\.5 {
    margin-top: 3.5px;
  }
  .md\:mr-3\.5 {
    margin-right: 3.5px;
  }
  .md\:mb-3\.5 {
    margin-bottom: 3.5px;
  }
  .md\:ml-3\.5 {
    margin-left: 3.5px;
  }
  .md\:-mt-0 {
    margin-top: 0px;
  }
  .md\:-mr-0 {
    margin-right: 0px;
  }
  .md\:-mb-0 {
    margin-bottom: 0px;
  }
  .md\:-ml-0 {
    margin-left: 0px;
  }
  .md\:-mt-1 {
    margin-top: -1px;
  }
  .md\:-mr-1 {
    margin-right: -1px;
  }
  .md\:-mb-1 {
    margin-bottom: -1px;
  }
  .md\:-ml-1 {
    margin-left: -1px;
  }
  .md\:-mt-2 {
    margin-top: -2px;
  }
  .md\:-mr-2 {
    margin-right: -2px;
  }
  .md\:-mb-2 {
    margin-bottom: -2px;
  }
  .md\:-ml-2 {
    margin-left: -2px;
  }
  .md\:-mt-3 {
    margin-top: -3px;
  }
  .md\:-mr-3 {
    margin-right: -3px;
  }
  .md\:-mb-3 {
    margin-bottom: -3px;
  }
  .md\:-ml-3 {
    margin-left: -3px;
  }
  .md\:-mt-4 {
    margin-top: -4px;
  }
  .md\:-mr-4 {
    margin-right: -4px;
  }
  .md\:-mb-4 {
    margin-bottom: -4px;
  }
  .md\:-ml-4 {
    margin-left: -4px;
  }
  .md\:-mt-5 {
    margin-top: -5px;
  }
  .md\:-mr-5 {
    margin-right: -5px;
  }
  .md\:-mb-5 {
    margin-bottom: -5px;
  }
  .md\:-ml-5 {
    margin-left: -5px;
  }
  .md\:-mt-10 {
    margin-top: -10px;
  }
  .md\:-mr-10 {
    margin-right: -10px;
  }
  .md\:-mb-10 {
    margin-bottom: -10px;
  }
  .md\:-ml-10 {
    margin-left: -10px;
  }
  .md\:-mt-15 {
    margin-top: -15px;
  }
  .md\:-mr-15 {
    margin-right: -15px;
  }
  .md\:-mb-15 {
    margin-bottom: -15px;
  }
  .md\:-ml-15 {
    margin-left: -15px;
  }
  .md\:-mt-20 {
    margin-top: -20px;
  }
  .md\:-mr-20 {
    margin-right: -20px;
  }
  .md\:-mb-20 {
    margin-bottom: -20px;
  }
  .md\:-ml-20 {
    margin-left: -20px;
  }
  .md\:-mt-25 {
    margin-top: -25px;
  }
  .md\:-mr-25 {
    margin-right: -25px;
  }
  .md\:-mb-25 {
    margin-bottom: -25px;
  }
  .md\:-ml-25 {
    margin-left: -25px;
  }
  .md\:-mt-30 {
    margin-top: -30px;
  }
  .md\:-mr-30 {
    margin-right: -30px;
  }
  .md\:-mb-30 {
    margin-bottom: -30px;
  }
  .md\:-ml-30 {
    margin-left: -30px;
  }
  .md\:-mt-35 {
    margin-top: -35px;
  }
  .md\:-mr-35 {
    margin-right: -35px;
  }
  .md\:-mb-35 {
    margin-bottom: -35px;
  }
  .md\:-ml-35 {
    margin-left: -35px;
  }
  .md\:-mt-40 {
    margin-top: -40px;
  }
  .md\:-mr-40 {
    margin-right: -40px;
  }
  .md\:-mb-40 {
    margin-bottom: -40px;
  }
  .md\:-ml-40 {
    margin-left: -40px;
  }
  .md\:-mt-45 {
    margin-top: -45px;
  }
  .md\:-mr-45 {
    margin-right: -45px;
  }
  .md\:-mb-45 {
    margin-bottom: -45px;
  }
  .md\:-ml-45 {
    margin-left: -45px;
  }
  .md\:-mt-50 {
    margin-top: -50px;
  }
  .md\:-mr-50 {
    margin-right: -50px;
  }
  .md\:-mb-50 {
    margin-bottom: -50px;
  }
  .md\:-ml-50 {
    margin-left: -50px;
  }
  .md\:-mt-55 {
    margin-top: -55px;
  }
  .md\:-mr-55 {
    margin-right: -55px;
  }
  .md\:-mb-55 {
    margin-bottom: -55px;
  }
  .md\:-ml-55 {
    margin-left: -55px;
  }
  .md\:-mt-60 {
    margin-top: -60px;
  }
  .md\:-mr-60 {
    margin-right: -60px;
  }
  .md\:-mb-60 {
    margin-bottom: -60px;
  }
  .md\:-ml-60 {
    margin-left: -60px;
  }
  .md\:-mt-65 {
    margin-top: -65px;
  }
  .md\:-mr-65 {
    margin-right: -65px;
  }
  .md\:-mb-65 {
    margin-bottom: -65px;
  }
  .md\:-ml-65 {
    margin-left: -65px;
  }
  .md\:-mt-70 {
    margin-top: -70px;
  }
  .md\:-mr-70 {
    margin-right: -70px;
  }
  .md\:-mb-70 {
    margin-bottom: -70px;
  }
  .md\:-ml-70 {
    margin-left: -70px;
  }
  .md\:-mt-75 {
    margin-top: -75px;
  }
  .md\:-mr-75 {
    margin-right: -75px;
  }
  .md\:-mb-75 {
    margin-bottom: -75px;
  }
  .md\:-ml-75 {
    margin-left: -75px;
  }
  .md\:-mt-80 {
    margin-top: -80px;
  }
  .md\:-mr-80 {
    margin-right: -80px;
  }
  .md\:-mb-80 {
    margin-bottom: -80px;
  }
  .md\:-ml-80 {
    margin-left: -80px;
  }
  .md\:-mt-85 {
    margin-top: -85px;
  }
  .md\:-mr-85 {
    margin-right: -85px;
  }
  .md\:-mb-85 {
    margin-bottom: -85px;
  }
  .md\:-ml-85 {
    margin-left: -85px;
  }
  .md\:-mt-90 {
    margin-top: -90px;
  }
  .md\:-mr-90 {
    margin-right: -90px;
  }
  .md\:-mb-90 {
    margin-bottom: -90px;
  }
  .md\:-ml-90 {
    margin-left: -90px;
  }
  .md\:-mt-95 {
    margin-top: -95px;
  }
  .md\:-mr-95 {
    margin-right: -95px;
  }
  .md\:-mb-95 {
    margin-bottom: -95px;
  }
  .md\:-ml-95 {
    margin-left: -95px;
  }
  .md\:-mt-100 {
    margin-top: -100px;
  }
  .md\:-mr-100 {
    margin-right: -100px;
  }
  .md\:-mb-100 {
    margin-bottom: -100px;
  }
  .md\:-ml-100 {
    margin-left: -100px;
  }
  .md\:-mt-105 {
    margin-top: -105px;
  }
  .md\:-mr-105 {
    margin-right: -105px;
  }
  .md\:-mb-105 {
    margin-bottom: -105px;
  }
  .md\:-ml-105 {
    margin-left: -105px;
  }
  .md\:-mt-110 {
    margin-top: -110px;
  }
  .md\:-mr-110 {
    margin-right: -110px;
  }
  .md\:-mb-110 {
    margin-bottom: -110px;
  }
  .md\:-ml-110 {
    margin-left: -110px;
  }
  .md\:-mt-115 {
    margin-top: -115px;
  }
  .md\:-mr-115 {
    margin-right: -115px;
  }
  .md\:-mb-115 {
    margin-bottom: -115px;
  }
  .md\:-ml-115 {
    margin-left: -115px;
  }
  .md\:-mt-120 {
    margin-top: -120px;
  }
  .md\:-mr-120 {
    margin-right: -120px;
  }
  .md\:-mb-120 {
    margin-bottom: -120px;
  }
  .md\:-ml-120 {
    margin-left: -120px;
  }
  .md\:-mt-px {
    margin-top: -1px;
  }
  .md\:-mr-px {
    margin-right: -1px;
  }
  .md\:-mb-px {
    margin-bottom: -1px;
  }
  .md\:-ml-px {
    margin-left: -1px;
  }
  .md\:-mt-0\.5 {
    margin-top: -0.5px;
  }
  .md\:-mr-0\.5 {
    margin-right: -0.5px;
  }
  .md\:-mb-0\.5 {
    margin-bottom: -0.5px;
  }
  .md\:-ml-0\.5 {
    margin-left: -0.5px;
  }
  .md\:-mt-1\.5 {
    margin-top: -1.5px;
  }
  .md\:-mr-1\.5 {
    margin-right: -1.5px;
  }
  .md\:-mb-1\.5 {
    margin-bottom: -1.5px;
  }
  .md\:-ml-1\.5 {
    margin-left: -1.5px;
  }
  .md\:-mt-2\.5 {
    margin-top: -2.5px;
  }
  .md\:-mr-2\.5 {
    margin-right: -2.5px;
  }
  .md\:-mb-2\.5 {
    margin-bottom: -2.5px;
  }
  .md\:-ml-2\.5 {
    margin-left: -2.5px;
  }
  .md\:-mt-3\.5 {
    margin-top: -3.5px;
  }
  .md\:-mr-3\.5 {
    margin-right: -3.5px;
  }
  .md\:-mb-3\.5 {
    margin-bottom: -3.5px;
  }
  .md\:-ml-3\.5 {
    margin-left: -3.5px;
  }
}

.p-0 {
  padding: 0px;
}

.p-1 {
  padding: 1px;
}

.p-2 {
  padding: 2px;
}

.p-3 {
  padding: 3px;
}

.p-4 {
  padding: 4px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-55 {
  padding: 55px;
}

.p-60 {
  padding: 60px;
}

.p-65 {
  padding: 65px;
}

.p-70 {
  padding: 70px;
}

.p-75 {
  padding: 75px;
}

.p-80 {
  padding: 80px;
}

.p-85 {
  padding: 85px;
}

.p-90 {
  padding: 90px;
}

.p-95 {
  padding: 95px;
}

.p-100 {
  padding: 100px;
}

.p-105 {
  padding: 105px;
}

.p-110 {
  padding: 110px;
}

.p-115 {
  padding: 115px;
}

.p-120 {
  padding: 120px;
}

.p-px {
  padding: 1px;
}

.p-0\.5 {
  padding: 0.5px;
}

.p-1\.5 {
  padding: 1.5px;
}

.p-2\.5 {
  padding: 2.5px;
}

.p-3\.5 {
  padding: 3.5px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.py-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.px-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.px-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.px-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.px-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.py-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.px-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.py-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.px-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.py-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.px-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.py-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.px-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.px-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.py-105 {
  padding-top: 105px;
  padding-bottom: 105px;
}

.px-105 {
  padding-left: 105px;
  padding-right: 105px;
}

.py-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.px-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.py-115 {
  padding-top: 115px;
  padding-bottom: 115px;
}

.px-115 {
  padding-left: 115px;
  padding-right: 115px;
}

.py-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.px-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0\.5 {
  padding-top: 0.5px;
  padding-bottom: 0.5px;
}

.px-0\.5 {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.py-1\.5 {
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}

.px-1\.5 {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.py-2\.5 {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.px-2\.5 {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.py-3\.5 {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.px-3\.5 {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pt-1 {
  padding-top: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pr-2 {
  padding-right: 2px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pl-2 {
  padding-left: 2px;
}

.pt-3 {
  padding-top: 3px;
}

.pr-3 {
  padding-right: 3px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pl-3 {
  padding-left: 3px;
}

.pt-4 {
  padding-top: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pr-105 {
  padding-right: 105px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pl-105 {
  padding-left: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pr-110 {
  padding-right: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pl-110 {
  padding-left: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pr-115 {
  padding-right: 115px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pl-115 {
  padding-left: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.pt-0\.5 {
  padding-top: 0.5px;
}

.pr-0\.5 {
  padding-right: 0.5px;
}

.pb-0\.5 {
  padding-bottom: 0.5px;
}

.pl-0\.5 {
  padding-left: 0.5px;
}

.pt-1\.5 {
  padding-top: 1.5px;
}

.pr-1\.5 {
  padding-right: 1.5px;
}

.pb-1\.5 {
  padding-bottom: 1.5px;
}

.pl-1\.5 {
  padding-left: 1.5px;
}

.pt-2\.5 {
  padding-top: 2.5px;
}

.pr-2\.5 {
  padding-right: 2.5px;
}

.pb-2\.5 {
  padding-bottom: 2.5px;
}

.pl-2\.5 {
  padding-left: 2.5px;
}

.pt-3\.5 {
  padding-top: 3.5px;
}

.pr-3\.5 {
  padding-right: 3.5px;
}

.pb-3\.5 {
  padding-bottom: 3.5px;
}

.pl-3\.5 {
  padding-left: 3.5px;
}

@media screen and (max-width: 768px) {
  .sm\:p-0 {
    padding: 0px;
  }
  .sm\:p-1 {
    padding: 1px;
  }
  .sm\:p-2 {
    padding: 2px;
  }
  .sm\:p-3 {
    padding: 3px;
  }
  .sm\:p-4 {
    padding: 4px;
  }
  .sm\:p-5 {
    padding: 5px;
  }
  .sm\:p-10 {
    padding: 10px;
  }
  .sm\:p-15 {
    padding: 15px;
  }
  .sm\:p-20 {
    padding: 20px;
  }
  .sm\:p-25 {
    padding: 25px;
  }
  .sm\:p-30 {
    padding: 30px;
  }
  .sm\:p-35 {
    padding: 35px;
  }
  .sm\:p-40 {
    padding: 40px;
  }
  .sm\:p-45 {
    padding: 45px;
  }
  .sm\:p-50 {
    padding: 50px;
  }
  .sm\:p-55 {
    padding: 55px;
  }
  .sm\:p-60 {
    padding: 60px;
  }
  .sm\:p-65 {
    padding: 65px;
  }
  .sm\:p-70 {
    padding: 70px;
  }
  .sm\:p-75 {
    padding: 75px;
  }
  .sm\:p-80 {
    padding: 80px;
  }
  .sm\:p-85 {
    padding: 85px;
  }
  .sm\:p-90 {
    padding: 90px;
  }
  .sm\:p-95 {
    padding: 95px;
  }
  .sm\:p-100 {
    padding: 100px;
  }
  .sm\:p-105 {
    padding: 105px;
  }
  .sm\:p-110 {
    padding: 110px;
  }
  .sm\:p-115 {
    padding: 115px;
  }
  .sm\:p-120 {
    padding: 120px;
  }
  .sm\:p-px {
    padding: 1px;
  }
  .sm\:p-0\.5 {
    padding: 0.5px;
  }
  .sm\:p-1\.5 {
    padding: 1.5px;
  }
  .sm\:p-2\.5 {
    padding: 2.5px;
  }
  .sm\:p-3\.5 {
    padding: 3.5px;
  }
  .sm\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sm\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .sm\:px-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .sm\:py-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .sm\:px-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .sm\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .sm\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .sm\:py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sm\:px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sm\:py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sm\:px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm\:py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sm\:px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm\:py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sm\:px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sm\:py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .sm\:px-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sm\:py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sm\:px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sm\:py-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .sm\:px-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .sm\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .sm\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .sm\:py-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .sm\:px-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .sm\:py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .sm\:px-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .sm\:py-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .sm\:px-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .sm\:py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .sm\:px-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .sm\:py-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .sm\:px-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .sm\:py-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .sm\:px-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .sm\:py-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .sm\:px-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .sm\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .sm\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .sm\:py-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .sm\:px-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .sm\:py-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .sm\:px-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .sm\:py-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .sm\:px-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .sm\:py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .sm\:px-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .sm\:py-105 {
    padding-top: 105px;
    padding-bottom: 105px;
  }
  .sm\:px-105 {
    padding-left: 105px;
    padding-right: 105px;
  }
  .sm\:py-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .sm\:px-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .sm\:py-115 {
    padding-top: 115px;
    padding-bottom: 115px;
  }
  .sm\:px-115 {
    padding-left: 115px;
    padding-right: 115px;
  }
  .sm\:py-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .sm\:px-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:py-0\.5 {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
  }
  .sm\:px-0\.5 {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }
  .sm\:py-1\.5 {
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }
  .sm\:px-1\.5 {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }
  .sm\:py-2\.5 {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .sm\:px-2\.5 {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .sm\:py-3\.5 {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .sm\:px-3\.5 {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .sm\:pt-0 {
    padding-top: 0px;
  }
  .sm\:pr-0 {
    padding-right: 0px;
  }
  .sm\:pb-0 {
    padding-bottom: 0px;
  }
  .sm\:pl-0 {
    padding-left: 0px;
  }
  .sm\:pt-1 {
    padding-top: 1px;
  }
  .sm\:pr-1 {
    padding-right: 1px;
  }
  .sm\:pb-1 {
    padding-bottom: 1px;
  }
  .sm\:pl-1 {
    padding-left: 1px;
  }
  .sm\:pt-2 {
    padding-top: 2px;
  }
  .sm\:pr-2 {
    padding-right: 2px;
  }
  .sm\:pb-2 {
    padding-bottom: 2px;
  }
  .sm\:pl-2 {
    padding-left: 2px;
  }
  .sm\:pt-3 {
    padding-top: 3px;
  }
  .sm\:pr-3 {
    padding-right: 3px;
  }
  .sm\:pb-3 {
    padding-bottom: 3px;
  }
  .sm\:pl-3 {
    padding-left: 3px;
  }
  .sm\:pt-4 {
    padding-top: 4px;
  }
  .sm\:pr-4 {
    padding-right: 4px;
  }
  .sm\:pb-4 {
    padding-bottom: 4px;
  }
  .sm\:pl-4 {
    padding-left: 4px;
  }
  .sm\:pt-5 {
    padding-top: 5px;
  }
  .sm\:pr-5 {
    padding-right: 5px;
  }
  .sm\:pb-5 {
    padding-bottom: 5px;
  }
  .sm\:pl-5 {
    padding-left: 5px;
  }
  .sm\:pt-10 {
    padding-top: 10px;
  }
  .sm\:pr-10 {
    padding-right: 10px;
  }
  .sm\:pb-10 {
    padding-bottom: 10px;
  }
  .sm\:pl-10 {
    padding-left: 10px;
  }
  .sm\:pt-15 {
    padding-top: 15px;
  }
  .sm\:pr-15 {
    padding-right: 15px;
  }
  .sm\:pb-15 {
    padding-bottom: 15px;
  }
  .sm\:pl-15 {
    padding-left: 15px;
  }
  .sm\:pt-20 {
    padding-top: 20px;
  }
  .sm\:pr-20 {
    padding-right: 20px;
  }
  .sm\:pb-20 {
    padding-bottom: 20px;
  }
  .sm\:pl-20 {
    padding-left: 20px;
  }
  .sm\:pt-25 {
    padding-top: 25px;
  }
  .sm\:pr-25 {
    padding-right: 25px;
  }
  .sm\:pb-25 {
    padding-bottom: 25px;
  }
  .sm\:pl-25 {
    padding-left: 25px;
  }
  .sm\:pt-30 {
    padding-top: 30px;
  }
  .sm\:pr-30 {
    padding-right: 30px;
  }
  .sm\:pb-30 {
    padding-bottom: 30px;
  }
  .sm\:pl-30 {
    padding-left: 30px;
  }
  .sm\:pt-35 {
    padding-top: 35px;
  }
  .sm\:pr-35 {
    padding-right: 35px;
  }
  .sm\:pb-35 {
    padding-bottom: 35px;
  }
  .sm\:pl-35 {
    padding-left: 35px;
  }
  .sm\:pt-40 {
    padding-top: 40px;
  }
  .sm\:pr-40 {
    padding-right: 40px;
  }
  .sm\:pb-40 {
    padding-bottom: 40px;
  }
  .sm\:pl-40 {
    padding-left: 40px;
  }
  .sm\:pt-45 {
    padding-top: 45px;
  }
  .sm\:pr-45 {
    padding-right: 45px;
  }
  .sm\:pb-45 {
    padding-bottom: 45px;
  }
  .sm\:pl-45 {
    padding-left: 45px;
  }
  .sm\:pt-50 {
    padding-top: 50px;
  }
  .sm\:pr-50 {
    padding-right: 50px;
  }
  .sm\:pb-50 {
    padding-bottom: 50px;
  }
  .sm\:pl-50 {
    padding-left: 50px;
  }
  .sm\:pt-55 {
    padding-top: 55px;
  }
  .sm\:pr-55 {
    padding-right: 55px;
  }
  .sm\:pb-55 {
    padding-bottom: 55px;
  }
  .sm\:pl-55 {
    padding-left: 55px;
  }
  .sm\:pt-60 {
    padding-top: 60px;
  }
  .sm\:pr-60 {
    padding-right: 60px;
  }
  .sm\:pb-60 {
    padding-bottom: 60px;
  }
  .sm\:pl-60 {
    padding-left: 60px;
  }
  .sm\:pt-65 {
    padding-top: 65px;
  }
  .sm\:pr-65 {
    padding-right: 65px;
  }
  .sm\:pb-65 {
    padding-bottom: 65px;
  }
  .sm\:pl-65 {
    padding-left: 65px;
  }
  .sm\:pt-70 {
    padding-top: 70px;
  }
  .sm\:pr-70 {
    padding-right: 70px;
  }
  .sm\:pb-70 {
    padding-bottom: 70px;
  }
  .sm\:pl-70 {
    padding-left: 70px;
  }
  .sm\:pt-75 {
    padding-top: 75px;
  }
  .sm\:pr-75 {
    padding-right: 75px;
  }
  .sm\:pb-75 {
    padding-bottom: 75px;
  }
  .sm\:pl-75 {
    padding-left: 75px;
  }
  .sm\:pt-80 {
    padding-top: 80px;
  }
  .sm\:pr-80 {
    padding-right: 80px;
  }
  .sm\:pb-80 {
    padding-bottom: 80px;
  }
  .sm\:pl-80 {
    padding-left: 80px;
  }
  .sm\:pt-85 {
    padding-top: 85px;
  }
  .sm\:pr-85 {
    padding-right: 85px;
  }
  .sm\:pb-85 {
    padding-bottom: 85px;
  }
  .sm\:pl-85 {
    padding-left: 85px;
  }
  .sm\:pt-90 {
    padding-top: 90px;
  }
  .sm\:pr-90 {
    padding-right: 90px;
  }
  .sm\:pb-90 {
    padding-bottom: 90px;
  }
  .sm\:pl-90 {
    padding-left: 90px;
  }
  .sm\:pt-95 {
    padding-top: 95px;
  }
  .sm\:pr-95 {
    padding-right: 95px;
  }
  .sm\:pb-95 {
    padding-bottom: 95px;
  }
  .sm\:pl-95 {
    padding-left: 95px;
  }
  .sm\:pt-100 {
    padding-top: 100px;
  }
  .sm\:pr-100 {
    padding-right: 100px;
  }
  .sm\:pb-100 {
    padding-bottom: 100px;
  }
  .sm\:pl-100 {
    padding-left: 100px;
  }
  .sm\:pt-105 {
    padding-top: 105px;
  }
  .sm\:pr-105 {
    padding-right: 105px;
  }
  .sm\:pb-105 {
    padding-bottom: 105px;
  }
  .sm\:pl-105 {
    padding-left: 105px;
  }
  .sm\:pt-110 {
    padding-top: 110px;
  }
  .sm\:pr-110 {
    padding-right: 110px;
  }
  .sm\:pb-110 {
    padding-bottom: 110px;
  }
  .sm\:pl-110 {
    padding-left: 110px;
  }
  .sm\:pt-115 {
    padding-top: 115px;
  }
  .sm\:pr-115 {
    padding-right: 115px;
  }
  .sm\:pb-115 {
    padding-bottom: 115px;
  }
  .sm\:pl-115 {
    padding-left: 115px;
  }
  .sm\:pt-120 {
    padding-top: 120px;
  }
  .sm\:pr-120 {
    padding-right: 120px;
  }
  .sm\:pb-120 {
    padding-bottom: 120px;
  }
  .sm\:pl-120 {
    padding-left: 120px;
  }
  .sm\:pt-px {
    padding-top: 1px;
  }
  .sm\:pr-px {
    padding-right: 1px;
  }
  .sm\:pb-px {
    padding-bottom: 1px;
  }
  .sm\:pl-px {
    padding-left: 1px;
  }
  .sm\:pt-0\.5 {
    padding-top: 0.5px;
  }
  .sm\:pr-0\.5 {
    padding-right: 0.5px;
  }
  .sm\:pb-0\.5 {
    padding-bottom: 0.5px;
  }
  .sm\:pl-0\.5 {
    padding-left: 0.5px;
  }
  .sm\:pt-1\.5 {
    padding-top: 1.5px;
  }
  .sm\:pr-1\.5 {
    padding-right: 1.5px;
  }
  .sm\:pb-1\.5 {
    padding-bottom: 1.5px;
  }
  .sm\:pl-1\.5 {
    padding-left: 1.5px;
  }
  .sm\:pt-2\.5 {
    padding-top: 2.5px;
  }
  .sm\:pr-2\.5 {
    padding-right: 2.5px;
  }
  .sm\:pb-2\.5 {
    padding-bottom: 2.5px;
  }
  .sm\:pl-2\.5 {
    padding-left: 2.5px;
  }
  .sm\:pt-3\.5 {
    padding-top: 3.5px;
  }
  .sm\:pr-3\.5 {
    padding-right: 3.5px;
  }
  .sm\:pb-3\.5 {
    padding-bottom: 3.5px;
  }
  .sm\:pl-3\.5 {
    padding-left: 3.5px;
  }
}

@media screen and (max-width: 992px) {
  .md\:p-0 {
    padding: 0px;
  }
  .md\:p-1 {
    padding: 1px;
  }
  .md\:p-2 {
    padding: 2px;
  }
  .md\:p-3 {
    padding: 3px;
  }
  .md\:p-4 {
    padding: 4px;
  }
  .md\:p-5 {
    padding: 5px;
  }
  .md\:p-10 {
    padding: 10px;
  }
  .md\:p-15 {
    padding: 15px;
  }
  .md\:p-20 {
    padding: 20px;
  }
  .md\:p-25 {
    padding: 25px;
  }
  .md\:p-30 {
    padding: 30px;
  }
  .md\:p-35 {
    padding: 35px;
  }
  .md\:p-40 {
    padding: 40px;
  }
  .md\:p-45 {
    padding: 45px;
  }
  .md\:p-50 {
    padding: 50px;
  }
  .md\:p-55 {
    padding: 55px;
  }
  .md\:p-60 {
    padding: 60px;
  }
  .md\:p-65 {
    padding: 65px;
  }
  .md\:p-70 {
    padding: 70px;
  }
  .md\:p-75 {
    padding: 75px;
  }
  .md\:p-80 {
    padding: 80px;
  }
  .md\:p-85 {
    padding: 85px;
  }
  .md\:p-90 {
    padding: 90px;
  }
  .md\:p-95 {
    padding: 95px;
  }
  .md\:p-100 {
    padding: 100px;
  }
  .md\:p-105 {
    padding: 105px;
  }
  .md\:p-110 {
    padding: 110px;
  }
  .md\:p-115 {
    padding: 115px;
  }
  .md\:p-120 {
    padding: 120px;
  }
  .md\:p-px {
    padding: 1px;
  }
  .md\:p-0\.5 {
    padding: 0.5px;
  }
  .md\:p-1\.5 {
    padding: 1.5px;
  }
  .md\:p-2\.5 {
    padding: 2.5px;
  }
  .md\:p-3\.5 {
    padding: 3.5px;
  }
  .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .md\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:py-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .md\:px-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .md\:py-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .md\:px-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .md\:py-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .md\:px-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .md\:py-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .md\:px-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .md\:py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .md\:px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .md\:py-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .md\:px-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .md\:py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .md\:px-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .md\:py-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .md\:px-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .md\:py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .md\:px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .md\:py-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .md\:px-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .md\:py-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .md\:px-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .md\:py-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .md\:px-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .md\:py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .md\:px-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .md\:py-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .md\:px-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .md\:py-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .md\:px-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .md\:py-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .md\:px-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .md\:py-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .md\:px-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .md\:py-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .md\:px-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .md\:py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .md\:px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .md\:py-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .md\:px-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .md\:py-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .md\:px-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .md\:py-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .md\:px-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .md\:py-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .md\:px-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .md\:py-105 {
    padding-top: 105px;
    padding-bottom: 105px;
  }
  .md\:px-105 {
    padding-left: 105px;
    padding-right: 105px;
  }
  .md\:py-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .md\:px-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .md\:py-115 {
    padding-top: 115px;
    padding-bottom: 115px;
  }
  .md\:px-115 {
    padding-left: 115px;
    padding-right: 115px;
  }
  .md\:py-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .md\:px-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:py-0\.5 {
    padding-top: 0.5px;
    padding-bottom: 0.5px;
  }
  .md\:px-0\.5 {
    padding-left: 0.5px;
    padding-right: 0.5px;
  }
  .md\:py-1\.5 {
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }
  .md\:px-1\.5 {
    padding-left: 1.5px;
    padding-right: 1.5px;
  }
  .md\:py-2\.5 {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }
  .md\:px-2\.5 {
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
  .md\:py-3\.5 {
    padding-top: 3.5px;
    padding-bottom: 3.5px;
  }
  .md\:px-3\.5 {
    padding-left: 3.5px;
    padding-right: 3.5px;
  }
  .md\:pt-0 {
    padding-top: 0px;
  }
  .md\:pr-0 {
    padding-right: 0px;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:pl-0 {
    padding-left: 0px;
  }
  .md\:pt-1 {
    padding-top: 1px;
  }
  .md\:pr-1 {
    padding-right: 1px;
  }
  .md\:pb-1 {
    padding-bottom: 1px;
  }
  .md\:pl-1 {
    padding-left: 1px;
  }
  .md\:pt-2 {
    padding-top: 2px;
  }
  .md\:pr-2 {
    padding-right: 2px;
  }
  .md\:pb-2 {
    padding-bottom: 2px;
  }
  .md\:pl-2 {
    padding-left: 2px;
  }
  .md\:pt-3 {
    padding-top: 3px;
  }
  .md\:pr-3 {
    padding-right: 3px;
  }
  .md\:pb-3 {
    padding-bottom: 3px;
  }
  .md\:pl-3 {
    padding-left: 3px;
  }
  .md\:pt-4 {
    padding-top: 4px;
  }
  .md\:pr-4 {
    padding-right: 4px;
  }
  .md\:pb-4 {
    padding-bottom: 4px;
  }
  .md\:pl-4 {
    padding-left: 4px;
  }
  .md\:pt-5 {
    padding-top: 5px;
  }
  .md\:pr-5 {
    padding-right: 5px;
  }
  .md\:pb-5 {
    padding-bottom: 5px;
  }
  .md\:pl-5 {
    padding-left: 5px;
  }
  .md\:pt-10 {
    padding-top: 10px;
  }
  .md\:pr-10 {
    padding-right: 10px;
  }
  .md\:pb-10 {
    padding-bottom: 10px;
  }
  .md\:pl-10 {
    padding-left: 10px;
  }
  .md\:pt-15 {
    padding-top: 15px;
  }
  .md\:pr-15 {
    padding-right: 15px;
  }
  .md\:pb-15 {
    padding-bottom: 15px;
  }
  .md\:pl-15 {
    padding-left: 15px;
  }
  .md\:pt-20 {
    padding-top: 20px;
  }
  .md\:pr-20 {
    padding-right: 20px;
  }
  .md\:pb-20 {
    padding-bottom: 20px;
  }
  .md\:pl-20 {
    padding-left: 20px;
  }
  .md\:pt-25 {
    padding-top: 25px;
  }
  .md\:pr-25 {
    padding-right: 25px;
  }
  .md\:pb-25 {
    padding-bottom: 25px;
  }
  .md\:pl-25 {
    padding-left: 25px;
  }
  .md\:pt-30 {
    padding-top: 30px;
  }
  .md\:pr-30 {
    padding-right: 30px;
  }
  .md\:pb-30 {
    padding-bottom: 30px;
  }
  .md\:pl-30 {
    padding-left: 30px;
  }
  .md\:pt-35 {
    padding-top: 35px;
  }
  .md\:pr-35 {
    padding-right: 35px;
  }
  .md\:pb-35 {
    padding-bottom: 35px;
  }
  .md\:pl-35 {
    padding-left: 35px;
  }
  .md\:pt-40 {
    padding-top: 40px;
  }
  .md\:pr-40 {
    padding-right: 40px;
  }
  .md\:pb-40 {
    padding-bottom: 40px;
  }
  .md\:pl-40 {
    padding-left: 40px;
  }
  .md\:pt-45 {
    padding-top: 45px;
  }
  .md\:pr-45 {
    padding-right: 45px;
  }
  .md\:pb-45 {
    padding-bottom: 45px;
  }
  .md\:pl-45 {
    padding-left: 45px;
  }
  .md\:pt-50 {
    padding-top: 50px;
  }
  .md\:pr-50 {
    padding-right: 50px;
  }
  .md\:pb-50 {
    padding-bottom: 50px;
  }
  .md\:pl-50 {
    padding-left: 50px;
  }
  .md\:pt-55 {
    padding-top: 55px;
  }
  .md\:pr-55 {
    padding-right: 55px;
  }
  .md\:pb-55 {
    padding-bottom: 55px;
  }
  .md\:pl-55 {
    padding-left: 55px;
  }
  .md\:pt-60 {
    padding-top: 60px;
  }
  .md\:pr-60 {
    padding-right: 60px;
  }
  .md\:pb-60 {
    padding-bottom: 60px;
  }
  .md\:pl-60 {
    padding-left: 60px;
  }
  .md\:pt-65 {
    padding-top: 65px;
  }
  .md\:pr-65 {
    padding-right: 65px;
  }
  .md\:pb-65 {
    padding-bottom: 65px;
  }
  .md\:pl-65 {
    padding-left: 65px;
  }
  .md\:pt-70 {
    padding-top: 70px;
  }
  .md\:pr-70 {
    padding-right: 70px;
  }
  .md\:pb-70 {
    padding-bottom: 70px;
  }
  .md\:pl-70 {
    padding-left: 70px;
  }
  .md\:pt-75 {
    padding-top: 75px;
  }
  .md\:pr-75 {
    padding-right: 75px;
  }
  .md\:pb-75 {
    padding-bottom: 75px;
  }
  .md\:pl-75 {
    padding-left: 75px;
  }
  .md\:pt-80 {
    padding-top: 80px;
  }
  .md\:pr-80 {
    padding-right: 80px;
  }
  .md\:pb-80 {
    padding-bottom: 80px;
  }
  .md\:pl-80 {
    padding-left: 80px;
  }
  .md\:pt-85 {
    padding-top: 85px;
  }
  .md\:pr-85 {
    padding-right: 85px;
  }
  .md\:pb-85 {
    padding-bottom: 85px;
  }
  .md\:pl-85 {
    padding-left: 85px;
  }
  .md\:pt-90 {
    padding-top: 90px;
  }
  .md\:pr-90 {
    padding-right: 90px;
  }
  .md\:pb-90 {
    padding-bottom: 90px;
  }
  .md\:pl-90 {
    padding-left: 90px;
  }
  .md\:pt-95 {
    padding-top: 95px;
  }
  .md\:pr-95 {
    padding-right: 95px;
  }
  .md\:pb-95 {
    padding-bottom: 95px;
  }
  .md\:pl-95 {
    padding-left: 95px;
  }
  .md\:pt-100 {
    padding-top: 100px;
  }
  .md\:pr-100 {
    padding-right: 100px;
  }
  .md\:pb-100 {
    padding-bottom: 100px;
  }
  .md\:pl-100 {
    padding-left: 100px;
  }
  .md\:pt-105 {
    padding-top: 105px;
  }
  .md\:pr-105 {
    padding-right: 105px;
  }
  .md\:pb-105 {
    padding-bottom: 105px;
  }
  .md\:pl-105 {
    padding-left: 105px;
  }
  .md\:pt-110 {
    padding-top: 110px;
  }
  .md\:pr-110 {
    padding-right: 110px;
  }
  .md\:pb-110 {
    padding-bottom: 110px;
  }
  .md\:pl-110 {
    padding-left: 110px;
  }
  .md\:pt-115 {
    padding-top: 115px;
  }
  .md\:pr-115 {
    padding-right: 115px;
  }
  .md\:pb-115 {
    padding-bottom: 115px;
  }
  .md\:pl-115 {
    padding-left: 115px;
  }
  .md\:pt-120 {
    padding-top: 120px;
  }
  .md\:pr-120 {
    padding-right: 120px;
  }
  .md\:pb-120 {
    padding-bottom: 120px;
  }
  .md\:pl-120 {
    padding-left: 120px;
  }
  .md\:pt-px {
    padding-top: 1px;
  }
  .md\:pr-px {
    padding-right: 1px;
  }
  .md\:pb-px {
    padding-bottom: 1px;
  }
  .md\:pl-px {
    padding-left: 1px;
  }
  .md\:pt-0\.5 {
    padding-top: 0.5px;
  }
  .md\:pr-0\.5 {
    padding-right: 0.5px;
  }
  .md\:pb-0\.5 {
    padding-bottom: 0.5px;
  }
  .md\:pl-0\.5 {
    padding-left: 0.5px;
  }
  .md\:pt-1\.5 {
    padding-top: 1.5px;
  }
  .md\:pr-1\.5 {
    padding-right: 1.5px;
  }
  .md\:pb-1\.5 {
    padding-bottom: 1.5px;
  }
  .md\:pl-1\.5 {
    padding-left: 1.5px;
  }
  .md\:pt-2\.5 {
    padding-top: 2.5px;
  }
  .md\:pr-2\.5 {
    padding-right: 2.5px;
  }
  .md\:pb-2\.5 {
    padding-bottom: 2.5px;
  }
  .md\:pl-2\.5 {
    padding-left: 2.5px;
  }
  .md\:pt-3\.5 {
    padding-top: 3.5px;
  }
  .md\:pr-3\.5 {
    padding-right: 3.5px;
  }
  .md\:pb-3\.5 {
    padding-bottom: 3.5px;
  }
  .md\:pl-3\.5 {
    padding-left: 3.5px;
  }
}

/* ===================
2/ base
=======================*/
/*--------------------------------------------------------------
## Include Fonts
--------------------------------------------------------------*/
@font-face {
  font-family: "circular";
  src: url("../fonts/CircularStd-Regular.woff") format("woff"), url("../fonts/CircularStd-Regular.woff2") format("woff2"), url("../fonts/CircularStd-Regular.svg") format("svg"), url("../fonts/circular.ttf") format("truetype");
}

@font-face {
  font-family: "circular_bold";
  src: url("../fonts/CircularStd-Bold.svg") format("svg"), url("../fonts/CircularStd-Bold.woff") format("woff"), url("../fonts/CircularStd-Bold.woff2") format("woff2"), url("../fonts/Circular-Bold.ttf") format("truetype");
  font-weight: normal;
}

/*--------------------------------------------------------------
## Base
--------------------------------------------------------------*/
html {
  scroll-behavior: smooth;
}

body {
  font-family: "circular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 17px;
  letter-spacing: 0.3px;
  color: #183b56;
  background: #ecf1f9;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 15.5px;
  }
}

body.is__dark {
  background: #0b1b27;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1400px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 800px !important;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

@media only screen and (min-width: 1510px) {
  .index_page .container {
    max-width: 1400px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "circular_bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

ul {
  margin: 0;
  padding: 0;
}

section:focus {
  outline: none !important;
}

a {
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  font-family: "circular";
  color: #6f7583;
}

/*--------------------------------------------------------------
## swiper reset
--------------------------------------------------------------*/
.swiper-pagination-bullet {
  margin-top: 20px;
  width: 12px !important;
  height: 12px !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

.swiper-pagination-bullet-active {
  background: #566ffe !important;
}

/*--------------------------------------------------------------
## prismjs reset
--------------------------------------------------------------*/
.code-toolbar {
  position: relative;
}

.code-toolbar .toolbar {
  position: absolute;
  top: 20px;
  right: 20px;
}

.code-toolbar button {
  background: #566ffe;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  border: none;
  box-shadow: none;
}

.sm_dropdown.show {
  height: 180px !important;
}

.single-drop.show {
  height: 300px !important;
}

.single-drop.show .drop_dark {
  display: block;
  padding: 20px 15px !important;
}

.single-drop.show .pl-10 {
  padding-left: 0 !important;
}

.effect {
  background-image: url(../img/bg/effect.svg);
  background-size: 100%;
  background-repeat: no-repeat;
}

.heading {
  margin-bottom: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-family: "circular_bold", -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: bold;
  text-align: inherit;
  position: relative;
  color: #183b56;
  letter-spacing: -0.01em;
}

a {
  color: #566ffe;
}

a:hover {
  color: #566ffe;
}

h1 {
  font-size: calc( 40px + 15 * ((100vw - 576px) / 1024));
  line-height: 1.2;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 40px;
  }
}

@media screen and (min-width: 1600px) {
  h1 {
    font-size: 55px;
  }
}

@media screen and (max-width: 768px) {
  h1 {
    line-height: 1.15 !important;
  }
}

h2 {
  font-size: calc( 32px + 2 * ((100vw - 576px) / 1024));
  line-height: 1.15;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 576px) {
  h2 {
    font-size: 32px;
  }
}

@media screen and (min-width: 1600px) {
  h2 {
    font-size: 34px;
  }
}

h3 {
  font-size: calc( 24px + 2 * ((100vw - 576px) / 1024));
  line-height: 1.1;
}

@media screen and (max-width: 576px) {
  h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 1600px) {
  h3 {
    font-size: 26px;
  }
}

h4 {
  font-size: calc( 19px + 1 * ((100vw - 576px) / 1024));
  line-height: 1.35;
}

@media screen and (max-width: 576px) {
  h4 {
    font-size: 19px;
  }
}

@media screen and (min-width: 1600px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: calc( 16px + 2 * ((100vw - 576px) / 1024));
  line-height: 1.5;
}

@media screen and (max-width: 576px) {
  h5 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1600px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: calc( 15.5px + 0.5 * ((100vw - 576px) / 1024));
  line-height: 1.5;
  letter-spacing: 0;
}

@media screen and (max-width: 576px) {
  h6 {
    font-size: 15.5px;
  }
}

@media screen and (min-width: 1600px) {
  h6 {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
## Typography
--------------------------------------------------------------*/
p {
  width: 100%;
  margin: 0 auto;
  display: block;
  color: #6f7d95;
  line-height: 1.6;
}

/* ===================
3/ elements
=======================*/
/*--------------------------------------------------------------
## btn styles
--------------------------------------------------------------*/
input[type="submit"],
.btn,
a.btn input.btn,
a.button,
span.button,
div.button {
  color: white;
  padding: 11px 23px;
  min-height: 20px;
  line-height: 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
  outline: none;
  position: relative;
  text-align: center;
  border-radius: 100px !important;
  overflow: hidden;
  will-change: transform, box-shadow;
}

input[type="submit"]:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
.btn:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
a.btn input.btn:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
a.button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
span.button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text,
div.button:not(.btn-outline):not(.btn-flat):not(.btn-link) .text {
  color: #fff;
}

input[type="submit"] i,
.btn i,
a.btn input.btn i,
a.button i,
span.button i,
div.button i {
  vertical-align: middle;
  margin-top: -16px !important;
  position: relative;
  top: -1px;
  font-size: 20px;
  position: relative;
  stroke: 2px;
  min-width: 20px;
  height: 20px;
}

input[type="submit"] i-left,
.btn i-left,
a.btn input.btn i-left,
a.button i-left,
span.button i-left,
div.button i-left {
  margin-right: 5px;
}

input[type="submit"] i-right,
.btn i-right,
a.btn input.btn i-right,
a.button i-right,
span.button i-right,
div.button i-right {
  margin-left: 5px;
}

input[type="submit"] i.sm,
.btn i.sm,
a.btn input.btn i.sm,
a.button i.sm,
span.button i.sm,
div.button i.sm {
  font-size: 12px;
}

input[type="submit"] i.md,
.btn i.md,
a.btn input.btn i.md,
a.button i.md,
span.button i.md,
div.button i.md {
  font-size: 16px;
}

input[type="submit"] i.lg,
.btn i.lg,
a.btn input.btn i.lg,
a.button i.lg,
span.button i.lg,
div.button i.lg {
  font-size: 18px;
}

input[type="submit"]:not(.text-hover):hover .icon.icon-right:before,
input[type="submit"]:not(.text-hover):hover .icon.icon-right svg,
.btn:not(.text-hover):hover .icon.icon-right:before,
.btn:not(.text-hover):hover .icon.icon-right svg,
a.btn input.btn:not(.text-hover):hover .icon.icon-right:before,
a.btn input.btn:not(.text-hover):hover .icon.icon-right svg,
a.button:not(.text-hover):hover .icon.icon-right:before,
a.button:not(.text-hover):hover .icon.icon-right svg,
span.button:not(.text-hover):hover .icon.icon-right:before,
span.button:not(.text-hover):hover .icon.icon-right svg,
div.button:not(.text-hover):hover .icon.icon-right:before,
div.button:not(.text-hover):hover .icon.icon-right svg {
  animation: hoverBtn ease-out 0.3s;
}

input[type="submit"]:not(.text-hover):hover .icon.icon-left:before,
input[type="submit"]:not(.text-hover):hover .icon.icon-left svg,
.btn:not(.text-hover):hover .icon.icon-left:before,
.btn:not(.text-hover):hover .icon.icon-left svg,
a.btn input.btn:not(.text-hover):hover .icon.icon-left:before,
a.btn input.btn:not(.text-hover):hover .icon.icon-left svg,
a.button:not(.text-hover):hover .icon.icon-left:before,
a.button:not(.text-hover):hover .icon.icon-left svg,
span.button:not(.text-hover):hover .icon.icon-left:before,
span.button:not(.text-hover):hover .icon.icon-left svg,
div.button:not(.text-hover):hover .icon.icon-left:before,
div.button:not(.text-hover):hover .icon.icon-left svg {
  animation: hoverBtn ease-out 0.3s reverse;
}

input[type="submit"]:not(.text-hover):hover .icon:before,
.btn:not(.text-hover):hover .icon:before,
a.btn input.btn:not(.text-hover):hover .icon:before,
a.button:not(.text-hover):hover .icon:before,
span.button:not(.text-hover):hover .icon:before,
div.button:not(.text-hover):hover .icon:before {
  color: inherit;
}

@keyframes hoverBtn {
  0% {
    transform: translateX(0px);
  }
  51% {
    transform: translateX(20px);
    opacity: 1;
  }
  52% {
    opacity: 0;
    transform: translateX(-20px);
  }
  53% {
    opacity: 0;
  }
  54% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

input[type="submit"]:hover, input[type="submit"]:focus,
.btn:hover,
.btn:focus,
a.btn input.btn:hover,
a.btn input.btn:focus,
a.button:hover,
a.button:focus,
span.button:hover,
span.button:focus,
div.button:hover,
div.button:focus {
  color: #fff;
  background-color: #566ffe;
  border-color: #566ffe;
  opacity: 0.9;
  outline: none;
  box-shadow: inherit;
  opacity: 0.9;
}

input[type="submit"].text-hover,
.btn.text-hover,
a.btn input.btn.text-hover,
a.button.text-hover,
span.button.text-hover,
div.button.text-hover {
  overflow: hidden;
  position: relative;
}

input[type="submit"].text-hover .icon,
input[type="submit"].text-hover .text,
.btn.text-hover .icon,
.btn.text-hover .text,
a.btn input.btn.text-hover .icon,
a.btn input.btn.text-hover .text,
a.button.text-hover .icon,
a.button.text-hover .text,
span.button.text-hover .icon,
span.button.text-hover .text,
div.button.text-hover .icon,
div.button.text-hover .text {
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

input[type="submit"].text-hover .icon,
.btn.text-hover .icon,
a.btn input.btn.text-hover .icon,
a.button.text-hover .icon,
span.button.text-hover .icon,
div.button.text-hover .icon {
  max-width: 100%;
  text-align: center;
  height: 26px;
  position: absolute;
  top: calc(50% - 10px);
  font-size: 20px;
  left: 50%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
}

input[type="submit"].text-hover .text,
.btn.text-hover .text,
a.btn input.btn.text-hover .text,
a.button.text-hover .text,
span.button.text-hover .text,
div.button.text-hover .text {
  display: inline-block;
  transform: translateY(-120%);
  opacity: 0;
}

input[type="submit"].text-hover:hover .icon,
.btn.text-hover:hover .icon,
a.btn input.btn.text-hover:hover .icon,
a.button.text-hover:hover .icon,
span.button.text-hover:hover .icon,
div.button.text-hover:hover .icon {
  transform: translate(-50%, 120%);
  opacity: 0;
}

input[type="submit"].text-hover:hover .text,
.btn.text-hover:hover .text,
a.btn input.btn.text-hover:hover .text,
a.button.text-hover:hover .text,
span.button.text-hover:hover .text,
div.button.text-hover:hover .text {
  transform: none;
  opacity: 1;
}

p a.btn:hover {
  text-decoration: none;
}

button[disabled].btn-white:hover,
input[type="submit"][disabled].btn-white:hover {
  background-color: #fff;
  color: #183b56;
}

button[disabled].btn-primary:hover,
input[type="submit"][disabled].btn-primary:hover {
  background-color: #566ffe;
  color: #fff;
}

/*--------------------------------------------------------------
### Buttons: outline
--------------------------------------------------------------*/
.btn-outline,
input[type="submit"].btn-outline,
a.btn-outline {
  background-color: transparent;
  color: currentColor;
}

.btn-outline:after, .btn-outline:before,
input[type="submit"].btn-outline:after,
input[type="submit"].btn-outline:before,
a.btn-outline:after,
a.btn-outline:before {
  display: none;
}

.btn-outline:hover, .btn-outline:focus,
input[type="submit"].btn-outline:hover,
input[type="submit"].btn-outline:focus,
a.btn-outline:hover,
a.btn-outline:focus {
  background-color: #566ffe;
  color: #fff;
}

.btn-outline.btn-white,
input[type="submit"].btn-outline.btn-white,
a.btn-outline.btn-white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-outline.btn-white:hover,
input[type="submit"].btn-outline.btn-white:hover,
a.btn-outline.btn-white:hover {
  background-color: #fff;
  color: #183b56;
}

.btn-outline.disabled:hover,
input[type="submit"].btn-outline.disabled:hover,
a.btn-outline.disabled:hover {
  background-color: transparent;
  color: #183b56;
  border-color: #183b56;
  cursor: default;
}

/*--------------------------------------------------------------
### Buttons: loading spinner
--------------------------------------------------------------*/
.btn-loading {
  box-shadow: none;
}

.btn-loading:before {
  content: "";
  position: relative;
  display: inline-block !important;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  bottom: auto;
  left: auto;
  opacity: 1;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  background: transparent;
  animation: 0.8s rotate cubic-bezier(0.99, 0.71, 0.22, 0.68) infinite;
}

.btn-loading.btn-outline:before, .btn-loading.btn:before {
  margin-right: 8px;
}

.btn-loading:before {
  vertical-align: top;
  margin-top: 1px;
}

.btn-loading .ion-left {
  display: none;
}

.btn-loading:hover {
  transition: none;
}

.btn-loading:hover:before {
  background: transparent;
  width: 18px;
  transition: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* =====================================================
- - - - - - - - - 1/ Button sizes
======================================================*/
.btn-sm {
  padding: 6px 16px;
  border-radius: 28px;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  width: fit-content;
}

.btn-sm.dropdown-toggle::after {
  top: 2px;
  position: relative;
}

.btn-lg {
  padding: 7px 25px;
  font-size: 19px;
  line-height: 1.7;
}

.btn_card {
  padding: 8px 16px;
}

/* ==================================================+===
- - - - - - - - - 2/ Button variations
=======================================================*/
.btn-primary {
  color: #ffffff;
  border: none !important;
  transition: all 0.4s ease-in-out !important;
  background: #566ffe !important;
  position: relative;
  position: relative;
}

.btn-primary::before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-radius: 100%;
  background: #183b56;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0.3;
}

.btn-primary:hover {
  color: #ffffff;
  position: relative;
}

.btn-primary:hover::before {
  content: "";
  position: absolute;
  height: 15rem;
  width: 15rem;
}

.btn-grad {
  color: #ffffff;
  border: none !important;
  transition: all 0.4s ease-in-out !important;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
}

.btn-grad:hover {
  outline: none;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border: solid 2px #566ffe;
}

.btn-green {
  color: #ffffff;
  border: none !important;
  transition: all 0.4s ease-in-out !important;
  background: #36b37e;
}

.btn-green:hover {
  background: #36b37e !important;
  opacity: 0.8;
}

.btn-orange {
  background: #e95432;
  color: white;
  border: solid 2px #566ffe;
  transition: 0.4s ease-in-out;
  border: none;
}

.btn-orange:hover {
  color: #fff;
  background-color: #e95432;
  border-color: #e95432;
  opacity: 0.9;
}

.btn-white {
  background: white;
  border: solid 1px rgba(10, 33, 65, 0.05);
  color: #183b56;
  box-shadow: 0px 2px 4px #7e8eb11f !important;
}

.btn-white:focus, .btn-white:active {
  opacity: 0.9;
  background: white !important;
  border: solid 1px rgba(10, 33, 65, 0.05);
  color: #183b56;
  box-shadow: 0px 2px 4px #7e8eb11f !important;
}

.btn-white.dropdown-toggle:focus, .btn-white.dropdown-toggle:active {
  opacity: 0.8;
  background: white !important;
  color: #566ffe !important;
  box-shadow: 0 9px 6px rgba(0, 0, 0, 0.1), 0 -50px 0 rgba(0, 0, 0, 0) inset;
  border: solid 1px rgba(10, 33, 65, 0.05) !important;
}

.btn-white span::after {
  color: #183b56 !important;
}

.btn-white.btn-outline {
  color: white;
  border: solid 2px white;
}

.btn-white:hover {
  opacity: 0.8;
  background: white !important;
  color: #566ffe !important;
  box-shadow: 0 9px 6px rgba(0, 0, 0, 0.1), 0 -50px 0 rgba(0, 0, 0, 0) inset;
  border: solid 1px rgba(10, 33, 65, 0.05) !important;
}

.btn-light {
  background: #edf3f5;
  border: solid 1px transparent;
  color: #183b56;
  box-shadow: 0px 2px 4px #7e8eb11f !important;
}

.btn-light:focus, .btn-light:active {
  opacity: 0.9;
  background: #edf3f5 !important;
  border: solid 1px transparent;
  color: #183b56;
  box-shadow: 0px 2px 4px #7e8eb11f !important;
}

.btn-light.dropdown-toggle:focus, .btn-light.dropdown-toggle:active {
  opacity: 0.8;
  background: #edf3f5;
  color: #566ffe !important;
  box-shadow: 0 9px 6px rgba(0, 0, 0, 0.1), 0 -50px 0 rgba(0, 0, 0, 0) inset;
  border: solid 1px transparent;
}

.btn-light span::after {
  color: #183b56 !important;
}

.btn-light.btn-outline {
  color: white;
  border: solid 2px white;
}

.btn-light:hover {
  opacity: 0.8;
  background: #edf3f5;
  color: #566ffe !important;
  box-shadow: 0 9px 6px rgba(0, 0, 0, 0.1), 0 -50px 0 rgba(0, 0, 0, 0) inset;
  border: solid 1px transparent !important;
}

.btn-dark {
  background: #183b56;
  color: #ffffff;
  border: solid 1px #183b56;
}

.btn-dark:hover, .btn-dark:focus {
  color: #ffffff !important;
  background: #183b56 !important;
  border: solid 1px #183b56;
}

.btn-dark.btn-sh {
  box-shadow: 0 14px 35px -11px #183b56;
}

.btn-dark.btn-outline {
  border: solid 2px #183b56;
  background: transparent !important;
  color: #183b56;
}

.btn-dark.btn-outline:hover {
  color: #566ffe !important;
}

.btn-dark.btn-outline-bottom {
  border-bottom: solid 3px #183b56;
  background: transparent !important;
  color: #183b56;
}

/* ==================================================+===
- - - - - - - - - 3 / outher button
=======================================================*/
.facebook-btn {
  background-color: #4267b2;
}

.facebook-btn:hover {
  background-color: #4267b2;
}

.twitter-btn {
  background-color: #1da1f2;
}

.twitter-btn:hover {
  background-color: #1da1f2;
}

.discord-btn {
  background-color: #5865f2;
}

.discord-btn:hover {
  background-color: #5865f2;
}

.telegram-btn {
  background-color: #0088cc;
}

.telegram-btn:hover {
  background-color: #0088cc;
}

.tiktok-btn {
  background-color: #f01d54;
}

.tiktok-btn:hover {
  background-color: #f01d54;
}

.instagram-btn {
  background: radial-gradient(circle farthest-corner at 0% 150%, #ffe17d 0%, #ffcd69 12%, #fa9137 25%, #eb4141 41%, transparent 95%), linear-gradient(-15deg, #234bd7 -10%, #c33cbe 65%);
}

.instagram-btn:hover {
  background: radial-gradient(circle farthest-corner at 0% 150%, #ffe17d 0%, #ffcd69 12%, #fa9137 25%, #eb4141 41%, transparent 95%), linear-gradient(-15deg, #234bd7 -10%, #c33cbe 65%);
}

.youtube-btn {
  background-color: #ff0000;
}

.youtube-btn:hover {
  background-color: #ff0000;
}

.medium-btn {
  background-color: #66cdaa;
}

.medium-btn:hover {
  background-color: #66cdaa;
}

button.close {
  height: 2rem;
  width: 2rem;
  border-radius: 50px;
  position: relative;
}

button.close::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  height: 20px;
  width: 40px;
  background-size: contain;
  opacity: 0.5;
  background-repeat: no-repeat;
  z-index: 2;
}

button.close:hover {
  color: white;
  outline: none;
  box-shadow: none;
  border: none;
}

/*=== Small Button ====
==================*/
.btn_sm_primary {
  padding: 0.7rem 1.55rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_sm_primary:hover {
  opacity: 0.9;
}

/*=== Medium Button ====
==================*/
.btn_md_primary {
  padding: 0.85rem 1.8rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_md_primary:hover {
  opacity: 0.9;
}

/*=== Large Button ====
==================*/
.btn_lg_primary {
  padding: 0.9rem 2.3rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_lg_primary:hover {
  opacity: 0.9;
}

/*=== XLarge Button ====
==================*/
.btn_xl_primary {
  padding: 1.1rem 2.3rem;
  font-size: 15px;
  text-align: center;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.btn_xl_primary:hover {
  opacity: 0.9;
}

/*=== UI kit ====
==================*/
.buttons span {
  display: block;
}

.btn_auction {
  padding: 10px 16px;
}

.buttons_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  flex: 2;
}

@media screen and (max-width: 991px) {
  .btn_create {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .others_btn {
    width: 47.5%;
  }
}

/*--------------------------------------------------------------
## form styles
--------------------------------------------------------------*/
.select-holder {
  position: relative;
}

.select-holder:after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  z-index: 10;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

select {
  cursor: pointer;
  display: inline-block;
  padding: 15px !important;
  color: #6c757d !important;
}

option {
  color: #183b56;
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 400;
}

option.value {
  background-color: #e2eef1 !important;
}

textarea {
  padding: 15px !important;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
select,
textarea,
.custom-select {
  background: 0 0;
  color: #232226;
  background-color: #f2f7fa !important;
  border: 1px solid transparent !important;
  width: 100%;
  padding: 15px;
  line-height: 1.5;
  font-weight: 400;
  font-size: inherit;
  border-radius: 12px;
  position: relative;
  -webkit-appearance: none;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  outline: 0;
}

@media screen and (max-width: 576px) {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  select,
  textarea,
  .custom-select {
    padding: 12px;
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  select,
  textarea,
  .custom-select {
    font-size: 13px;
  }
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus,
.custom-select:focus {
  background-color: #ffffff !important;
  border: 1px solid #e2eef1 !important;
  box-shadow: none !important;
}

input[type="text"]:hover,
input[type="password"]:hover,
input[type="email"]:hover,
input[type="tel"]:hover,
input[type="number"]:hover,
select:hover,
textarea:hover,
.custom-select:hover {
  background-color: #ffffff;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
}

input[type="text"].input_white,
input[type="password"].input_white,
input[type="email"].input_white,
input[type="tel"].input_white,
input[type="number"].input_white,
select.input_white,
textarea.input_white,
.custom-select.input_white {
  background-color: #ffffff !important;
  border: 1px solid #e2eef1 !important;
  box-shadow: 0px 17px 13px 0px #192c4b05;
}

@media screen and (max-width: 576px) {
  .form-select {
    padding: 12px !important;
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  .form-select {
    font-size: 13px;
  }
}

.form-group {
  margin-bottom: 20px !important;
}

.form-group .confirm {
  position: relative;
}

.form-group .confirm .confirm-btn {
  margin: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.search {
  background: #e2eef1;
  border-radius: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
  border: solid 1px transparent;
}

.search:hover {
  background: #ffffff;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
}

.search input {
  width: 100%;
  height: 100%;
  background: transparent !important;
  border: 1px solid transparent !important;
  padding-left: calc(2rem + 20px);
}

.search .result {
  margin: 0;
  border: none;
  background: #ffffff;
  border-radius: 200px;
  height: 2rem;
  width: 2rem;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-group-text {
  padding: 0.375rem 20px !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #e2e4e9;
  border-radius: 0.25rem 0 0 0.25rem;
  min-height: 64px;
}

.textarea {
  width: 100%;
  height: 150px;
  resize: vertical;
}

label {
  color: #77748f;
  margin-bottom: 5px;
}

/*--------------------------------------------------------------
### Radio Checkbox
--------------------------------------------------------------*/
.radio {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 5px;
}

.radio + label {
  text-transform: none;
  font-weight: normal;
  color: inherit;
  vertical-align: middle;
}

.radio input {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.radio input:checked {
  cursor: default;
}

.radio input:checked + .input:after {
  background: #183b56;
}

.radio .input {
  width: 18px;
  height: 18px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.radio .input:after {
  content: "";
  background: #fff;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
  -webkit-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -moz-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  -o-transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}

.radio:hover .input:after {
  background: #183b56;
}

.radio:hover input:checked + .input:after {
  background: #183b56;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: white;
  background-color: #566ffe;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  width: 20px;
  height: 20px;
}

.custom-control-label {
  margin-left: 5px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

.custom-control-label::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  display: block;
  box-shadow: 0px 17px 13px 0px #192c4b05 !important;
  outline: none;
  width: 20px;
  height: 20px;
  content: "";
  background: no-repeat 50%/50% 50%;
  outline: none !important;
}

.custom-control-label::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -30px;
  display: block;
  box-shadow: 0px 17px 13px 0px #192c4b05 !important;
  outline: none;
  width: 20px;
  height: 20px;
  content: "";
  background-color: #fff;
  outline: none !important;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 2.75rem;
  pointer-events: all;
  border-radius: 24px;
  height: 22px;
  border: none;
  background-color: #f9fbfc;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: white;
  background-color: #5a52ff;
  box-shadow: 0 21px 51px rgba(29, 14, 98, 0.15);
  width: 2.75rem;
  height: 20px;
}

.custom-switch .custom-control-label::after {
  top: 1px;
  left: calc(-14px + 0px);
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  box-shadow: 0 21px 23px -5px rgba(82, 48, 162, 0.12);
  border-radius: 1.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-47px + 0px);
  top: -9px;
}

.custom-switch .custom-control-label {
  margin-left: 0;
  padding-left: 19px;
}

.custom-select {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC");
  background-position: calc(100% - 0.5rem), 100% 0;
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;
  transition: none !important;
}

.input-group-prepend .btn {
  padding: 19px 18px;
}

.custom-select-xs {
  height: calc(0.5rem + 0.9375rem * 1.6 + 2px);
  padding: 0.25rem 1.875rem 0.25rem 0.625rem;
  line-height: 1.6;
  font-size: 0.9375rem;
}

.custom-control-label::before,
.custom-file-label {
  transition: 0.35s ease-in-out;
}

.form_cc_four {
  background-color: #ffffff;
  border-radius: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%237B61FFFF' stroke-width='3' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 2.5rem;
  -webkit-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  -moz-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  -o-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  -ms-box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
  box-shadow: 0px 50px 70px -10px rgba(11, 34, 56, 0.05) !important;
}

.form_cc_four .mn_with {
  min-width: max-content;
}

.form_cc_four.bg_dark label {
  margin-bottom: 5px;
  color: #fff;
}

.form_cc_four.bg_dark input,
.form_cc_four.bg_dark .form-control {
  background-color: rgba(255, 255, 255, 0.192) !important;
  border: none !important;
  color: #fff !important;
}

.form_cc_four.bg_dark input::placeholder,
.form_cc_four.bg_dark .form-control::placeholder {
  color: #908ea7 !important;
}

.form_cc_four.bg_dark input:hover,
.form_cc_four.bg_dark .form-control:hover {
  background-color: rgba(255, 255, 255, 0.075) !important;
}

@media (min-width: 992px) {
  .form_cc_four .item_upload {
    display: flex;
  }
}

.form_cc_four .item_upload span {
  font-size: 13px;
  color: #f9fbfc;
}

@media (max-width: 767px) {
  .form_cc_four .item_upload span {
    display: block;
  }
}

.form_cc_four .upload__file {
  position: relative;
  overflow: hidden;
  margin-right: 15px;
  background: white;
  color: #183b56;
  border-radius: 8px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.form_cc_four .upload__file input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
  left: -160px;
  font-size: 40px;
  cursor: pointer;
}

.form_cc_four .upload__file .btn {
  border-radius: 8px;
  border: 1px dashed #dce0e3;
  color: #183b56;
}

.form_cc_four .upload__file .btn i {
  vertical-align: middle;
  color: #183b56;
  font-size: 18px;
  margin-right: 4px;
  top: 5px;
}

.form_cc_four .upload__file:hover .btn {
  color: white;
}

.form_cc_four .upload__file:hover i {
  color: white;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 44px;
  height: 24px;
  background: #c0c9d8;
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: #566ffe;
}

input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

label:active:after {
  width: 44px;
}

.choose_collection {
  border-radius: 12px;
  background-color: #f2f7fa;
  padding: 10px;
  color: #183b56;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose_collection.is_brand {
  background-color: #ffffff;
  border: solid 1px #566ffe;
}

.choose_collection.is_brand img {
  margin-right: 10px;
  height: 2rem;
}

.choose_collection .icon {
  height: 2rem;
  width: 2rem;
  background: #ffffff;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose_collection .icon i {
  color: #566ffe;
}

.upload_file {
  position: relative;
}

.upload_file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.nav-tabs {
  border: none !important;
}

.nav-tabs .nav-item a.active {
  background: #183b56;
  color: #ffffff;
  border: solid 1px #183b56;
}

.nav-tabs .nav-item a.active:hover, .nav-tabs .nav-item a.active:focus {
  color: #ffffff !important;
  background: #183b56 !important;
  border: solid 1px #183b56;
}

.nav-tabs .nav-item a.active.btn-sh {
  box-shadow: 0 14px 35px -11px #183b56;
}

.nav-tabs .nav-item a.active.btn-outline {
  border: solid 2px #183b56;
  background: transparent !important;
  color: #183b56;
}

.nav-tabs .nav-item a.active.btn-outline:hover {
  color: #566ffe !important;
}

.nav-tabs .nav-item a.active.btn-outline-bottom {
  border-bottom: solid 3px #183b56;
  background: transparent !important;
  color: #183b56;
}

.dropdown-menu {
  border: 1px solid #e2eef1 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.share {
  position: relative;
}

.share .icon {
  cursor: pointer;
  background: #ffffff;
  height: 3rem;
  width: 3rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: solid 1px #e2eef1;
  border-radius: 2000px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.share .icon:hover {
  box-shadow: none;
  background: #e2eef1;
}

.share .icon i {
  color: #183b56;
  font-size: 18px;
}

.share .share_box,
.share .dropdown__popup {
  z-index: 99;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.share .share_box a,
.share .dropdown__popup a {
  color: #183b56;
  display: block;
}

.share .share_box a:hover,
.share .dropdown__popup a:hover {
  color: #566ffe;
  transform: scale(1.3);
}

.share .share_box i,
.share .dropdown__popup i {
  font-size: 20px;
}

.share .dropdown__popup {
  position: absolute;
  top: 60px;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%) translateY(-1rem);
}

.share .dropdown__popup.visible {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

.more {
  position: relative;
  z-index: 99;
}

.more .icon {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: solid 1px #e2eef1;
  border-radius: 2000px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more .icon:hover {
  box-shadow: none;
  background: #e2eef1;
}

.more .icon i {
  color: #183b56;
  font-size: 18px;
}

.more .more_box,
.more .dropdown__popup {
  z-index: 99;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  padding: 10px 20px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.more .more_box a,
.more .dropdown__popup a {
  color: #183b56;
  display: block;
  align-items: center;
}

.more .more_box a:hover,
.more .dropdown__popup a:hover {
  color: #566ffe;
}

.more .more_box i,
.more .dropdown__popup i {
  font-size: 20px;
  color: #eb5757;
}

.more .dropdown__popup {
  position: absolute;
  top: 60px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transform: translateY(-1rem);
}

.more .dropdown__popup.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.result .ri-search-line {
  position: relative;
  top: 2px;
}

.header__result .ri-search-line {
  position: relative;
  top: 1px;
}

.menu_categories {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-x: auto;
}

.menu_categories::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu_categories .switch_item {
  min-width: max-content;
}

.menu_categories li i {
  margin-right: 5px;
  font-size: 22px;
}

.menu_categories li a {
  display: flex;
  align-items: center;
  width: max-content;
  color: #183b56;
}

.menu_categories li a:hover {
  color: #566ffe;
}

.filters span {
  width: max-content;
  white-space: nowrap;
}

.menu_activity {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-x: auto;
}

.menu_activity::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu_activity .switch_item {
  min-width: max-content;
}

.menu_activity li {
  padding: 5px 20px;
  background-color: #e2eef1;
  border-radius: 1000px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu_activity li i {
  margin-right: 5px;
  font-size: 22px;
}

.menu_activity li a {
  display: flex;
  align-items: center;
  color: #183b56;
}

.menu_activity li a span {
  width: max-content;
  white-space: nowrap;
}

.menu_activity li:hover {
  background-color: #566ffe;
}

.menu_activity li:hover a,
.menu_activity li:hover i {
  color: #ffffff;
}

/*--------------------------------------------------------------
## breadcrumb styles
--------------------------------------------------------------*/
.breadcrumb {
  background-color: white;
  border-radius: 100px;
  /*-----------------------------
       default
  -----------------------------*/
}

.breadcrumb.default {
  background-color: white;
  padding: 6px 18px;
  box-shadow: 0 2px 4px #7e8eb11f !important;
  margin-bottom: 0;
  border: 1px solid #eaeaee;
  border-radius: 100px;
}

.breadcrumb.default .breadcrumb-item {
  color: #91A1BC;
  display: flex;
  align-items: center;
  padding-left: 2px;
}

.breadcrumb.default .breadcrumb-item::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(145,161,188,1)'/%3E%3C/svg%3E");
  vertical-align: middle;
  font-size: 13px;
  height: 20px;
  width: 20px;
  margin-top: 2px;
  margin-right: 2px;
  color: red;
}

.breadcrumb.default .breadcrumb-item:first-of-type::before {
  display: none;
}

.breadcrumb.default .breadcrumb-item a {
  font-size: 13px;
  font-weight: normal !important;
  color: #91A1BC;
}

.breadcrumb.default .breadcrumb-item.active {
  font-size: 13px;
  color: #0b1b27;
}

.breadcrumb {
  width: fit-content;
  background-color: transparent;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.breadcrumb a {
  color: #183b56;
}

.breadcrumb.has_style1 {
  border-radius: 20px;
  background: #183b56 !important;
}

.breadcrumb.has_style1 a {
  color: rgba(255, 255, 255, 0.459);
}

.breadcrumb.has_style1 .active {
  color: white !important;
}

.breadcrumb.has_style1 .breadcrumb-item {
  position: relative;
}

.breadcrumb.has_style1 .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  color: #6c757d;
  content: "";
  margin-right: 7px;
  left: 0;
  top: 0%;
  width: 9px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.breadcrumb.has_stylel2 {
  border-radius: 20px;
  background: transparent !important;
}

.breadcrumb.has_stylel2 a {
  color: rgba(255, 255, 255, 0.459);
}

.breadcrumb.has_stylel2 .active {
  color: white !important;
}

.breadcrumb.has_stylel2 .breadcrumb-item {
  position: relative;
}

.breadcrumb.has_stylel2 .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  color: #6c757d;
  content: "";
  margin-right: 7px;
  left: 0;
  top: 0%;
  width: 9px;
  height: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.breadcrumb.has_stylel2::before {
  content: none;
}

.breadcrumb .breadcrumb-item {
  position: relative;
}

.breadcrumb .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0;
  color: #6c757d;
  content: "";
  margin-right: 5px;
  left: 0;
  top: 0%;
  width: 12px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.breadcrumb .breadcrumb-item:first-child::before {
  content: none;
}

/*--------------------------------------------------------------
## accordion styles
--------------------------------------------------------------*/
.accordion {
  background-color: white;
  border: solid 1px #e2eef1;
  border-radius: 10px;
  outline: none !important;
  overflow: hidden;
}

.accordion .accordion-desc {
  color: #6f7d95;
  border: none;
  border-top: solid 1px #e2eef1;
  padding: 25px 0 0 0;
  margin-top: 25px;
}

.accordion .accordion-header {
  padding: 25px;
  margin-bottom: 0;
  border: none;
}

.accordion .accordion-button {
  color: transparent;
  padding: 0;
  border: none;
}

.accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z' fill='rgba(16,44,65,1)'/%3E%3C/svg%3E");
}

.accordion .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion .accordion-button:focus {
  box-shadow: none;
}

.accordion i {
  color: #0b1b27;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 4px;
  position: relative;
}

.accordion.has_style1 {
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white), radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.accordion.has_style1 .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z' fill='rgba(86,111,254,1)'/%3E%3C/svg%3E");
}

/*--------------------------------------------------------------
## avatar styles
--------------------------------------------------------------*/
.avatars {
  display: flex;
  align-items: center;
}

.avatars .media {
  position: relative;
}

.avatars .media .badge {
  position: absolute;
  bottom: 0;
  right: -7px;
}

.avatars .media .badge img {
  width: 100%;
}

.avatars .media .has_number {
  position: absolute;
  left: -12%;
  top: 40%;
  width: 22px;
  height: 22px;
  color: #ffffff;
  background-color: #183b56;
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.avatars .media .has_number.top {
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  left: 70%;
  top: 4%;
}

.avatars .media.has_border {
  box-sizing: border-box;
  padding: 4px;
  border-radius: 100%;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
}

.avatars .avatars_name {
  font-weight: 700;
  transition: 0.3s;
}

.avatars .avatars_name.large {
  font-size: 22px;
}

.avatars .avatars_name:hover {
  color: #566ffe;
}

.avatars .price {
  font-size: 15px;
}

.avatars .details {
  display: flex;
  justify-content: space-between;
  background: #e6edf0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  width: 100%;
}

.avatars .avatar {
  width: 4.5rem;
  height: 4.5rem;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border-radius: 50% !important;
  border: 3px solid #fff;
  object-fit: cover;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.avatars .avatar.avatar-sm {
  width: 2rem;
  height: 2rem;
}

.avatars .avatar.avatar-md {
  width: 5rem;
  height: 5rem;
}

@media screen and (max-width: 576px) {
  .avatars .avatar.avatar-md {
    width: 4rem;
    height: 4rem;
  }
}

.avatars .avatar.avatar-lg {
  width: 6rem;
  height: 6rem;
}

.avatars .avatar.avatar-forum {
  width: 50px;
  height: 50px;
}

.avatars .avatar.avatar-chat {
  width: 4rem;
  height: 4rem;
}

@media screen and (max-width: 576px) {
  .avatars .avatar.avatar-chat {
    width: 4rem;
    height: 4rem;
  }
}

.avatars .total__avatars {
  position: relative;
  color: white;
  font-size: 10px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border-radius: 50% !important;
  border: 3px solid #fff;
  object-fit: cover;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  background: #566ffe;
  padding: 7px 4px;
}

.avatar-group {
  display: flex;
  margin-left: 25px;
}

.avatar-group .avatar-item {
  width: 3rem;
  height: 3rem;
  margin-left: -25px;
  border: solid 6px white;
}

.popup .modal-content {
  border: solid 1px #e2eef1;
  border-radius: 20px !important;
  box-shadow: 0px 17px 13px 0px #192c4b05;
}

.popup .close {
  position: absolute;
  right: -15px;
  top: -15px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  outline: none;
  border: solid 1px #e2eef1;
  background-color: #ffffff;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2;
}

.popup .close:hover {
  background-color: #183b56;
  border: solid 1px #183b56;
}

#popup_preview .modal-content {
  max-width: 20rem;
}

.edit_box {
  padding: 40px !important;
}

@media screen and (max-width: 991px) {
  .edit_box {
    padding: 20px !important;
  }
}

.box {
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  display: block;
}

.box.is__live {
  background: #d4e3ea;
}

.box.is__big {
  padding: 30px !important;
}

@media screen and (max-width: 991px) {
  .box.is__big {
    padding: 20px !important;
  }
}

.box .social_profile a {
  color: #183b56;
  display: flex;
  align-items: center;
}

.box .social_profile a i {
  font-size: 20px;
  height: 2rem;
  width: 2rem;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: all 0.25s ease-in-out;
  border: 1px solid transparent;
}

.box .social_profile a .ri-messenger-line {
  color: #006aff;
  background: #006aff3a;
}

.box .social_profile a .ri-whatsapp-line {
  color: #25d366;
  background: #25d36538;
}

.box .social_profile a .ri-youtube-line {
  color: #ff0000;
  background: #ff000027;
}

.box .social_profile a .ri-facebook-line {
  color: #4267b2;
  background: #4267b22a;
}

.box.in__upload {
  padding: 40px;
}

@media screen and (max-width: 576px) {
  .box.in__upload {
    padding: 15px;
    margin-bottom: 160px;
  }
}

.box.in__upload .left__part {
  border: 1px dashed #b7c5db;
  border-radius: 20px;
  text-align: center;
  padding: 40px;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .box.in__upload .left__part {
    height: auto;
    padding: 20px;
  }
}

.box.in__profile {
  position: relative;
  border: 1px dashed #b7c5db;
  border-radius: 20px;
  text-align: center;
  height: 100%;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

@media screen and (max-width: 991px) {
  .box.in__profile {
    height: 74px;
    padding: 20px;
  }
}

.box.in__profile .icon {
  max-width: 60px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .box.in__profile .icon {
    max-width: 30px;
  }
}

.box.in__profile .icon:hover {
  transform: scale(1.1);
}

.box.in__profile input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.box.in__profile:hover {
  background-color: #f9fbfc;
}

.box.in__wallet {
  text-align: center;
  margin-bottom: 20px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0);
}

.box.in__wallet:hover {
  transform: translateY(-0.8rem);
  border: 1px solid rgba(86, 111, 254, 0.26);
}

.card__item {
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  margin: auto;
  margin-bottom: 30px;
  max-width: 30rem;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.card__item.has_style2 {
  background: transparent;
  padding: 0;
  overflow: visible;
  margin: 0;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.card__item .view_history {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.card__item .view_history:hover {
  color: #183b56;
}

.card__item.is_gaming {
  margin-top: 25px;
  overflow: visible !important;
}

.card__item.is_gaming .avatar {
  margin-top: -60px;
  height: 60px;
  width: 60px;
}

.card__item.is_gaming .creators {
  overflow: visible !important;
}

.card__item.in__popup {
  margin: 0 !important;
  max-width: 100% !important;
}

.card__item.is__hero {
  padding: 0;
  border: none;
  box-shadow: none;
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .card__item.is__hero {
    margin-bottom: 30px;
  }
}

.card__item .card_title a {
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block ruby;
}

.card__item .card_head {
  border-radius: 20px;
  background-color: #ecf1f9;
  height: 22rem;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.card__item .card_head img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.card__item:hover {
  transform: translateY(-5px);
}

.card__item:hover .card_head img {
  transform: scale(1.05);
}

.card__item .card_footer .ri-history-line {
  font-size: 20px;
}

.card__item .creators,
.card__item .card_footer,
.card__item .bid,
.card__item .likes {
  display: flex;
  align-items: center;
}

.card__item.one .card_head, .card__item.six .card_head {
  max-height: 300px;
}

.card__item.one .card_head .details, .card__item.six .card_head .details {
  position: absolute;
  background: rgba(255, 255, 255, 0.93);
  padding: 10px 18px;
  z-index: 1;
  bottom: 14px;
  left: 14px;
  right: 14px;
  border-radius: 10px;
}

.card__item.one .card_head .details .icon img, .card__item.six .card_head .details .icon img {
  width: 2rem;
}

.card__item.one .card_head .details .fire-icon, .card__item.six .card_head .details .fire-icon {
  position: absolute;
  top: -16px;
  left: 46%;
  width: 30px;
  height: 30px;
  background: #eb5757;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  border: 2px solid #ffffff;
}

.card__item.one .creators,
.card__item.one .card_footer,
.card__item.one .bid,
.card__item.one .likes, .card__item.six .creators,
.card__item.six .card_footer,
.card__item.six .bid,
.card__item.six .likes {
  display: flex;
  align-items: center;
}

.card__item.one .likes, .card__item.six .likes {
  color: #183b56;
}

.card__item.one .likes i, .card__item.six .likes i {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #b8bdd1;
  position: relative;
}

.card__item.one .likes i::after, .card__item.six .likes i::after {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 100%;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.card__item.one .likes i:hover, .card__item.six .likes i:hover {
  transform: scale(1.2);
  color: #eb5757;
  position: relative;
}

.card__item.one .likes i:hover::after, .card__item.six .likes i:hover::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) scale(1.5);
}

.card__item.two .card_head {
  max-height: 300px;
  border-radius: 20px;
  background-color: #ecf1f9;
  height: 22rem;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.card__item.two .card_head img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__item.two .card_head .block_timer {
  position: absolute;
  z-index: 1;
  top: 14px;
  left: 14px;
}

.card__item.two .card_head .block_timer .item {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.966);
  width: 33px;
  height: 33px;
  border-radius: 20px;
}

.card__item.two .card_head .block_timer .item .number {
  color: #183b56;
}

.card__item.two .card_head .block_timer .dots {
  color: #ffffff;
  padding: 0 5px;
  height: auto !important;
  margin-top: 0;
}

.card__item.two .card_head .progress {
  position: absolute;
  z-index: 1;
  bottom: 14px;
  left: 14px;
  right: 14px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
}

.card__item.two .card_head .progress .progress-bar {
  height: 50%;
  align-self: center;
  margin-left: 5px;
  color: #fff;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border-radius: 20px;
}

.card__item.three .creators, .card__item.four .creators {
  justify-content: space-between;
  overflow: hidden;
}

.card__item.three .card_head, .card__item.four .card_head {
  max-height: 300px;
  position: relative;
}

.card__item.three .card_head .action, .card__item.four .card_head .action {
  position: absolute;
  width: 100%;
  padding: 0 14px;
  z-index: 1;
  bottom: 14px;
  display: flex;
  justify-content: center;
}

.card__item.three .card_head .action .btn, .card__item.four .card_head .action .btn {
  width: 100%;
}

.card__item.three .card_head .likes, .card__item.four .card_head .likes {
  position: absolute;
  background: rgba(255, 255, 255, 0.966);
  padding: 3px 12px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  z-index: 1;
  top: 14px;
  right: 14px;
  color: #183b56;
}

.card__item.three .card_head .likes i, .card__item.four .card_head .likes i {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #ff6871;
  font-weight: bold;
  position: relative;
}

.card__item.three .card_head .likes i::after, .card__item.four .card_head .likes i::after {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 100%;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.card__item.three .card_head .likes i:hover, .card__item.four .card_head .likes i:hover {
  transform: scale(1.2);
  color: #eb5757;
  position: relative;
}

.card__item.three .card_head .likes i:hover::after, .card__item.four .card_head .likes i:hover::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) scale(1.5);
}

.card__item.five .card_head {
  max-height: 300px;
  border-radius: 20px;
  background-color: #ecf1f9;
  height: auto;
  width: 80%;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.card__item.five .card_head img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__item.five .card_head .progress {
  position: absolute;
  z-index: 1;
  bottom: 14px;
  left: 14px;
  right: 14px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
}

.card__item.five .card_head .progress .progress-bar {
  height: 50%;
  align-self: center;
  margin-left: 5px;
  color: #fff;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border-radius: 20px;
}

.card__item.six {
  max-width: 400px;
  margin: 0 auto;
}

.card__item.six .card_body .card_head {
  max-width: none;
  max-height: none;
  height: auto;
}

.card__item.six .card_body .card_footer {
  position: relative;
}

.card__item.six .card_body .card_footer .details {
  width: 90%;
  background: white;
  padding: 10px 18px;
  z-index: 1;
  margin-top: -50px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}

.card__item.six .card_body .card_footer .details .card_title {
  font-size: 19px;
}

@media screen and (max-width: 576px) {
  .card__item.six .card_body .card_footer .details .card_title {
    font-size: 17px;
  }
}

.card__item.six .card_body .card_footer .details .card_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -10px;
  flex-wrap: wrap;
}

.card__item.six .card_body .card_footer .details .card_wrap .bid, .card__item.six .card_body .card_footer .details .card_wrap .auction_end {
  margin-right: 20px;
}

.card__item.six .card_body .card_footer .details .card_wrap .bid, .card__item.six .card_body .card_footer .details .card_wrap .auction_end, .card__item.six .card_body .card_footer .details .card_wrap .bid_btn {
  display: block;
  margin-bottom: 10px;
}

.card__item.six .card_body .card_footer .details .card_wrap .auction_end .counter {
  color: #eb5757;
}

.card__item.six .card_body .card_footer .details .card_wrap .bid_btn {
  margin-left: auto;
  margin-right: auto;
}

.item_details .item_img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item_details .likes {
  background: #ffffff;
  padding: 7px 14px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  color: #183b56;
  display: flex;
}

.item_details .likes i {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #ff6871;
  font-weight: 700;
  position: relative;
  margin-right: 5px;
}

.card_home4 .icon-shadow {
  border-radius: 100%;
  box-shadow: 0px 10px 11px rgba(25, 44, 75, 0.13);
  max-width: 48px;
}

.creator_card {
  background: #ffffff;
  border: solid 1px #e2eef1;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  max-width: 30rem;
}

.creator_card.is_big {
  padding: 30px 20px !important;
}

.creator_card.rounded_border {
  border-radius: 200px;
}

.creator_item {
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 30rem;
}

@media screen and (min-width: 769px) {
  .creator_item {
    margin-left: auto;
    margin-right: auto;
  }
}

.creator_item .cover {
  width: 100%;
  background: #183b56;
  height: 100px;
  border-radius: 10px 10px 0 0;
  margin-bottom: -50px;
  overflow: hidden;
}

.creator_item .cover img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .collections {
    margin-right: auto;
    margin-left: auto;
  }
}

.collections .collections_item {
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
}

.collections .collections_item .top_imgs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.collections .collections_item .top_imgs img {
  width: 31%;
  object-fit: cover;
}

.collections .collections_item .images-box {
  border-radius: 10px;
  overflow: hidden;
}

.collections .collections_item img {
  width: 100%;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  transition: all 0.35s ease-in-out;
}

.collections .collections_footer .collection_title a {
  color: #183b56;
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.collections .collections_footer .collection_title a:hover {
  color: #566ffe;
}

.collections .creators,
.collections .collections_footer,
.collections .likes {
  display: flex;
  align-items: center;
}

.collections .likes {
  color: #183b56;
}

.collections .likes i {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #b8bdd1;
  position: relative;
}

.collections .likes i::after {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 100%;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.collections .likes i:hover {
  transform: scale(1.2);
  color: #eb5757;
  position: relative;
}

.collections .likes i:hover::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) scale(1.5);
}

.collections.is__gaming .images-box {
  border-radius: 0;
}

.collections.is__gaming .images-box img {
  border-radius: 20px;
  width: 100%;
}

.collections.is__gaming .images-box .big_img {
  object-fit: cover;
  width: 800%;
  max-height: 368px;
}

.collections.is__gaming .images-box .left_imgs {
  overflow: hidden;
}

.menu_categories {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-x: auto;
}

.menu_categories::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu_categories .switch_item {
  min-width: max-content;
}

.menu_categories li i {
  margin-right: 5px;
  font-size: 22px;
}

.menu_categories li a {
  display: flex;
  align-items: center;
  width: max-content;
  color: #183b56;
}

.menu_categories li a:hover {
  color: #566ffe;
}

.filters span {
  width: max-content;
  white-space: nowrap;
}

.menu_activity {
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-x: auto;
}

.menu_activity::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu_activity .switch_item {
  min-width: max-content;
}

.menu_activity li {
  padding: 5px 20px;
  background-color: #e2eef1;
  border-radius: 1000px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu_activity li i {
  margin-right: 5px;
  font-size: 22px;
}

.menu_activity li a {
  display: flex;
  align-items: center;
  color: #183b56;
}

.menu_activity li a span {
  width: max-content;
  white-space: nowrap;
}

.menu_activity li:hover {
  background-color: #566ffe;
}

.menu_activity li:hover a,
.menu_activity li:hover i {
  color: #ffffff;
}

/* ===================
4/ Layouts
=======================*/
.header__1 {
  position: relative;
  background: #ffffff;
  border-bottom: solid 1px #e2eef1;
  width: 100%;
  white-space: nowrap;
}

.header__1.header_wallet {
  display: none;
}

.header__1 .header__menu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.header__1 .header__menu::-webkit-scrollbar {
  display: none;
}

.header__1 .header__menu a {
  white-space: nowrap;
}

.header__1 .wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.header__1 .header__logo img {
  height: 2.2rem;
}

.header__1 .is_new {
  position: relative;
  position: relative;
}

.header__1 .is_new::before {
  content: "";
  position: absolute;
  left: -6px;
  top: -3px;
  height: 5px;
  width: 5px;
  border-radius: 22px;
  background: #eb5757;
}

.header__1 .has_popup,
.header__1 .has_popup2 {
  position: relative;
}

.header__1 .has_popup > a,
.header__1 .has_popup2 > a {
  display: flex;
  align-items: center;
}

.header__1 .has_popup .menu__popup,
.header__1 .has_popup .menu__popup2,
.header__1 .has_popup2 .menu__popup,
.header__1 .has_popup2 .menu__popup2 {
  position: absolute;
  padding: 30px;
  top: 3rem;
  left: 50%;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  transition: all 0.35s ease-in-out;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1rem) translateX(-50%);
  max-width: 20rem;
  width: max-content;
  z-index: 9;
}

.header__1 .has_popup .menu__popup a,
.header__1 .has_popup .menu__popup2 a,
.header__1 .has_popup2 .menu__popup a,
.header__1 .has_popup2 .menu__popup2 a {
  display: flex;
  align-items: center;
  color: #183b56;
}

.header__1 .has_popup .menu__popup a:hover,
.header__1 .has_popup .menu__popup2 a:hover,
.header__1 .has_popup2 .menu__popup a:hover,
.header__1 .has_popup2 .menu__popup2 a:hover {
  color: #566ffe;
}

.header__1 .has_popup .menu__popup a i,
.header__1 .has_popup .menu__popup2 a i,
.header__1 .has_popup2 .menu__popup a i,
.header__1 .has_popup2 .menu__popup2 a i {
  font-size: 20px;
  margin-right: 5px;
}

.header__1 .has_popup .menu__popup.visible,
.header__1 .has_popup .menu__popup2.visible,
.header__1 .has_popup2 .menu__popup.visible,
.header__1 .has_popup2 .menu__popup2.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}

.header__1 .has_popup .menu__popup2,
.header__1 .has_popup2 .menu__popup2 {
  width: 27rem;
  max-width: inherit;
}

.header__1 .header__search {
  background: #e2eef1;
  height: 3rem;
  width: 20rem;
  border-radius: 10px;
  position: relative;
  transition: all 0.2s ease-in-out;
  border: solid 1px transparent;
}

@media screen and (max-width: 991px) {
  .header__1 .header__search.in_mobile {
    display: block;
    width: 100%;
  }
}

.header__1 .header__search:hover {
  background: white;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
}

.header__1 .header__search input {
  width: 100%;
  height: 100%;
  background: transparent !important;
  border: none !important;
  padding-left: calc(2rem + 20px);
}

.header__1 .header__search .header__result {
  border: none;
  background: #ffffff;
  border-radius: 200px;
  height: 2rem;
  width: 2rem;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.header__1 .header__burger {
  display: none;
}

@media screen and (max-width: 991px) {
  .header__1 .header__burger {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
    background: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .header__1 .header__burger.active:before {
    transform: translateY(0) rotate(-45deg);
  }
  .header__1 .header__burger.active:after {
    transform: translateY(0) rotate(45deg);
  }
  .header__1 .header__burger:before, .header__1 .header__burger:after {
    content: "";
    position: absolute;
    top: 16px;
    left: 6px;
    width: 20px;
    height: 2px;
    background: #6f7d95;
    border-radius: 2px;
    transition: transform 0.2s;
    cursor: pointer;
  }
  .header__1 .header__burger:before {
    transform: translateY(-4px);
  }
  .header__1 .header__burger:after {
    transform: translateY(3px);
  }
}

.header__1 .header__mobile {
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  top: 4.9rem;
  left: 0;
  right: 0;
  border-radius: 10px;
  margin: 0 !important;
  background: #ffffff;
  width: 100%;
  padding: 40px;
  transition: all 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  z-index: 9;
  transform: translateY(-1rem) translateX(0);
}

@media screen and (max-width: 991px) {
  .header__1 .header__mobile.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

.header__1 .header__mobile__menu ul {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  .header__1 .header__search {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .header__1 .header__search,
  .header__1 .header__menu {
    display: none;
  }
}

.header__1 .header__btns {
  display: block;
}

@media screen and (max-width: 991px) {
  .header__1 .header__btns {
    display: none;
  }
}

.header__1 .header__avatar {
  border-radius: 2000px;
  border: solid 1px #e2eef1;
  background: #f9fbfc;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 9;
}

@media screen and (max-width: 768px) {
  .header__1 .header__avatar {
    display: none !important;
  }
}

.header__1 .header__avatar:hover {
  background: #e6edf0;
}

.header__1 .header__avatar .avatar {
  height: 2.4rem;
  width: 2.4rem;
  border-radius: 2000px;
  object-fit: cover;
}

.header__1 .header__avatar .price {
  padding: 0 10px 0 20px;
}

.header__1 .header__avatar .avatar_popup {
  position: absolute;
  padding: 30px;
  top: 6rem;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1rem);
}

.header__1 .header__avatar .avatar_popup.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.header__1 .header__avatar .avatar_popup .links {
  display: flex;
  flex-direction: column;
}

.header__1 .header__avatar .avatar_popup .links a {
  color: #183b56;
  display: flex;
  align-items: center;
}

.header__1 .header__avatar .avatar_popup .links a i {
  margin-right: 5px;
  font-size: 20px;
}

.header__1 .header__avatar .avatar_popup .links a:hover {
  color: #566ffe;
}

.header__1 .header__notifications .js-notifications-icon {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header__1 .header__notifications {
    display: none !important;
  }
}

.header__1 .header__notifications .notifications_popup {
  position: absolute;
  padding: 30px;
  top: 6rem;
  overflow: hidden;
  border-radius: 10px;
  background: #ffffff;
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-1rem);
  max-width: 30rem;
  z-index: 9;
}

.header__1 .header__notifications .notifications_popup.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.header__1 .header__notifications .notifications_popup .item {
  position: relative;
  padding-right: 40px;
}

.header__1 .header__notifications .notifications_popup .circle {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #566ffe;
}

.header__1 .header__notifications .notifications_popup a h6 {
  transition: all 0.25s ease-in-out;
}

.header__1 .header__notifications .notifications_popup a h6:hover {
  color: #566ffe;
}

.header__1 .header__notifications .notifications_popup .badge {
  border-radius: 20px;
  padding: 8px 16px 6px;
  font-size: 14px;
  background: #183b56;
}

.header__1 .header__notifications .notifications_popup .thumb {
  height: 3rem;
  width: 3rem;
  border-radius: 2000px;
  background: #e2eef1;
}

.header__1 .header__notifications .notifications_popup .links {
  display: flex;
  flex-direction: column;
}

.header__1 .header__notifications .notifications_popup .links a {
  color: #183b56;
  display: flex;
  align-items: center;
}

.header__1 .header__notifications .notifications_popup .links a i {
  margin-right: 5px;
  font-size: 20px;
}

.header__1.is__dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.082);
  background: #183b56;
}

.header__1.is__dark * {
  color: #ffffff !important;
}

.header__1.is__dark .header__search {
  background: #0b1b27;
}

.header__1.is__dark .header__search:hover, .header__1.is__dark .header__search:focus {
  border: none !important;
}

.header__1.is__dark .header__result {
  background: #183b56 !important;
}

.header__1.is__dark .menu__popup {
  background: #142c3f;
  border: none;
}

.hero__1 {
  overflow: hidden;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  z-index: 0;
  position: relative;
}

.hero__1::before {
  content: "";
  position: absolute;
  left: 0;
  background: url(../img/effects/hero.jpg);
  width: 476px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .hero__1::before {
    display: none;
  }
}

.hero__1 .hero__left {
  padding: 100px 0;
}

@media screen and (max-width: 991px) {
  .hero__1 .hero__left {
    padding: 50px 0;
  }
}

.hero__1 .hero__text {
  max-width: 30rem;
  margin-left: 0;
}

.hero__1.is__dark {
  background: #183b56;
  position: relative;
}

.hero__1.is__dark::before {
  content: "";
  position: absolute;
  display: none;
}

.hero__1.is__dark *:not(p):not(.btn) {
  color: #ffffff;
}

.hero__1.is__dark p {
  color: rgba(255, 255, 255, 0.486);
}

.hero__index {
  overflow: hidden;
  position: relative;
  z-index: 0;
  position: relative;
  position: relative;
}

.hero__index::before {
  content: "";
  position: absolute;
  left: 0;
  background: white;
  width: 100vw;
  height: calc(100% - 5rem);
  z-index: -1;
  font-size: 22px;
}

@media screen and (max-width: 991px) {
  .hero__index::before {
    height: calc(100%);
  }
}

.hero__index .is__big {
  padding: 40px;
  transform-origin: 50% -50px;
  transform: perspective(400px) rotateX(3deg);
}

@media screen and (max-width: 991px) {
  .hero__index .is__big {
    transform-origin: 0;
    display: none;
    transform: none;
  }
}

.hero__index .hero_icons .icon {
  padding: 20px;
  border-radius: 50px;
  display: block;
}

.hero__index .hero_icons .icon.is_1 {
  background: #faab021a;
  color: #f59608;
}

.hero__index .hero_icons .icon.is_2 {
  background: #09b68b1f;
  color: #0dc990;
}

.hero__index .hero_icons .icon.is_3 {
  background: #094eb61f;
  color: #2e4f69;
}

.hero__index .sh_1 {
  position: absolute;
  top: 0;
  right: 0;
}

.hero__index .sh_2 {
  position: absolute;
  bottom: 5rem;
  left: 0;
}

@media screen and (max-width: 991px) {
  .hero__index .sh_2 {
    display: none;
  }
}

.hero__index .sh_3 {
  position: absolute;
  bottom: 15rem;
  right: 15rem;
}

.hero__index .hero__title {
  font-size: 74px;
}

@media screen and (max-width: 991px) {
  .hero__index .hero__title {
    font-size: 45px;
  }
}

.hero__index::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -14rem;
  background: url(../img/logos/top.svg);
  width: 80rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

.hero__2 {
  overflow: hidden;
  position: relative;
  padding-top: 100px;
  z-index: 0;
  position: relative;
}

@media screen and (max-width: 991px) {
  .hero__2 {
    padding-top: 50px;
  }
}

.hero__2::before {
  content: "";
  position: absolute;
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  width: 100%;
  height: 80%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

.hero__2 .hero__text {
  max-width: 30rem;
}

.hero__2 .wrapper {
  margin-top: 50px;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  background-color: #ffffff;
  position: relative;
  position: relative;
}

.hero__2 .wrapper::before {
  content: "";
  position: absolute;
  background: url(../img/bg/ce.png);
  background-size: cover;
  height: 25rem;
  width: 25rem;
  top: -10rem;
  left: -8rem;
  z-index: -1;
}

.hero__2 .wrapper::after {
  content: "";
  position: absolute;
  background: url(../img/bg/ce.png);
  background-size: cover;
  height: 25rem;
  width: 25rem;
  top: -10rem;
  right: -8rem;
  z-index: -1;
}

.hero__2 .wrapper .card__hero__item {
  background: #f9fbfc;
  border-radius: 20px;
}

.hero__2 .wrapper .card__hero__item .card__img {
  border-radius: 20px;
}

@media screen and (max-width: 991px) {
  .hero__2 .wrapper {
    margin-bottom: -30px;
  }
}

.hero__3 {
  padding: 100px 0 50px;
  background-image: url(../img/bg/effect.svg);
  background-size: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
}

@media screen and (max-width: 991px) {
  .hero__3 {
    padding-top: 50px;
  }
}

.hero__4 {
  padding: 50px 0 50px;
}

@media screen and (max-width: 991px) {
  .hero__4 {
    padding-top: 50px;
  }
}

.hero__4 .swiper_hero {
  position: relative;
}

@media screen and (max-width: 992px) {
  .hero__4 .swiper_hero {
    overflow: hidden;
  }
}

.hero__4 .swiper_hero .swiper-button-prev {
  height: 3rem;
  width: 3rem;
  background: #ffffff;
  border: solid 1px #e2eef1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out 0s;
  border-radius: 100px;
}

.hero__4 .swiper_hero .swiper-button-prev:hover {
  background: white;
  box-shadow: none;
}

.hero__4 .swiper_hero .swiper-button-prev::after {
  color: #183b56;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

@media screen and (max-width: 1400px) {
  .hero__4 .swiper_hero .swiper-button-prev {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .hero__4 .swiper_hero .swiper-button-prev {
    display: flex;
  }
}

.hero__4 .swiper_hero .swiper-button-next {
  height: 3rem;
  width: 3rem;
  background: #ffffff;
  border: solid 1px #e2eef1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out 0s;
  border-radius: 100px;
}

.hero__4 .swiper_hero .swiper-button-next:hover {
  background: #e2eef1;
  box-shadow: none;
}

.hero__4 .swiper_hero .swiper-button-next::after {
  color: #183b56;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

@media screen and (max-width: 1400px) {
  .hero__4 .swiper_hero .swiper-button-next {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .hero__4 .swiper_hero .swiper-button-next {
    display: flex;
  }
}

.hero__kit,
.hero_marketplace,
.hero_ranking,
.hero_forum,
.hero_questions,
.hero_no_results,
.hero_privacy,
.hero_upcoming_projects {
  background: #ffffff;
  padding: 100px 0;
  border-bottom: 1px solid #e2eef1;
}

@media screen and (max-width: 991px) {
  .hero__kit,
  .hero_marketplace,
  .hero_ranking,
  .hero_forum,
  .hero_questions,
  .hero_no_results,
  .hero_privacy,
  .hero_upcoming_projects {
    padding: 50px 0;
  }
}

.hero_upcoming_projects .hero__desc {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.hero_Live_Auctions {
  background-color: #ecf1f9;
  padding: 100px 0 0;
}

@media screen and (max-width: 768px) {
  .hero_Live_Auctions {
    padding: 70px 0 0;
  }
}

@media screen and (max-width: 992px) {
  .hero_Live_Auctions .left_content {
    margin-bottom: 30px;
  }
}

.hero_Live_Auctions .card__item {
  margin-left: auto !important;
  margin-right: 0;
  max-width: 40rem;
}

.hero_Live_Auctions .card__item:hover {
  transform: translateY(0);
}

@media screen and (max-width: 992px) {
  .hero_Live_Auctions .card__item {
    margin-left: auto;
    margin-right: auto !important;
  }
}

@media screen and (max-width: 576px) {
  .hero_Live_Auctions .card__item .card_left {
    margin-bottom: 20px;
  }
}

.hero_Live_Auctions .card__item .card_left .details {
  background-color: #183b56;
  padding: 10px 18px;
  border-radius: 10px;
}

.hero_Live_Auctions .card__item .card_left .details span {
  color: white;
}

.hero_Live_Auctions .card__item .card_right {
  overflow: hidden;
  border-radius: 20px;
}

.hero_Live_Auctions .card__item .card_right:hover {
  transform: translateY(0);
}

.hero_Live_Auctions .card__item .card_right img {
  border-radius: 20px;
  width: 100%;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.hero_Live_Auctions .card__item .card_right img:hover {
  transform: scale(1.05);
}

.hero_privacy {
  background-color: #ecf1f9 !important;
  border-bottom: none !important;
}

.hero_privacy .privacy__icon {
  font-size: 40px;
}

.hero_newsletter {
  margin-top: 100px;
  padding: 30px !important;
}

@media screen and (max-width: 991px) {
  .hero_newsletter {
    margin-top: 70px !important;
  }
}

@media screen and (max-width: 992px) {
  .hero_newsletter .left__side {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 992px) {
  .hero_newsletter .left__side .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 992px) {
  .hero_newsletter .right__side {
    display: flex;
    justify-content: center;
  }
}

.hero_newsletter .right__side .hero__img {
  border-radius: 20px;
}

.hero_questions p {
  max-width: 30rem;
}

.hero_no_results {
  background: #ecf1f9;
  border-bottom: none;
}

.hero_no_results .search__box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 20px 40px;
  margin-left: auto;
  margin-right: auto;
}

.hero_no_results .search__box i {
  font-size: 50px;
}

.hero_no_results .hero__title,
.hero_no_results .input__box {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.hero_no_results .hero__desc {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.hero__profile {
  position: relative;
}

.hero__profile .tabs {
  position: absolute;
  top: 20px;
  left: 45px;
}

.hero__profile .cover {
  height: 17rem;
}

.hero__profile .cover img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.hero__profile .avatar_wrap {
  position: relative;
  width: 6rem;
}

.hero__profile .avatar_wrap .avatar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: solid 5px #ffffff;
  width: 7rem;
  height: 7rem;
}

.hero__profile .infos {
  padding: 20px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #e2eef1;
}

.hero__profile .copy {
  padding: 10px 20px;
  border-radius: 400px;
  border: 1px solid #e2eef1;
  display: flex;
  justify-content: space-between;
}

.hero__profile .copy i {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #6f7d95;
}

.hero__creators {
  padding: 100px 0 50px;
}

.hero__creators .hero__text {
  max-width: 30rem;
  margin-left: 0;
}

.hero__collections {
  background: #ffffff;
  padding: 100px 0;
  border-bottom: 1px solid #e2eef1;
}

@media screen and (max-width: 991px) {
  .hero__collections {
    padding: 50px 0;
  }
}

.hero__activity {
  background: #ffffff;
  padding: 100px 0;
  border-bottom: 1px solid #e2eef1;
}

@media screen and (max-width: 991px) {
  .hero__activity {
    padding: 50px 0;
  }
}

.hero__upload {
  padding: 100px 0;
}

@media screen and (max-width: 991px) {
  .hero__upload {
    padding: 50px 0;
  }
}

.hero__upload .hero__text {
  max-width: 30rem;
}

@media screen and (max-width: 576px) {
  .hero__upload .switch {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 576px) {
  .hero__upload .title {
    text-align: center;
  }
}

.footer__1 {
  padding: 100px 0 50px;
  margin-top: 100px;
  border-top: 1px solid #e2eef1;
  background-color: #ffffff;
}

@media screen and (max-width: 991px) {
  .footer__1 {
    padding: 70px 0 30px;
    margin-top: 70px;
  }
}

.footer__1 .footer_logo img {
  height: 3rem;
}

.footer__1 .footer__text {
  max-width: 30rem;
  margin-left: 0;
}

.footer__1 .footer__social {
  display: flex;
}

.footer__1 .footer__social a,
.footer__1 .footer__social i {
  color: #183b56;
  font-size: 1.2rem;
}

.footer__1 .footer__social i {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #e2eef1;
  transition: all 0.25s ease-in-out;
  border: solid 1px transparent;
}

.footer__1 .footer__social i:hover {
  background: #ffffff;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #e2eef1;
}

.footer__1 .footer-desc {
  margin-bottom: 2rem;
}

.footer__1 .footer-desc .logo {
  margin-bottom: 1rem;
}

.footer__1 .footer-desc .logo img {
  height: 2.5rem;
}

.footer__1 .footer-desc .social-footer {
  margin-top: 1rem;
}

.footer__1 .footer-desc .social-footer a img {
  width: 18px;
  margin-right: 8px;
}

.footer__1 .footer-desc .social-footer a ion-icon {
  font-size: 25px;
  color: white;
}

.footer__1 .footer__title {
  font-size: 1.1rem;
  position: relative;
  margin-bottom: 1.3rem;
}

.footer__1 .footer__list {
  margin-bottom: 2rem;
}

.footer__1 .footer__list li {
  margin-bottom: 1rem;
}

.footer__1 .footer__list li a {
  color: #183b56;
}

.footer__1 .footer__list li a:hover {
  padding-left: 0.5rem;
  color: #566ffe;
}

.footer__1 .copyright {
  margin-top: 35px;
  font-size: 14px;
  margin-bottom: 0;
}

.footer__1 .copyright p {
  padding: 2rem 0 2rem;
  font-size: 1rem;
  margin: 0;
  color: #a5a5a5;
}

.section__title {
  font-size: 34px;
}

@media screen and (max-width: 991px) {
  .section__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 576px) {
  .section__title {
    font-size: 30px;
  }
}

.section__head {
  margin-bottom: 30px;
}

.logos__wrap {
  margin-top: 100px;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  padding: 80px 60px;
}

.logos__wrap img {
  height: fit-content;
}

@media screen and (max-width: 991px) {
  .logos__wrap {
    margin-top: 50px;
    padding: 50px 15px;
  }
}

.call2action {
  background: radial-gradient(100.04% 100.76% at 50.49% -0.76%, #29436d 0%, #050911 100%);
  padding: 100px 0;
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .call2action {
    text-align: center;
  }
  .call2action .section__text {
    margin-right: auto;
    margin-left: auto;
  }
}

.call2action.is__light {
  background-color: #ffffff;
  background-image: url(../img/bg/circles.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
}

.call2action .section__text {
  max-width: 30rem;
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .call2action .section__text {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 991px) {
  .call2action {
    margin-top: 50px;
  }
}

.community {
  margin-top: 100px;
}

@media screen and (max-width: 991px) {
  .community {
    margin-top: 50px;
  }
}

.community .section__title {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}

.community .section__text {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.community .community__items .item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  padding: 30px;
}

@media screen and (max-width: 991px) {
  .community .community__items .item {
    margin-bottom: 20px;
  }
}

.community .community__items .item .logo {
  height: 5rem;
  width: 5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edf3f5;
}

.community .community__items .item .logo.is_twitter {
  background: rgba(5, 171, 246, 0.1);
}

.community .community__items .item .logo.is_discord {
  background: rgba(114, 137, 217, 0.1);
}

.fixed_row {
  padding: 20px 0;
  background-color: #ffffff;
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  border-top: 1px solid #e2eef1;
}

@media screen and (max-width: 404px) {
  .fixed_row .content {
    justify-content: center !important;
  }
}

.section__artists {
  position: relative;
}

.section__artists .container {
  position: relative;
}

.section__artists .swiper-button-prev {
  height: 3rem;
  width: 3rem;
  top: 20px;
  left: auto;
  right: 5rem !important;
  background: #ffffff;
  border: solid 1px #e2eef1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out 0s;
  border-radius: 100px;
}

.section__artists .swiper-button-prev:hover {
  background: white;
  box-shadow: none;
}

.section__artists .swiper-button-prev::after {
  color: #183b56;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

.section__artists .swiper-button-next {
  height: 3rem;
  width: 3rem;
  top: 20px;
  right: 15px !important;
  background: #ffffff;
  border: solid 1px #e2eef1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out 0s;
  border-radius: 100px;
}

.section__artists .swiper-button-next:hover {
  background: #e2eef1;
  box-shadow: none;
}

.section__artists .swiper-button-next::after {
  color: #183b56;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.2s ease-in-out 0s;
}

@media screen and (max-width: 576px) {
  .section__activity .box .row {
    justify-content: center !important;
  }
}

@media screen and (max-width: 576px) {
  .section__activity .box .row .creators_box {
    margin-bottom: 10px;
  }
}

.section__creators .w-container .w-row {
  margin-left: -10px;
  margin-right: -10px;
}

.section__creators .w-row:before,
.section__creators .w-row:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.section__creators .w-row:after {
  clear: both;
}

.section__creators .w-row .w-row {
  margin-left: 0;
  margin-right: 0;
}

.section__creators .w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.section__creators .w-col .w-col {
  padding-left: 0;
  padding-right: 0;
}

.section__creators .w-col-1 {
  width: 8.33333333%;
}

.section__creators .w-col-2 {
  width: 16.66666667%;
}

.section__creators .w-col-3 {
  width: 25%;
}

.section__creators .w-col-4 {
  width: 33.33333333%;
}

.section__creators .w-col-5 {
  width: 41.66666667%;
}

.section__creators .w-col-6 {
  width: 50%;
}

.section__creators .w-col-7 {
  width: 58.33333333%;
}

.section__creators .w-col-8 {
  width: 66.66666667%;
}

.section__creators .w-col-9 {
  width: 75%;
}

.section__creators .w-col-10 {
  width: 83.33333333%;
}

.section__creators .w-col-11 {
  width: 91.66666667%;
}

.section__creators .w-col-12 {
  width: 100%;
}

.section__creators .w-hidden-main {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .section__creators .w-container {
    max-width: 728px;
  }
  .section__creators .w-hidden-main {
    display: inherit !important;
  }
  .section__creators .w-hidden-medium {
    display: none !important;
  }
  .section__creators .w-col-medium-1 {
    width: 8.33333333%;
  }
  .section__creators .w-col-medium-2 {
    width: 16.66666667%;
  }
  .section__creators .w-col-medium-3 {
    width: 25%;
  }
  .section__creators .w-col-medium-4 {
    width: 33.33333333%;
  }
  .section__creators .w-col-medium-5 {
    width: 41.66666667%;
  }
  .section__creators .w-col-medium-6 {
    width: 50%;
  }
  .section__creators .w-col-medium-7 {
    width: 58.33333333%;
  }
  .section__creators .w-col-medium-8 {
    width: 66.66666667%;
  }
  .section__creators .w-col-medium-9 {
    width: 75%;
  }
  .section__creators .w-col-medium-10 {
    width: 83.33333333%;
  }
  .section__creators .w-col-medium-11 {
    width: 91.66666667%;
  }
  .section__creators .w-col-medium-12 {
    width: 100%;
  }
  .section__creators .w-col-stack {
    width: 100%;
    left: auto;
    right: auto;
  }
}

@media screen and (max-width: 767px) {
  .section__creators .w-hidden-main {
    display: inherit !important;
  }
  .section__creators .w-hidden-medium {
    display: inherit !important;
  }
  .section__creators .w-hidden-small {
    display: none !important;
  }
  .section__creators .w-row,
  .section__creators .w-container .w-row {
    margin-left: 0;
    margin-right: 0;
  }
  .section__creators .w-col {
    width: 100%;
    left: auto;
    right: auto;
  }
  .section__creators .w-col-small-1 {
    width: 8.33333333%;
  }
  .section__creators .w-col-small-2 {
    width: 16.66666667%;
  }
  .section__creators .w-col-small-3 {
    width: 25%;
  }
  .section__creators .w-col-small-4 {
    width: 33.33333333%;
  }
  .section__creators .w-col-small-5 {
    width: 41.66666667%;
  }
  .section__creators .w-col-small-6 {
    width: 50%;
  }
  .section__creators .w-col-small-7 {
    width: 58.33333333%;
  }
  .section__creators .w-col-small-8 {
    width: 66.66666667%;
  }
  .section__creators .w-col-small-9 {
    width: 75%;
  }
  .section__creators .w-col-small-10 {
    width: 83.33333333%;
  }
  .section__creators .w-col-small-11 {
    width: 91.66666667%;
  }
  .section__creators .w-col-small-12 {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .section__creators .w-container {
    max-width: none;
  }
  .section__creators .w-hidden-main {
    display: inherit !important;
  }
  .section__creators .w-hidden-medium {
    display: inherit !important;
  }
  .section__creators .w-hidden-small {
    display: inherit !important;
  }
  .section__creators .w-hidden-tiny {
    display: none !important;
  }
  .section__creators .w-col {
    width: 100%;
  }
  .section__creators .w-col-tiny-1 {
    width: 8.33333333%;
  }
  .section__creators .w-col-tiny-2 {
    width: 16.66666667%;
  }
  .section__creators .w-col-tiny-3 {
    width: 25%;
  }
  .section__creators .w-col-tiny-4 {
    width: 33.33333333%;
  }
  .section__creators .w-col-tiny-5 {
    width: 41.66666667%;
  }
  .section__creators .w-col-tiny-6 {
    width: 50%;
  }
  .section__creators .w-col-tiny-7 {
    width: 58.33333333%;
  }
  .section__creators .w-col-tiny-8 {
    width: 66.66666667%;
  }
  .section__creators .w-col-tiny-9 {
    width: 75%;
  }
  .section__creators .w-col-tiny-10 {
    width: 83.33333333%;
  }
  .section__creators .w-col-tiny-11 {
    width: 91.66666667%;
  }
  .section__creators .w-col-tiny-12 {
    width: 100%;
  }
}

.section__creators .container._w-800 {
  max-width: 800px;
}

.section__creators .container._w-600 {
  max-width: 600px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.section__creators .container._w-400 {
  max-width: 400px;
}

.section__creators .container.flex-h {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.section__creators .container.flex-h.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.section__creators .container.flex-h.reverse.mt-128 {
  margin-top: 180px;
}

.section__creators .container.flex-h.mt-128 {
  margin-top: 180px;
}

.section__creators .container.flex-h.mt-128.type-1 {
  margin-top: 260px;
}

.section__creators .container.w300 {
  max-width: 300px;
}

.section__creators .container._w-1000 {
  max-width: 1000px;
}

.section__creators .container._w-1000.flex-h {
  max-width: 1000px;
}

.section__creators .container._w-1000.flex-h.cta {
  padding-right: 48px;
  padding-left: 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section__creators .container._w-1000.flex-h.downloads {
  padding-right: 24px;
  padding-left: 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section__creators .container._w-1000.flex-h.grid {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section__creators .container.grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section__creators .p.opacity {
  opacity: 0.6;
}

.section__creators .p.tiny {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.section__creators .p.tiny.text-align-center.mt-16 {
  margin-top: 16px;
}

.section__creators .p.tiny {
  margin-top: 10px;
  font-size: 0.8em;
}

.section__creators .p.medium {
  font-size: 18px;
}

.section__creators .clients-slider-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200%;
  margin-bottom: 32px;
  background-color: transparent;
}

.section__creators .clients-slider-mask {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  max-width: 100vw;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section__creators .clients-slider-mask.mt-8 {
  overflow: hidden;
  margin-top: 10px;
}

.section__creators .testimonial-card {
  display: -ms-grid;
  display: grid;
  padding: 16px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 11px;
  -ms-grid-columns: 1fr -webkit-max-content;
  -ms-grid-columns: 1fr max-content;
  grid-template-columns: 1fr -webkit-max-content;
  grid-template-columns: 1fr max-content;
  -ms-grid-rows: minmax(auto, 25px) auto -webkit-min-content;
  -ms-grid-rows: minmax(auto, 25px) auto min-content;
  grid-template-rows: minmax(auto, 25px) auto -webkit-min-content;
  grid-template-rows: minmax(auto, 25px) auto min-content;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
}

.section__creators .clients-slider-pane {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: transparent;
}

.section__creators .tc-company-image {
  max-height: 100%;
}

.section__creators .parent-cover {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.section__creators .sg---html-embed {
  width: 40px;
  height: 40px;
  background-color: #000;
}

.section__creators .align-btm {
  margin-top: auto;
}

.section__creators .align-top {
  margin-bottom: auto;
}

.section__creators .glow {
  position: absolute;
  left: -10%;
  top: -30%;
  z-index: 0;
  display: none;
  width: 60px;
  height: 60px;
  background-image: radial-gradient(circle closest-side at 50% 50%, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
  opacity: 0.5;
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.section__creators .nav-cta-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.section__creators .ml-8 {
  margin-left: 8px;
}

.section__creators .ml-24 {
  margin-left: 24px;
}

.section__creators .mt-8 {
  margin-top: 8px;
}

.section__creators .mt-16 {
  margin-top: 16px;
}

.section__creators .mb-8 {
  margin-bottom: 8px;
}

.section__creators .mb-16 {
  margin-bottom: 16px;
}

.section__creators .mr-8 {
  margin-right: 8px;
}

.section__creators .mr-16 {
  margin-right: 16px;
}

.section__creators .logos-wrapper.mt-16 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: auto;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section__creators .chat-bubble {
  position: relative;
  max-width: 600px;
  margin-right: 10%;
  margin-left: 10%;
  padding: 24px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 16px 16px 16px 4px;
  background-color: #fefcfa;
  box-shadow: 0 4px 16px 0 #f4e6d7;
}

.section__creators .emoji-speaker-image {
  position: absolute;
  left: -90px;
  top: auto;
  right: auto;
  bottom: -60px;
  height: 100px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.section__creators .emoji-speaker-image.r-side {
  left: auto;
  right: -90px;
  bottom: -50px;
}

.section__creators .emoji-speaker-image.r-side.type-2 {
  right: -80px;
}

.section__creators .scroll-arrow {
  display: none;
}

.section__creators .scroll-arrow.align-center {
  display: block;
}

.section__creators .div-60 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55%;
  margin-left: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section__creators .div-60.reverse {
  margin-right: 5%;
  margin-left: 0%;
}

.section__creators .div-60.reverse.cta {
  margin-right: 2%;
}

.section__creators .div-60.flex-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  margin-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.section__creators .div-60.div-55 {
  width: 50%;
}

.section__creators .div-35 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section__creators .div-35.div-product {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section__creators .text-highlight {
  display: inline;
  background-color: #efdffd;
}

.section__creators .text-highlight.green {
  background-color: #e5ffc7;
}

.section__creators .text-highlight.yellow {
  background-color: #fde49b;
}

.section__creators .text-highlight.product-bold {
  font-weight: 500;
}

.section__creators .text-highlight.product-bold.pink {
  background-color: #fbcee8;
}

.section__creators .text-highlight.pink {
  background-color: #fbcee8;
}

.section__creators .mt-96 {
  margin-top: 96px;
}

.section__creators .mt-128 {
  margin-top: 128px;
}

.section__creators .grid-wrapper {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section__creators .personas-image {
  height: 80px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.section__creators .bg-glass {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  margin-right: auto;
  margin-left: auto;
}

.section__creators .bg-glass.bg-filter {
  top: auto;
  height: 80px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.6);
}

.section__creators .nav-bar-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 1000;
  display: block;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section__creators .nav-bar-wrapper.glassed {
  position: fixed;
  top: -80px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.section__creators .product-image {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(103, 63, 24, 0.2);
}

.section__creators .product-image.type-2 {
  border-radius: 16px;
}

.section__creators .product-image.type-2._2 {
  position: relative;
  top: -80px;
  z-index: 10;
  width: 89%;
  max-width: 592px;
  box-shadow: 0 1px 50px -2px rgba(103, 63, 24, 0.2);
}

.section__creators .product-image.type-2._1 {
  position: relative;
  left: 80px;
  top: 24px;
  width: 89%;
  max-width: 592px;
}

.section__creators .product-image.type-1 {
  left: 80px;
  width: 89%;
  max-width: 592px;
  border-radius: 0px;
}

.section__creators .product-image.type-3 {
  max-width: 468px;
  border-radius: 10px;
}

.section__creators .product-image.type-3.mb-24 {
  width: 89%;
}

.section__creators .product-image.type-4 {
  position: absolute;
  left: 65px;
  bottom: 120px;
  width: 89%;
  max-width: 624px;
  border-radius: 10px;
}

.section__creators .product-image.type-5 {
  max-width: 651px;
  border-radius: 16px;
}

.section__creators .product-image.type-5.mt-24 {
  margin-top: 12px;
}

.section__creators .cta-text {
  font-size: 50px;
  line-height: 60px;
}

.section__creators .princing-card {
  overflow: hidden;
  max-width: 500px;
  padding: 32px 50px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 0 50px -2px rgba(103, 63, 24, 0.2);
}

.section__creators .subs-text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 800;
}

.section__creators .subs-text-tiny {
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.section__creators .pricing-card-text-wrapper {
  margin: -56px -50px -32px;
  padding: 98px 80px 42px;
  background-color: #fefaf7;
}

.section__creators .inline-emoji {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin-bottom: 0px;
  -o-object-fit: contain;
  object-fit: contain;
}

.section__creators .div-block-4 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 24px;
  -ms-grid-columns: -webkit-max-content auto;
  -ms-grid-columns: max-content auto;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section__creators .bg-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1000;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.section__creators .bg-image.bottom {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 10%;
}

.section__creators .bg-image.bottom-anchor {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.section__creators .download-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #111;
  font-weight: 600;
}

.section__creators .footer-collumn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section__creators .clients-slider-pane-wrapper {
  width: 50%;
  min-width: 2500px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: transparent;
}

.section__creators .clients-slider-pane-wrapper.right {
  margin-right: 16px;
}

.section__creators .clients-slider-pane-wrapper.left {
  margin-left: 16px;
}

.section__creators .tc-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

.section__creators .tc-name {
  font-size: 14px;
}

.section__creators .tc-position {
  color: rgba(55, 53, 40, 0.6);
  font-size: 12px;
}

.section__creators .tc-people-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.section__creators .align-lft {
  margin-right: auto;
}

.section__creators .align-rgt {
  margin-left: auto;
}

.section__creators .tc-company-image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.section__creators .chat-bubble-2 {
  position: relative;
  max-width: 600px;
  margin-right: 10%;
  margin-left: 10%;
  padding: 24px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-radius: 16px 16px 16px 4px;
  background-color: #fefcfa;
  box-shadow: 0 4px 16px 0 #f4e6d7;
}

.section__creators .chat-bubble-2.dark {
  max-width: 400px;
  padding: 16px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  background-color: #111;
  box-shadow: 0 4px 16px 0 rgba(154, 94, 36, 0.15);
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

.section__creators .chat-bubble-2.dark.r-side {
  z-index: 10;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 4px;
}

.section__creators .chat-bubble-2.dark.r-side.type-3 {
  margin-left: 0%;
}

.section__creators .text-highlight-2 {
  display: inline;
  background-color: #efdffd;
}

.section__creators .legal-date-text {
  margin-bottom: 42px;
  font-size: 14px;
  line-height: 1;
}

.section__creators .heading {
  line-height: 1.188;
}

/* ===================
- 5/ Pages
=======================*/
.typoghraphy {
  position: relative;
  overflow: hidden;
  position: relative;
}

.typoghraphy::before {
  content: "";
  position: absolute;
  content: "Aa";
  opacity: 0.1;
  font-size: 200px;
  font-family: "circular_bold";
  bottom: -6rem;
  left: -2rem;
}

@media screen and (max-width: 768px) {
  .profile__content {
    margin-bottom: 70px;
  }
}

.edit_profile .profile-img .pro_img {
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.edit_profile .profile-img .pro_img:hover {
  opacity: 0.8;
  transform: scale(0.9);
}

.edit_profile .profile-img .pro_img.is_active {
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  position: relative;
  position: relative;
}

.edit_profile .profile-img .pro_img.is_active::before {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background: url(../img/icons/Badge.svg);
  right: -15px;
  top: -15px;
  background-size: contain;
  background-repeat: no-repeat;
}

.edit_profile .profile-img .pro_img.is_active::after {
  content: "";
  position: absolute;
  height: calc(100% + 10px);
  width: calc(100% + 12px);
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border-radius: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .edit_profile .profile-img .pro_img {
    margin-bottom: 20px;
  }
}

.edit_profile .profile-img img {
  border-radius: 10px;
  width: 100%;
}

.edit_profile .profile-img .box img {
  border-radius: 0 !important;
}

.edit_profile .account-info,
.edit_profile .social-media {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#boxUpload {
  position: relative;
}

#boxUpload #imageUpload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  opacity: 0;
}

#profileImage {
  cursor: pointer;
  position: relative;
}

.register {
  background-image: url(../img/bg/log.jpeg);
  background-size: cover;
  background-attachment: fixed;
}

.register .left_part {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.register .right_part {
  padding: 60px 0;
}

.login {
  height: calc(100vh - 70px - 89px);
}

@media screen and (max-width: 991px) {
  .login {
    height: auto;
  }
}

.ranking {
  margin-bottom: 0 !important;
  border-spacing: 0 2px;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.ranking .collection {
  display: flex;
  align-items: center;
}

.ranking .collection .media {
  position: relative;
}

.ranking .collection .media .has_number {
  position: absolute;
  right: -5%;
  top: 75%;
  width: 22px;
  height: 22px;
  color: #ffffff;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.ranking .collection .media .collection__img {
  width: 4.5rem;
  height: 4.5rem;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border-radius: 5px;
  border: 3px solid #fff;
  object-fit: cover;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ranking .collection .title {
  font-weight: 400;
}

.ranking .avatars {
  display: flex;
  align-items: center;
}

.ranking .avatars .media {
  position: relative;
}

.ranking .avatars .media .badge {
  position: absolute;
  bottom: 0;
  right: -7px;
}

.ranking .avatars .media .badge img {
  width: 100%;
}

.ranking .avatars .media .has_number {
  position: absolute;
  left: -12%;
  top: 40%;
  width: 22px;
  height: 22px;
  color: #ffffff;
  background-color: #183b56;
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.ranking .avatars .media .has_number.top {
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  left: 70%;
  top: 4%;
}

.ranking .avatars .media.has_border {
  box-sizing: border-box;
  padding: 4px;
  border-radius: 100%;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
}

.ranking .avatars .avatars_name {
  font-weight: 700;
  transition: 0.3s;
}

.ranking .avatars .avatars_name.large {
  font-size: 22px;
}

.ranking .avatars .avatars_name:hover {
  color: #566ffe;
}

.ranking .avatars .price {
  font-size: 15px;
}

.ranking .avatars .details {
  display: flex;
  justify-content: space-between;
  background: #e6edf0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  width: 100%;
}

.ranking .avatars .collection__img {
  width: 4.5rem;
  height: 4.5rem;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border-radius: 50% !important;
  border: 3px solid #fff;
  object-fit: cover;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.ranking .avatars .collection__img.avatar-sm {
  width: 2rem;
  height: 2rem;
}

.ranking .avatars .collection__img.avatar-md {
  width: 5rem;
  height: 5rem;
}

@media screen and (max-width: 576px) {
  .ranking .avatars .collection__img.avatar-md {
    width: 4rem;
    height: 4rem;
  }
}

.ranking .avatars .collection__img.avatar-lg {
  width: 6rem;
  height: 6rem;
}

.ranking th {
  padding-left: 20px;
  padding-right: 20px;
}

.ranking th i {
  color: #183b56;
  vertical-align: middle;
  font-weight: normal;
  font-size: 17.5px;
}

.ranking th span {
  color: #183b56;
  font-weight: bold;
  vertical-align: middle;
}

.ranking tr {
  background: transparent;
}

.ranking tr td {
  vertical-align: middle;
  padding: 15px 20px;
  background: #edf3f5;
  white-space: nowrap;
}

.ranking .table * {
  border: none !important;
}

.ranking table tbody td:first-child {
  border-radius: 10px 0 0 10px;
}

.ranking table tbody td:last-child {
  border-radius: 0 10px 10px 0;
}

.forum .forum__content {
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .nav {
    justify-content: center;
  }
}

.forum .forum__content .nav .nav-item:not(:last-child) {
  margin-right: 10px;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .nav .nav-item {
    margin-bottom: 10px;
  }
}

.forum .forum__content .tags {
  background-color: white;
  color: #566ffe;
  padding: 6px 16px;
  border-radius: 28px;
  font-size: 13px;
  font-weight: 500;
  border: solid 1px rgba(10, 33, 65, 0.05);
  box-shadow: 0px 2px 4px #7e8eb11f !important;
  width: fit-content;
}

.forum .forum__content .tags span:not(:last-child) {
  margin-right: 5px;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .tags span {
    margin-bottom: 5px;
  }
}

.forum .forum__content .forum__title {
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.forum .forum__content .forum__title:hover {
  color: #566ffe;
}

.forum .forum__content .number {
  padding: 20px;
}

.forum .forum__content .number span {
  position: relative;
  color: #183b56;
}

.forum .forum__content .number span::before {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z' fill='rgba(54,179,126,1)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  top: -25px;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translateX(-50%);
}

.forum .forum__content .number span::after {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(233,84,50,1)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -25px;
  left: 50%;
  width: 30px;
  height: 30px;
  transform: translateX(-50%);
}

.forum .forum__content .reaction {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .reaction {
    margin-bottom: 10px;
  }
}

.forum .forum__content .reaction .comments,
.forum .forum__content .reaction .views,
.forum .forum__content .reaction .likes {
  margin-right: 10px;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .reaction .comments,
  .forum .forum__content .reaction .views,
  .forum .forum__content .reaction .time,
  .forum .forum__content .reaction .likes {
    margin-bottom: 10px;
  }
}

.forum .forum__content .reaction .likes {
  display: flex;
  align-items: center;
}

.forum .forum__content .reaction .likes span {
  color: #6f7d95;
}

.forum .forum__content .reaction .likes i {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #ff6871;
  font-weight: bold;
}

.forum .forum__content .reaction .likes i:hover {
  transform: scale(1.2);
  color: #eb5757;
  position: relative;
}

.forum .forum__content .reaction .likes i:hover::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) scale(1.5);
}

.forum .forum__content .reaction .comments,
.forum .forum__content .reaction .views,
.forum .forum__content .reaction .time {
  display: flex;
  align-items: center;
}

.forum .forum__content .reaction .comments i,
.forum .forum__content .reaction .views i,
.forum .forum__content .reaction .time i {
  color: #91a1bc;
}

.forum .forum__content .reaction .comments span,
.forum .forum__content .reaction .views span,
.forum .forum__content .reaction .time span {
  color: #6f7d95;
}

.forum .forum__content .reaction .like {
  display: flex;
}

.forum .forum__content .reaction .like .like__btn {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #566ffe;
}

.forum .forum__content .reaction .like .like__btn:hover {
  transform: scale(1.2);
  position: relative;
}

.forum .forum__content .reaction .like .like__btn:hover::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) scale(1.5);
}

.forum .forum__content .reaction .like i {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #566ffe;
}

.forum .forum__content .reaction .like span {
  color: #6f7d95;
}

.forum .forum__content .reaction .dislike {
  display: flex;
}

.forum .forum__content .reaction .dislike .dislike__btn {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  color: #566ffe;
}

.forum .forum__content .reaction .dislike .dislike__btn:hover {
  transform: scale(1.2);
  position: relative;
}

.forum .forum__content .reaction .dislike .dislike__btn:hover::after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%) scale(1.5);
}

.forum .forum__content .reaction .dislike i {
  color: #183b56;
}

.forum .forum__content .reaction .dislike span {
  color: #6f7d95;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .answer {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.forum .forum__content .comments__box {
  display: flex;
  align-items: center;
}

.forum .forum__content .comments__box .inupt__comment {
  display: block;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 576px) {
  .forum .forum__content .comments__box .inupt__comment {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.forum .forum__content .answers__box .v__line {
  padding: 32px;
  position: relative;
}

.forum .forum__content .answers__box .v__line::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 120%;
  background-color: #e2eef1;
  position: absolute;
  left: 50%;
  margin-left: -1px;
  top: 0;
}

.forum .forum__content .answers__box .v__line.last {
  position: relative;
}

.forum .forum__content .answers__box .v__line.last::before {
  content: "";
  position: absolute;
  display: none;
}

.forum .sidebar .popular-tags .tags_items {
  margin-bottom: -10px;
}

.forum .sidebar .popular-tags .tags_items span {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
}

.forum .sidebar .card__item {
  padding: 0;
  background: transparent;
  border: none;
}

.post__details .forum__title:hover {
  color: #183b56;
}

.post__details .Answers {
  display: flex;
  align-items: center;
}

.post__details .Answers i {
  color: #36b37e;
  font-size: 22px;
  font-weight: bold;
}

.post__details .Answers h5 {
  color: #36b37e;
}

.forum_border {
  border: solid 2px #566ffe;
}

.questions__page .accordion-button {
  color: #183b56;
  font-size: 19px;
  font-family: "circular_bold";
}

.questions__page .side {
  background: #183b56;
  border: solid 10px #fff;
}

@media screen and (max-width: 576px) {
  .questions__page .side {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 576px) {
  .questions__page .side .sidenav {
    margin-bottom: -20px;
    margin-right: -20px;
  }
}

@media screen and (max-width: 576px) {
  .questions__page .side .sidenav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.questions__page .side .sidenav ul li .text__reset {
  color: #ffffff;
}

.questions__page .side .sidenav ul li i {
  color: #ffffff;
  font-size: 22px;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.247);
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .questions__page .side .sidenav ul li {
    margin-bottom: 20px;
    margin-right: 20px;
  }
}

.questions__page .side .sidenav ul li:not(:last-child) {
  margin-bottom: 20px;
}

#sidebar-nav.sticky {
  position: fixed;
  top: 5px;
}

.contact {
  display: flex;
  margin-bottom: -100px;
}

.contact .contact__img {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .contact .contact__img {
    display: none;
  }
}

.contact .contact__img .img__wrap img {
  height: 100%;
}

.contact .contact__content {
  display: flex;
  align-items: center;
  padding-left: 50px;
  margin: 60px 0;
}

@media screen and (max-width: 768px) {
  .contact .contact__content {
    margin: 70px 0 0;
    padding-left: 15px !important;
  }
}

.not__found {
  position: relative;
}

.not__found .wrap {
  display: flex;
}

.not__found .wrap .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.not__found .wrap .content img {
  max-width: 20rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.not__found .wrap .img__left {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
}

@media screen and (max-width: 768px) {
  .not__found .wrap .img__left {
    display: none;
  }
}

.not__found .wrap .img__left img {
  height: 100%;
}

@media screen and (max-width: 992px) {
  .not__found .wrap .img__left img {
    height: 80%;
  }
}

.not__found .wrap .img__right {
  display: flex;
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
}

@media screen and (max-width: 768px) {
  .not__found .wrap .img__right {
    display: none;
  }
}

.not__found .wrap .img__right img {
  height: 100%;
  margin-left: auto;
}

@media screen and (max-width: 992px) {
  .not__found .wrap .img__right img {
    height: 80%;
  }
}

.requests {
  margin-bottom: -100px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .requests {
    margin-bottom: -70px;
  }
}

.requests .requests__wrap {
  margin: 100px 0;
}

@media screen and (max-width: 768px) {
  .requests .requests__wrap {
    margin: 70px 0;
  }
}

.requests .contact__img {
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .requests .contact__img {
    display: none;
  }
}

.requests .contact__img .img__wrap img {
  height: 100%;
  position: absolute;
  right: 0;
}

.requests .requests_footer {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 576px) {
  .requests .requests_footer {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.requests .requests_footer .Attach_desc {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .requests .requests_footer .Attach_desc {
    margin-right: 20px;
  }
}

@media screen and (max-width: 576px) {
  .requests .requests_footer .Attach_desc {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.requests .requests_footer .Attach_desc .Attach_file {
  position: relative;
  display: block ruby;
}

.requests .requests_footer .Attach_desc .Attach_file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 100px;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.submit_requests {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .submit_requests {
    margin-top: 70px;
  }
}

.submit_requests p {
  width: auto;
}

@media screen and (max-width: 992px) {
  .submit_requests .side_bar {
    margin-bottom: 30px;
  }
}

.submit_requests .side_bar .sidebar_box {
  padding: 20px;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border-radius: 10px;
}

.submit_requests .side_bar .sidebar_box .question_icon {
  font-size: 20px;
}

.submit_requests .side_bar .sidebar_box p:not(:last-child) {
  margin: 0;
  margin-right: 10px;
}

.submit_requests .side_bar .sidebar_box p:last-child {
  margin: 0;
}

.submit_requests .side_bar .sidebar_box .desc_icon {
  vertical-align: middle;
  display: none;
}

@media screen and (max-width: 576px) {
  .submit_requests .side_bar .sidebar_box .desc_icon {
    display: inline-block;
  }
}

.submit_requests .side_bar .sidebar_box .desc_text {
  display: inline-block;
}

@media screen and (max-width: 576px) {
  .submit_requests .side_bar .sidebar_box .desc_text {
    display: none;
  }
}

.submit_requests .sender .message_box {
  background-color: #e6e9ff;
}

.submit_requests .receiver .message_box {
  background-color: #edf3f5;
}

.upcoming_projects {
  margin-bottom: 0 !important;
  border-spacing: 0 2px;
  border-collapse: separate;
  border-spacing: 0;
}

.upcoming_projects .calendar_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.upcoming_projects .calendar_icon i {
  font-size: 24px;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
}

.upcoming_projects th {
  padding: 10px 40px 20px 40px;
}

.upcoming_projects th i {
  color: #183b56;
  vertical-align: middle;
  font-weight: normal;
  font-size: 17.5px;
}

.upcoming_projects th span {
  color: #183b56;
  font-weight: bold;
  vertical-align: middle;
}

.upcoming_projects tr {
  background: transparent;
}

.upcoming_projects tr td {
  vertical-align: middle;
  padding: 30px 40px;
  background: #eef0ff;
  white-space: nowrap;
}

.upcoming_projects tr.white td {
  background: white;
}

.upcoming_projects .table * {
  border: none !important;
}

.upcoming_projects table tbody td:first-child {
  border-radius: 10px 0 0 10px;
}

.upcoming_projects table tbody td:last-child {
  border-radius: 0 10px 10px 0;
}

/*-----------------------------
    section__news
  -----------------------------*/
.section__news {
  background: linear-gradient(#ffffff, #f9f9f9);
  width: 93%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
}

@media (max-width: 991px) {
  .section__news {
    margin-top: 3.75rem;
    width: 100%;
  }
}

.section__news .block__news_ch .item:first-of-type {
  margin-top: -3.75rem;
}

.section__news .block__news_ch .item:last-of-type {
  margin-top: -6.875rem;
}

@media (max-width: 767px) {
  .section__news .block__news_ch .item {
    margin-top: inherit !important;
    margin-bottom: 1.875rem;
  }
}

.section__news .block__news_ch .item__news {
  padding: 1.375rem;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.section__news .block__news_ch .item__news .head_img {
  margin-bottom: 0.938rem;
}

.section__news .block__news_ch .item__news .head_img img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  height: 140px;
  object-fit: cover;
}

@media (max-width: 767px) {
  .section__news .block__news_ch .item__news .head_img img {
    height: 180px;
  }
}

.section__news .block__news_ch .item__news .body__content span {
  display: block;
  margin-bottom: 0.625rem;
  color: #eb5757;
  font-size: 13px;
}

.section__news .block__news_ch .item__news .body__content h3 {
  margin-bottom: 0.625rem;
  font-size: 20px;
  font-weight: 500;
  color: #183b56;
}

.section__news .block__news_ch .item__news .body__content p {
  margin-bottom: 0;
  font-size: 15px;
}

.section__news .block__news_ch .item__news .footer__content {
  margin-top: 1.875rem;
}

.section__news .block__news_ch .item__news .footer__content .link__go {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.section__news .block__news_ch .item__news .footer__content .link__go:hover {
  color: #183b56;
}

/*-----------------------------
    news__workspace
  -----------------------------*/
.news__workspace .title_sections {
  margin-bottom: 3.75rem;
}

.news__workspace .fa__news {
  display: block;
}

.news__workspace .fa__news .img__cover {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 1.25rem;
}

.news__workspace .fa__news .img__cover img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 8px;
  -webkit-transform: scale3d(1, 1, 1) !important;
  -moz-transform: scale3d(1, 1, 1) !important;
  -o-transform: scale3d(1, 1, 1) !important;
  -ms-transform: scale3d(1, 1, 1) !important;
  transform: scale3d(1, 1, 1) !important;
  -webkit-transition: 0.4s !important;
  -moz-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  -ms-transition: 0.4s !important;
  transition: 0.4s !important;
}

.news__workspace .fa__news .img__cover.sm_height img {
  height: 180px;
}

.news__workspace .fa__news .body__content span {
  font-size: 15px;
  display: block;
  margin-bottom: 0.625rem;
  font-weight: 400;
}

.news__workspace .fa__news .body__content h3 {
  font-size: 20px;
  margin-bottom: 0.625rem;
  font-weight: 500;
  color: #183b56;
}

.news__workspace .fa__news .body__content p {
  font-size: 15px;
  color: #6f7d95;
  margin-bottom: 0;
  font-weight: 400;
}

.news__workspace .fa__news:hover .img__cover img {
  -webkit-transform: scale3d(1.1, 1.1, 1) !important;
  -moz-transform: scale3d(1.1, 1.1, 1) !important;
  -o-transform: scale3d(1.1, 1.1, 1) !important;
  -ms-transform: scale3d(1.1, 1.1, 1) !important;
  transform: scale3d(1.1, 1.1, 1) !important;
}

.news__workspace .block_end .fa__news:first-child {
  margin-top: -3.125rem;
}

@media (max-width: 991px) {
  .news__workspace .block_end .fa__news:first-child {
    margin-top: 0;
  }
}

/*-----------------------------
    blog_slider
  -----------------------------*/
.blog_slider .swiper-button-next,
.blog_slider .swiper-button-prev {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(24, 59, 86, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 22px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.blog_slider .swiper-button-next .tio,
.blog_slider .swiper-button-prev .tio {
  font-size: 18px;
  color: #183b56;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.blog_slider .swiper-button-next::after,
.blog_slider .swiper-button-prev::after {
  display: none;
}

.blog_slider .swiper-button-next:hover,
.blog_slider .swiper-button-prev:hover {
  background-color: #183b56;
}

.blog_slider .swiper-button-next:hover .tio,
.blog_slider .swiper-button-prev:hover .tio {
  color: #ffffff;
}

@media (max-width: 767px) {
  .blog_slider .swiper-button-next,
  .blog_slider .swiper-button-prev {
    display: none;
  }
}

.blog_slider .swiper-button-next {
  right: 10px;
  left: auto;
}

.blog_slider .swiper-button-prev {
  right: 4.5rem;
  left: inherit;
}

.blog_slider .title_sections_inner {
  border-bottom: 1px solid #e2eef1;
  position: relative;
}

.blog_slider .title_sections_inner h2 {
  margin-bottom: 1.875rem;
}

.blog_slider .title_sections_inner::after {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  left: 0;
  background-color: #183b56;
}

.grid_blog_avatar a {
  display: block;
}

.grid_blog_avatar .cover_blog {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 8px;
}

.grid_blog_avatar .cover_blog img {
  width: 100%;
  border-radius: 8px;
  height: 220px;
  object-fit: cover;
  -webkit-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -moz-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -o-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -ms-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -webkit-transform: scale3d(1, 1, 1) !important;
  -moz-transform: scale3d(1, 1, 1) !important;
  -o-transform: scale3d(1, 1, 1) !important;
  -ms-transform: scale3d(1, 1, 1) !important;
  transform: scale3d(1, 1, 1) !important;
}

.grid_blog_avatar .body_blog .person {
  margin-bottom: 20px;
}

.grid_blog_avatar .body_blog .person img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 12px;
  margin-bottom: 5px !important;
}

.grid_blog_avatar .body_blog .person .txt h3 {
  color: #183b56;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.grid_blog_avatar .body_blog .person .txt time {
  font-size: 12px;
  color: #6f7d95;
  display: block;
}

.grid_blog_avatar .body_blog .title_blog {
  color: #183b56;
  font-weight: 500;
  font-size: 20px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.grid_blog_avatar .body_blog .short_desc {
  color: #6f7d95;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  display: -webkit-box;
  height: 43px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid_blog_avatar .body_blog .link_blog {
  display: block;
}

.grid_blog_avatar .body_blog .link_blog:hover .title_blog {
  color: #566ffe;
}

.grid_blog_avatar:hover .cover_blog img {
  -webkit-transform: scale3d(1.12, 1.12, 1) !important;
  -moz-transform: scale3d(1.12, 1.12, 1) !important;
  -o-transform: scale3d(1.12, 1.12, 1) !important;
  -ms-transform: scale3d(1.12, 1.12, 1) !important;
  transform: scale3d(1.12, 1.12, 1) !important;
}

.grid_blog_avatar.list_style {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .grid_blog_avatar.list_style .body_blog {
    margin-top: 20px;
  }
}

.grid_blog_avatar.list_style .cover_blog {
  margin-bottom: 0;
}

.article_page .user {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.article_page .blog-author-detail {
  margin-bottom: 40px;
}

.article_page .author-meta p {
  margin: 0;
}

.article_page .article_wrap .tag_cloud {
  margin-bottom: -10px;
}

.article_page .article_wrap .tag_cloud .p_tags {
  display: flex;
  width: min-content;
  line-height: 2;
  display: block;
  margin-right: 20px;
}

.article_page .article_wrap .tag_cloud a {
  padding: 2px 10px;
  background-color: #f9fbfc;
  margin-right: 5px;
  margin-bottom: 10px;
  border: solid 1px transparent;
  color: #183b56;
}

.article_page .article_wrap .tag_cloud a:hover {
  background-color: white;
  color: #566ffe;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #eee;
}

.article_page .blog-share-container {
  position: sticky;
  top: 15px;
  left: 0;
}

.article_page .blog-share {
  box-shadow: 0 33px 88px 0 #1d0e6211;
  padding: 20px;
  background: #566ffe;
  width: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
}

.article_page .blog-share::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/others/lines.svg);
  background-size: cover;
  mix-blend-mode: screen;
  z-index: -1;
}

.article_page .blog-share .share {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.article_page .blog-share ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.article_page .blog-share ul li {
  width: fit-content;
  margin-bottom: 5px;
}

.article_page .blog-share ul li a {
  width: fit-content;
  color: white;
}

.article_page .blog-share ul li a:hover {
  color: #183b56;
}

.article_page .blog-share a {
  color: #183b56;
}

.article_page .content {
  margin-top: 0;
  margin-bottom: 60px;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  padding: 30px;
}

.article_page .content .inner h2,
.article_page .content .inner h3,
.article_page .content .inner h4,
.article_page .content .inner h5,
.article_page .content .inner h6,
.article_page .content .inner p {
  margin-top: 25px;
  margin-bottom: 25px;
}

.article_page .content .img_article {
  margin-bottom: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border-radius: 20px;
}

.comments_article {
  max-width: 1000px;
  background-color: rgba(86, 111, 254, 0.15);
  padding: 30px 30px 10px;
  margin-bottom: 60px;
  border-radius: 20px;
}

.comments_article .avatar {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.comments_article .comment {
  margin-bottom: 20px;
}

.comments_article .comment-content {
  background: #ffffff;
  border-radius: 0 15px 15px 15px;
  padding: 20px;
}

.comments_article .is_child {
  padding-left: 60px;
  border-left: solid 2px #ffffff;
}

.comments_article .is_child .comment-content {
  background: rgba(255, 255, 255, 0.623);
}

@media screen and (max-width: 991px) {
  .comments_article .is_child {
    padding-left: 20px;
  }
}

.reply {
  max-width: 1000px;
  padding: 30px;
  border-right: 20px;
  border-radius: 5px;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
}

/*-----------------------------
    content-Sblog
  -----------------------------*/
.content-Sblog {
  margin-top: 50px;
}

.content-Sblog .fixSide_scroll .item {
  margin-bottom: 40px;
}

.content-Sblog .fixSide_scroll .item .profile_user {
  text-align: center;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .item .profile_user {
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.content-Sblog .fixSide_scroll .item .profile_user img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .item .profile_user img {
    margin-right: 15px;
  }
}

.content-Sblog .fixSide_scroll .item .profile_user .txt {
  margin-top: 15px;
}

.content-Sblog .fixSide_scroll .item .profile_user .txt h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.content-Sblog .fixSide_scroll .item .profile_user .txt time {
  display: block;
  font-size: 12px;
  color: #6f7d95;
}

.content-Sblog .fixSide_scroll .item .profile_user .btn_profile {
  padding: 9px 27px;
  font-size: 14px;
  margin-top: 15px;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .item .profile_user .btn_profile {
    margin-left: 15px;
  }
}

.content-Sblog .fixSide_scroll .share_socail {
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .share_socail {
    text-align: left;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.content-Sblog .fixSide_scroll .share_socail .title {
  font-size: 15px;
  color: #6f7d95;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .content-Sblog .fixSide_scroll .share_socail .title {
    margin-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.content-Sblog .fixSide_scroll .share_socail .btn {
  margin-bottom: 5px;
}

.content-Sblog .fixSide_scroll .share_socail .btn.icon {
  background-color: #ffffff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-Sblog .fixSide_scroll .share_socail .btn.icon .tio {
  font-size: 18px;
}

.content-Sblog .fixSide_scroll .share_socail .btn:hover.icon {
  background-color: #566ffe;
}

.content-Sblog .body_content p,
.content-Sblog .body_content li {
  font-size: 15px;
  color: #434c55;
}

.content-Sblog .body_content a {
  color: #566ffe;
}

.content-Sblog .body_content img {
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
}

.content-Sblog .body_content mark {
  color: #ffffff;
}

.content-Sblog .body_content .img_md {
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.content-Sblog .body_content .cover_video {
  position: relative;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.content-Sblog .body_content .cover_video img {
  width: 100%;
  height: 450px;
}

.content-Sblog .body_content .cover_video .icon_played {
  position: absolute;
}

.content-Sblog .body_content .cover_video .btn_video .play_video::after {
  display: none;
}

.content-Sblog .body_content .txt_quotation {
  border-left: 6px solid #6f7d95;
  padding: 15px 25px;
  font-size: 24px;
  color: #183b56;
  font-weight: 500;
}

/*-----------------------------
    simple_blog_dev
  -----------------------------*/
.simple_blog_dev .item_news {
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .simple_blog_dev .item_news {
    margin-bottom: 30px;
  }
}

.simple_blog_dev .item_news .link_post {
  display: block;
  font-size: 25px;
  font-weight: 500;
  color: #183b56;
  margin-bottom: 5px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.simple_blog_dev .item_news .link_post:hover {
  color: #566ffe;
}

.simple_blog_dev .item_news p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

/*-----------------------------
    box_news_gray
  -----------------------------*/
.box_news_gray .grid_blog_avatar {
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.box_news_gray .grid_blog_avatar .link_blog {
  margin-bottom: 30px;
}

.box_news_gray .grid_blog_avatar .link_blog:hover .title_blog {
  color: #eb5757;
}

/*-----------------------------
    blog_masonry
  -----------------------------*/
.blog_masonry .card {
  border-radius: 8px;
  border: 0;
  margin-bottom: 30px;
}

.blog_masonry .card:hover .cover_link::before {
  opacity: 1;
}

.blog_masonry .card:hover .cover_link .main_img {
  -webkit-transform: scale3d(1.1, 1.1, 1) !important;
  -moz-transform: scale3d(1.1, 1.1, 1) !important;
  -o-transform: scale3d(1.1, 1.1, 1) !important;
  -ms-transform: scale3d(1.1, 1.1, 1) !important;
  transform: scale3d(1.1, 1.1, 1) !important;
}

.blog_masonry .card:hover .cover_link .auther_post {
  opacity: 1;
  top: 30px;
}

.blog_masonry .card:hover .card-title {
  color: #566ffe !important;
}

.blog_masonry .card .link_poet {
  display: block;
  text-decoration: none;
}

.blog_masonry .card .cover_link {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog_masonry .card .cover_link .auther_post {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 2;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -moz-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -o-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -ms-transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
}

.blog_masonry .card .cover_link .auther_post img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 12px;
}

.blog_masonry .card .cover_link .auther_post .txt h4 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.blog_masonry .card .cover_link .auther_post .txt p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.blog_masonry .card .cover_link .main_img {
  object-fit: cover;
  width: 100%;
  -webkit-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -moz-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -o-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -ms-transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  transition: 0.4s cubic-bezier(0.65, 0.05, 0.36, 1) !important;
  -webkit-transform: scale3d(1, 1, 1) !important;
  -moz-transform: scale3d(1, 1, 1) !important;
  -o-transform: scale3d(1, 1, 1) !important;
  -ms-transform: scale3d(1, 1, 1) !important;
  transform: scale3d(1, 1, 1) !important;
}

.blog_masonry .card .cover_link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  z-index: 1;
  opacity: 0;
}

.blog_masonry .card .cover_link:hover {
  z-index: 0;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog_masonry .card .cover_link:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.308);
  background: rgba(5, 6, 51, 0.479);
  z-index: 1;
  opacity: 1;
}

.blog_masonry .card .card-body {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 33px 88px 0 #1d0e6211;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.blog_masonry .card .card-body .about_post {
  margin-bottom: 12px;
}

.blog_masonry .card .card-body .about_post .c_ategory a {
  color: #183b56;
  display: inline-block;
  font-size: 14px;
}

.blog_masonry .card .card-body .about_post .c_ategory a:hover {
  color: #566ffe;
}

.blog_masonry .card .card-body .about_post .c_ategory a::before {
  content: ", ";
}

.blog_masonry .card .card-body .about_post .c_ategory a:first-child::before {
  display: none;
}

.blog_masonry .card .card-body .about_post .dot {
  width: 5px;
  height: 5px;
  border-radius: 30px;
  background-color: #e0e1e4;
  margin: 0 8px;
  display: inline-block;
}

.blog_masonry .card .card-body .about_post time {
  display: inline-block;
  font-size: 13px;
}

.blog_masonry .card .card-body .card-title {
  color: #183b56;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 12px;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  transition: 0.3s !important;
}

.blog_masonry .card .card-body .card-text {
  font-size: 15px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog_masonry.two_column .card-columns {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 1.875rem;
  -moz-column-gap: 1.875rem;
  column-gap: 1.875rem;
  orphans: 1;
  widows: 1;
  overflow: visible;
}

.blog_masonry.three_column .card-body .card-title {
  font-size: 20px;
}

.blog_masonry.height_fixed .link_poet .cover_link .main_img {
  height: 250px;
}

@media (max-width: 991px) {
  .blog_masonry .card-columns {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    -o-column-count: 2 !important;
    -ms-column-count: 2 !important;
    column-count: 2 !important;
  }
}

@media (max-width: 767px) {
  .blog_masonry .card-columns {
    -webkit-column-count: 1 !important;
    -moz-column-count: 1 !important;
    -o-column-count: 1 !important;
    -ms-column-count: 1 !important;
    column-count: 1 !important;
  }
}

.section_tag_auther {
  margin-top: 50px;
}

.section_tag_auther .box_tags_psot h4 {
  font-size: 14px;
  color: #183b56;
  margin-bottom: 10px;
}

.section_tag_auther .box_tags_psot a {
  background-color: #566ffe;
  border-radius: 8px;
  font-size: 13px;
  color: #183b56;
  padding: 10px 20px;
  margin-right: 8px;
}

.section_tag_auther .block_auther_post {
  background-color: #566ffe;
  border-radius: 8px;
  padding: 35px;
}

.section_tag_auther .block_auther_post .img_person {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.section_tag_auther .block_auther_post .txt h4 {
  font-size: 15px;
  font-weight: 500;
  color: #183b56;
  margin-bottom: 5px;
}

.section_tag_auther .block_auther_post .txt p {
  margin-bottom: 0;
  font-size: 14px;
}

/*-----------------------------
    sec_comments
  -----------------------------*/
.sec_comments .title_commnt h2 {
  font-size: 20px;
  color: #183b56;
  font-weight: 500;
}

.sec_comments .body_commnt .item_commnt {
  margin-top: 40px;
}

.sec_comments .body_commnt .item_commnt .at_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.sec_comments .body_commnt .item_commnt .txt h3 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 3px;
}

.sec_comments .body_commnt .item_commnt .txt .date {
  display: block;
  font-size: 12px;
  color: #6f7d95;
}

.sec_comments .body_commnt .item_commnt .txt .content {
  margin-top: 15px;
  color: #183b56;
  font-size: 14px;
  margin-bottom: 10px;
}

.sec_comments .body_commnt .item_commnt .txt .btn_repy {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #183b56;
}

.sec_comments .body_commnt .item_commnt .txt .btn_repy:hover {
  color: #566ffe;
}

.sec_comments .form_comment .title_sections {
  margin-bottom: 40px;
}

.sec_comments .form_comment .title_sections h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.blog.has_style1 {
  background: white;
  margin: 0 0 60px;
  overflow: hidden;
  border-radius: 0px;
}

.blog.has_style1 .row {
  padding: 0;
  margin: 0;
}

.blog.has_style1 .col-row {
  padding: 0;
  margin: 0;
}

.blog.has_style_grid {
  margin: 0 0 30px;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
}

.blog.has_style_grid .blog-excerpt {
  font-size: 15px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog.has_style_grid .blog-img img {
  width: 100%;
  object-fit: cover;
  height: 14rem;
}

.blog.has_style_grid .blog-title {
  font-size: 24px;
}

.blog.has_style_grid .row {
  padding: 0;
  margin: 0;
}

.blog.has_style_grid .col-row {
  padding: 0;
  margin: 0;
}

.blog .meta.absolute {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  display: flex;
  color: #183b56;
  padding: 6px 12px;
}

.blog .meta.absolute .meta-info {
  color: #183b56;
  font-size: 13px;
  margin: 0 3px;
  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.blog .meta.absolute .meta-info svg {
  width: 15px;
  height: 15px;
  fill: #6f7d95;
  vertical-align: middle;
  margin-top: -3px;
  opacity: 0.5;
}

.blog .meta.absolute .min-read {
  display: inline-block;
  cursor: default;
}

.blog .meta.absolute .min-read span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  background-color: #7169fe;
  text-align: center;
  line-height: 21px;
  font-size: 13px;
  vertical-align: middle;
  margin-top: -4px;
  overflow: hidden;
}

.blog .meta.absolute .views-counter {
  cursor: default;
}

.blog .blog-title {
  margin: 0 0 20px;
  color: #183b56;
  font-size: calc( 19px + 7 * ((100vw - 576px) / 1024));
  line-height: 1.33;
  max-height: 2.66em;
  letter-spacing: 0.2px;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  .blog .blog-title {
    font-size: 19px;
  }
}

@media screen and (min-width: 1600px) {
  .blog .blog-title {
    font-size: 26px;
  }
}

.blog .blog-title a {
  color: #183b56;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog .blog-title a:hover {
  color: #566ffe;
}

.blog .blog-excerpt {
  font-size: 18px;
  line-height: 1.78;
  color: #5a7184;
  margin: 0 0 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog .blog-img {
  position: relative;
}

.blog .blog-img img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.blog .blog-author-detail {
  margin-top: 20px;
}

.blog .blog-author-detail .c-customer-review-meta {
  margin-top: 0;
}

.blog .blog-wrap {
  margin: 30px 0;
}

.blog .name {
  padding: 0;
  color: #566ffe;
}

.blog .author-post {
  text-transform: uppercase;
  color: #7a7d97;
  font-size: 13px;
  margin: 0;
}

.blog .user {
  margin-right: 0;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.blog .user img {
  object-fit: cover;
  width: 40px;
  height: 40px;
}

.blog .post_wrap {
  position: relative;
}

@media screen and (max-width: 991px) {
  .blog_sidebar {
    margin-top: 60px;
  }
}

.blog_sidebar .widget {
  margin-bottom: 30px;
  background: #ffffff;
  border: solid 1px #e2eef1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  padding: 30px;
}

.blog_sidebar .widget .widget-title {
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: normal;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: #0b1b27 !important;
  font-weight: bold;
  position: relative;
  border-bottom: 1px solid #edeef1;
}

.blog_sidebar .widget .widget-title::after {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: #0b2238;
}

.blog_sidebar .social-widget {
  display: flex;
}

.blog_sidebar .social-widget a {
  color: #0b1b27;
}

.blog_sidebar .social-widget i {
  font-size: 24px;
}

.blog_sidebar .social-widget .social_link {
  margin-right: 20px;
  height: 3rem;
  width: 3rem;
  border: 1px soli transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  background: white;
}

.blog_sidebar .social-widget .social_link.facebook {
  background: #4267b2;
  color: #ffffff;
}

.blog_sidebar .social-widget .social_link.dribbble {
  background: #ea4c89;
  color: #ffffff;
}

.blog_sidebar .social-widget .social_link.youtube {
  background: #c4302b;
  color: #ffffff;
}

.blog_sidebar .social-widget .social_link.twitter {
  background: #00acee;
  color: #ffffff;
}

.blog_sidebar .social-widget .social_link:hover {
  border: 1px solid #ededed;
  background: white !important;
  color: #0b1b27;
  box-shadow: 0 5px 34px rgba(3, 3, 38, 0.071);
}

.blog_sidebar .Recent_Articles-widget .item {
  display: flex;
}

.blog_sidebar .Recent_Articles-widget .item .thumb img {
  width: 70px;
  margin-right: 10px;
  border-radius: 5px;
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog_sidebar .Recent_Articles-widget .item .info {
  padding-bottom: 20px;
  border-bottom: solid 1px #ededed;
  margin-bottom: 20px;
}

.blog_sidebar .Recent_Articles-widget .item .info.reset {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.blog_sidebar .Recent_Articles-widget .item .info .title {
  font-size: 15px;
  margin-bottom: 4px;
}

.blog_sidebar .Recent_Articles-widget .item .info .title a {
  color: #0b1b27;
}

.blog_sidebar .Recent_Articles-widget .item .info .title a:hover {
  color: #566ffe;
}

.blog_sidebar .Recent_Articles-widget .item .info .time {
  font-size: 12px;
  color: #86819e;
}

.blog_sidebar .Recent_Articles-widget .item:hover img {
  filter: saturate(1.6);
}

.blog_sidebar .Recent_Articles-widget .swiper-buttons-styling {
  display: inline-block;
  border: 1px solid #ededed;
  box-shadow: 0 5px 34px rgba(0, 0, 0, 0.071);
  padding: 5px 4px;
  border-radius: 5px;
  display: flex;
  width: fit-content;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-prev {
  border-right: 1px solid #ededed;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-next,
.blog_sidebar .Recent_Articles-widget .swiper-button-prev {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  color: #bfbfbf;
  margin: 0 !important;
  height: auto !important;
  padding: 5px 0 !important;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-next:after,
.blog_sidebar .Recent_Articles-widget .swiper-button-prev:after {
  font-size: 17px !important;
}

.blog_sidebar .Recent_Articles-widget .swiper-button-next:hover,
.blog_sidebar .Recent_Articles-widget .swiper-button-prev:hover {
  color: #566ffe;
}

.blog_sidebar .ads-widget img {
  width: 100%;
  border-radius: 5px;
}

.blog_sidebar .cat-item {
  padding-bottom: 10px;
  border-bottom: solid 1px #f9fbfc;
  margin-bottom: 10px;
}

.blog_sidebar .cat-item a {
  color: #0b1b27;
}

.blog_sidebar .cat-item a:hover {
  padding-left: 5px;
  color: #566ffe;
}

.blog_sidebar .cat-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.blog_sidebar .tag_cloud a {
  display: inline-block;
  font-size: 13.5px;
  padding: 2px 10px;
  background-color: rgba(86, 111, 254, 0.15);
  margin-right: 5px;
  text-transform: capitalize;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #0b1b27;
}

.blog_sidebar .tag_cloud a:hover {
  background-color: #0b1b27;
  color: #f9fbfc;
}

.tag_cloud {
  margin-bottom: -10px;
}

.tag_cloud .p_tags {
  display: flex;
  width: min-content;
  line-height: 2;
  display: block;
  margin-right: 20px;
}

.tag_cloud a {
  padding: 2px 10px;
  background-color: #f9fbfc;
  margin-right: 5px;
  margin-bottom: 10px;
  border: solid 1px transparent;
  color: #0b1b27;
}

.tag_cloud a:hover {
  background-color: white;
  color: #566ffe;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  border: solid 1px #eee;
}

.styles_tab {
  display: flex;
  justify-content: center;
}

.styles_tab .item {
  padding: 10px 20px;
  border-radius: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  position: relative;
  opacity: 0.3;
  position: relative;
}

.styles_tab .item::before {
  content: "";
  position: absolute;
  height: 106%;
  width: 102%;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border-radius: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  opacity: 0;
}

.styles_tab .item:hover {
  position: relative;
}

.styles_tab .item:hover::before {
  content: "";
  position: absolute;
  opacity: 0;
}

.styles_tab .item p {
  margin-left: 10px;
  color: #183b56;
}

.styles_tab .item.is_active {
  opacity: 1;
  position: relative;
}

.styles_tab .item.is_active::before {
  content: "";
  position: absolute;
  opacity: 1 !important;
}

.title_demos {
  font-size: 50px;
}

@media screen and (max-width: 991px) {
  .title_demos {
    font-size: 35px;
  }
}

.hr_in_index {
  width: 100%;
  height: 1px;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
}

.demos_wrap {
  position: relative;
  position: relative;
}

.demos_wrap::before {
  content: "";
  position: absolute;
  background: url(../img/icons/demo_effect.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 0;
  height: 100rem;
  width: 100%;
  top: -20rem;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .demos_wrap::before {
    display: none;
  }
}

.demo_item {
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 17px 13px 0px #192c4b05;
  position: relative;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.1s;
  transform: translateY(0);
  display: block;
  transform-origin: 50% -50px;
  margin-bottom: 40px;
  transform: perspective(300px) rotateX(-3deg);
  position: relative;
  position: relative;
}

.demo_item.is_sm .img_wrap {
  width: 100%;
  height: 10rem;
  border-radius: 20px;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .demo_item.is_sm .img_wrap {
    height: 16rem;
  }
}

.demo_item.is_lg {
  transform: none !important;
}

.demo_item.is_lg .img_wrap {
  width: 100%;
  height: 26.5rem;
  border-radius: 20px;
  overflow: hidden;
}

.demo_item::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 20px;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.514);
  border-radius: 20px 20px 0 0;
  z-index: -1;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.demo_item::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 40px;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.26);
  border-radius: 20px 20px 0 0;
  z-index: -2;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.05s;
}

.demo_item .img_wrap {
  width: 100%;
  height: 20rem;
  border-radius: 20px;
  overflow: hidden;
}

.demo_item .img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  object-position: top center;
}

.demo_item:hover {
  transform: translateY(-0.5rem);
  position: relative;
  position: relative;
}

.demo_item:hover .img_wrap img {
  transform: scale(1.05);
}

.demo_item:hover::before {
  content: "";
  position: absolute;
  top: 0;
}

.demo_item:hover::after {
  content: "";
  position: absolute;
  top: 0px;
}

.change_log {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.change_log .bage {
  padding: 2px 10px;
  background: #566ffe;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}

.change_log .bage.upd {
  background: #36b37e;
}

.change_log .bage.fix {
  background: #eb5757;
}

.overflow_log {
  height: 30rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.overflow_log::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10rem;
  background: linear-gradient(to bottom, transparent, #ecf1f918, #ecf1f9f6, #ecf1f9);
  z-index: 1;
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.overflow_log .more_log {
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.overflow_log.visible {
  height: auto;
  overflow: visible;
  position: relative;
}

.overflow_log.visible::before {
  content: "";
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.overflow_log.visible .more_log {
  bottom: -4rem;
}

.box_buy_wrapper {
  position: relative;
  position: relative;
}

.box_buy_wrapper::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 20px;
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.514);
  border-radius: 20px 20px 0 0;
  z-index: -1;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.box_buy_wrapper::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 40px;
  left: 50%;
  top: -40px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.26);
  border-radius: 20px 20px 0 0;
  z-index: -2;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.05s;
}

.box_buy_wrapper2 {
  position: relative;
  position: relative;
}

.box_buy_wrapper2::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 20px;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.514);
  border-radius: 20px 20px 0 0;
  z-index: -1;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.box_buy_wrapper2::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 40px;
  left: 50%;
  bottom: -40px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.26);
  border-radius: 20px 20px 0 0;
  z-index: -2;
  transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0.05s;
}

.box_buy {
  padding: 60px;
  border: none;
  position: relative;
}

.box_buy::before {
  content: "";
  position: absolute;
  height: 103%;
  width: 101%;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
  border-radius: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.box_buy .btn_buy {
  width: 100%;
  background-color: #89bd49;
  border-radius: 10px !important;
}

.box_buy .list_buy li {
  padding-left: 30px;
  position: relative;
}

.box_buy .list_buy li::before {
  content: "";
  position: absolute;
  content: url(../img/icons/check.svg);
  height: 20px;
  width: 20px;
  left: 0px;
}

.hire_box {
  position: relative;
}

.hire_box::before {
  content: "";
  position: absolute;
  content: none;
}

.raroin_purchase {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 999;
}

.raroin_purchase .btn_purch {
  position: relative;
  overflow: visible;
}

.raroin_purchase .btn_purch .icon {
  width: 45px;
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #89bd49;
  border-radius: 50%;
  vertical-align: sub;
}

.raroin_purchase .btn_purch .icon img {
  width: 18px;
}

.raroin_purchase .btn_purch .icon:before {
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  position: absolute;
  background: rgba(137, 189, 73, 0.1);
  z-index: -1;
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.sh_log {
  position: absolute;
  right: 0;
  top: 0;
}

.sh_buy {
  position: absolute;
  left: -2.7rem;
  top: -3rem;
  z-index: 9;
  width: 7rem;
}

.relative {
  position: relative;
}

.mode_switcher {
  display: flex;
}

.mode_switcher a {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.123);
  border-radius: 50px;
  padding: 3px 10px;
}

.mode_switcher a.is_active {
  background: #566ffe;
}

.mode_switcher a i {
  margin-right: 5px;
}

.is__dark {
  background: #0b1b27;
}

.is__dark .hero_marketplace,
.is__dark .hero__1,
.is__dark .hero__profile,
.is__dark .hero__collections,
.is__dark .hero__activity,
.is__dark .hero__kit,
.is__dark .bg_white,
.is__dark .accordion,
.is__dark .hero__profile .infos {
  background: #183b56 !important;
  border-color: #ffffff1f;
}

.is__dark .hero__index::before {
  background: transparent !important;
}

.is__dark .hero__3,
.is__dark .hero_no_results,
.is__dark .hero_privacy,
.is__dark .hero_Live_Auctions {
  background: #0b1b27 !important;
}

.is__dark .menu_activity li {
  background-color: #e2eef114;
}

.is__dark .header__1 {
  background: #183b56 !important;
  border-bottom: solid 1px #e2eef11f;
}

.is__dark .border-b {
  border-color: #ffffff1f;
}

.is__dark .hero__2 {
  background-color: #0b1b27;
}

.is__dark .hero__2::before {
  content: none;
}

.is__dark *:not(p):not(.btn),
.is__dark .collection_title a,
.is__dark div {
  color: #ffffff;
}

.is__dark * {
  box-shadow: none !important;
}

.is__dark .collection_title a {
  color: #ffffff !important;
}

.is__dark label,
.is__dark .btn-dark,
.is__dark .header__1 .header__mobile {
  background: #305571;
}

.is__dark .color_green {
  color: #36b37e !important;
}

.is__dark p,
.is__dark span {
  color: #a5afc1;
}

.is__dark .box.is__live,
.is__dark .call2action {
  background: #091620 !important;
  border: none;
}

.is__dark .section__title,
.is__dark .avatars_name,
.is__dark .swiper-button-next::after,
.is__dark .swiper-button-prev::after {
  color: #ffffff;
}

.is__dark .avatar {
  border: none;
}

.is__dark .hero__profile .avatar_wrap .avatar {
  border: solid 5px #0b1b27;
}

.is__dark .creator_card,
.is__dark .card__item,
.is__dark .swiper-button-prev,
.is__dark .swiper-button-next,
.is__dark .collections_item,
.is__dark .logos__wrap,
.is__dark .footer__1,
.is__dark .card__item .likes,
.is__dark .community__items .item,
.is__dark .hero__2 .wrapper,
.is__dark .box,
.is__dark .has_number,
.is__dark .header__result,
.is__dark .search,
.is__dark .fixed_row,
.is__dark .choose_collection .icon,
.is__dark .upcoming_projects tr.white td,
.is__dark .upcoming_projects .calendar_icon,
.is__dark .card__item.six .card_body .card_footer .details {
  background-color: #183b56 !important;
  border: none;
}

.is__dark .nav-tabs .nav-item a.active,
.is__dark .box .btn-white,
.is__dark .tags,
.is__dark .light_bg {
  background: #0b1b27 !important;
}

.is__dark .hr,
.is__dark .hr2,
.is__dark .footer__1 .footer__social i,
.is__dark .bg-white,
.is__dark .header__avatar,
.is__dark .header__search,
.is__dark .details,
.is__dark .card__item.six,
.is__dark .card__item.six .card_body .card_footer {
  background-color: #0b1b27 !important;
  border: none;
}

.is__dark .v__line {
  position: relative;
}

.is__dark .v__line::before {
  content: "";
  position: absolute;
  background-color: #0b1b27 !important;
}

.is__dark .dislike i {
  color: #91a1bc !important;
}

.is__dark td {
  background-color: #0b1b27 !important;
}

.is__dark .btn-white,
.is__dark .tags,
.is__dark .light_bg {
  color: white;
  background: #183b56;
}

.is__dark .bg-white,
.is__dark .call2action.is__light {
  background: #08131b !important;
}

.is__dark .community .community__items .item .logo {
  background: #edf3f52b;
}

.is__dark .footer__1 .footer__list li a {
  color: #a5afc1;
}

.is__dark .card__item.one .card_head .details {
  background: #0d1013;
}

.is__dark .block_timer .item {
  background: #183b56;
  color: white;
}

.is__dark .has_number {
  border-color: #183b56 !important;
  background: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #db74ff 100%);
}

.is__dark input[type="text"],
.is__dark input[type="password"],
.is__dark input[type="email"],
.is__dark input[type="tel"],
.is__dark input[type="number"],
.is__dark select,
.is__dark textarea,
.is__dark .custom-select,
.is__dark .choose_collection {
  background-color: #0b1b27 !important;
  border: none !important;
}

.is__dark .number span {
  color: white !important;
}

.is__dark .avatars .total__avatars {
  border-color: #183b56 !important;
}

.is__dark .menu__popup,
.is__dark .menu__popup2,
.is__dark .share_box,
.is__dark .more_box,
.is__dark .notifications_popup,
.is__dark .more .icon,
.is__dark .share .icon,
.is__dark .hero__profile .copy,
.is__dark .item_details .likes,
.is__dark .share .share_box,
.is__dark .share .dropdown__popup,
.is__dark .more .dropdown__popup {
  background: #142c3f !important;
  border: none !important;
}

.is__dark .menu__popup a,
.is__dark .menu__popup2 a,
.is__dark .share_box a,
.is__dark .more_box a,
.is__dark .notifications_popup a,
.is__dark .more .icon a,
.is__dark .share .icon a,
.is__dark .hero__profile .copy a,
.is__dark .item_details .likes a,
.is__dark .share .share_box a,
.is__dark .share .dropdown__popup a,
.is__dark .more .dropdown__popup a {
  color: #fff !important;
}

.is__dark .notifications_popup .details {
  background: transparent !important;
}

.is__dark .modal-content {
  background: #183b56;
  border: none !important;
}

.is__dark .modal-content .close {
  background: #345a77;
  border: none !important;
}

.is__dark .btn-white,
.is__dark .tags,
.is__dark .light_bg {
  border: solid 1px #345a77;
}

.is__dark .btn-white:hover,
.is__dark .tags:hover,
.is__dark .light_bg:hover {
  border: solid 1px #345a77;
  background: #345a77;
}

.is__dark .card__item .view_history:hover {
  color: white !important;
}

.is__dark .post__details .forum__title:hover {
  color: white !important;
}

.is__dark .form_cc_four {
  background: #183b56;
}

.is__dark .search .result {
  background: #183b56;
}

.is__dark .effect {
  background-image: none;
}

.is__dark .box {
  border: none;
}

.is__dark .box:hover {
  border: none;
}

.is__dark .questions__page .container-md .side .sidenav ul li .text__reset,
.is__dark i {
  color: #f9fbfc;
}

.is__dark .submit_requests .sidebar_box {
  background-color: #182633 !important;
}

.is__dark .submit_requests .sender .message_box {
  background-color: #0e1621 !important;
}

.is__dark .submit_requests .sender .message_box p {
  color: white !important;
}

.is__dark .submit_requests .receiver .message_box {
  background-color: #182633 !important;
}

.is__dark .submit_requests .receiver .message_box p {
  color: white !important;
}

.is__dark .table span.color_red {
  color: #eb5757;
}

.is__dark .table span.color_info {
  color: #1384e0;
}

.is__dark .table span.color_green {
  color: #36b37e;
}

.is__dark .card__item.six .card_body .card_head {
  background: transparent !important;
}

.is__dark .demo_item,
.is__dark .box_buy_wrapper,
.is__dark .box_buy_wrapper2 {
  background: #183b56;
}

.is__dark .demo_item::before,
.is__dark .box_buy_wrapper::before,
.is__dark .box_buy_wrapper2::before {
  background: #193c5880;
}

.is__dark .demo_item::after,
.is__dark .box_buy_wrapper::after,
.is__dark .box_buy_wrapper2::after {
  background: #193c582a;
}

.is__dark .change_log {
  background: #0b1b27 !important;
}

.is__dark .overflow_log::before {
  background: linear-gradient(transparent, #0b1b2773, #0b1b27d6, #0b1b27);
}
