/* ===============================================
    TABLE  OF CONTENT:
    ------------------
- 1/ CSS Basic classes
- 2/ base
- 3/ elements
- 4/ Layouts
- 5/ Pages
=============================================== */

// style.scss

/* ===================
- 1/ CSS Basic classes
=======================*/

@import "1-helpers/colors";
@import "1-helpers/mixins";
@import "1-helpers/variables";
@import "1-helpers/global";
@import "1-helpers/classes";
@import "1-helpers/spacing";
@import "1-helpers/margin";
@import "1-helpers/padding";

/* ===================
2/ base
=======================*/
@import "0-base/base";
@import "0-base/typography";

/* ===================
3/ elements
=======================*/
@import "2-elements/buttons";
@import "2-elements/forms";
@import "2-elements/tabs";
@import "2-elements/dropdowns";
@import "2-elements/menus";
@import "2-elements/breadcrumb";
@import "2-elements/accordion";
@import "2-elements/avatars";
@import "2-elements/popups";
@import "2-elements/card_items";
@import "2-elements/creators_items";
@import "2-elements/collections_items";
@import "2-elements/menus";
/* ===================
4/ Layouts
=======================*/
@import "3-Layouts/header";
@import "3-Layouts/hero";
@import "3-Layouts/footer";
@import "3-Layouts/sections";
/* ===================
- 5/ Pages
=======================*/
@import "4-Pages/elements_page";
@import "4-Pages/profile_page";
@import "4-Pages/register";
@import "4-Pages/ranking_page";
@import "4-Pages/forum_page";
@import "4-Pages/questions_page";
@import "4-Pages/contact";
@import "4-Pages/404";
@import "4-Pages/requests_page";
@import "4-Pages/submit_requests";
@import "4-Pages/upcoming_projects";
@import "4-Pages/blog";

@import "4-Pages/index";

@import "4-Pages/is_dark";
